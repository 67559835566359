
//  AffRating.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD56d6d6bad2e654862bbb8124aaa229384#]

import {
    OMReference,
    DomainObject,
    OMUniverse
} from "@apptivity-lab/firmament-node-sdk"

import { SrrRating, SrrRatingProperties } from "../ratings"

// Import related object types
import { CnxMeetingTemplate } from "../connectx"
import AffExpertProfile from "./AffExpertProfile"
import { ConcreteRateable } from "../ratings"
import { DomainProfile } from "@firmament-packages/kernel"

export interface AffRatingProperties extends SrrRatingProperties
{
    // AffRating relationships
    meetingTemplate?: OMReference<CnxMeetingTemplate> | null
    provider?: OMReference<AffExpertProfile> | null
}

/**
 * AffRating
 */
export default class AffRating extends SrrRating
    implements AffRatingProperties
{
    public static Type: string = "aff_rating"
    public static Path: string = "aff_ratings"

    // Creates a placeholder AffRating instance
    public static createPlaceholder(subject?: OMReference<ConcreteRateable>) {
        const attendee = OMUniverse.shared.currentUser?.user.attendeeProfile()

        return new AffRating({
            subject: subject || new OMReference(ConcreteRateable, ConcreteRateable.createPlaceholder()),
            overall: 5,
            submitter: attendee ? new OMReference(DomainProfile, attendee.id) : undefined
        })
    }

    // AffRating Relationships
    public meetingTemplate?: OMReference<CnxMeetingTemplate> | null
    public provider?: OMReference<AffExpertProfile> | null

    constructor(object: AffRatingProperties) {
        super(object)

        // AffRating Relationships
        this.meetingTemplate = object.meetingTemplate ? new OMReference(CnxMeetingTemplate, object.meetingTemplate) : null
        this.provider = object.provider ? new OMReference(AffExpertProfile, object.provider) : null
    }
}
