import React, { useEffect, useState } from "react"

const ErrorHUD: React.FunctionComponent<{
    error: Error,
    /**
     * Set to true if this HUD should remain visible until manually
     */
    sticky?: boolean,
    onDismiss?: () => void
}> = ({ error, sticky, onDismiss }) => {
    const [isVisible, setVisible] = useState<boolean>(true)

    useEffect(() => {
        if (sticky) {
            return
        }

        const timeout = setTimeout(() => {
            setVisible(false)
            onDismiss && onDismiss()
        }, 8000)

        return () => clearTimeout(timeout)
    }, [sticky, onDismiss])

    return (
        <div
            className={[
                "fixed top-[40px] right-[40px] w-[360px] max-w-[360px] z-40 p-[20px]",
                "bg-red-100 text-alert-900 shadow-lg",
                "transition-all duration-300 ease-in-out",
                isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-[500px]"
            ].join(" ")}>

            <div className="flex text-callout">
                <div className="flex-auto"><strong>Error</strong></div>
                <button type="button" onClick={() => {
                    setVisible(false)
                    onDismiss && onDismiss()
                }}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div className="text-footnote">{error.message}</div>
        </div>
    )
}

export default ErrorHUD
