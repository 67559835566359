//  WltWallet.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import WltTransaction from "./WltTransaction"

export interface WltWalletProperties extends DomainObjectProperties
{
    // WltWallet Attributes
    currency: string

    // WltWallet relationships
    transactions?: OMReference<WltTransaction>[] | null
    transactionsCount?: number
    transactionsAvailableBalance?: number | null
    transactionsFloatingBalance?: number | null
    transactionsAllTimeEarned?: number | null
}

/**
 * WltWallet
 */
export default class WltWallet extends DomainObject
    implements WltWalletProperties
{
    public static Type: string = "wlt_wallet"
    public static Path: string = "wlt_wallets"

    // Creates a placeholder WltWallet instance
    public static createPlaceholder() {
        return new WltWallet({
            currency: ""
        })
    }

    // WltWallet Attributes
    public currency: string

    // WltWallet Relationships
    public transactions?: Array<OMReference<WltTransaction>> | null
    public transactionsCount: number
    public transactionsAvailableBalance: number
    public transactionsFloatingBalance: number
    public transactionsAllTimeEarned: number

    constructor(object: WltWalletProperties) {
        super(object)

        // WltWallet Attributes
        this.currency = object.currency

        // WltWallet Relationships
        this.transactions = object.transactions ? object.transactions.map((item) => new OMReference(WltTransaction, item)) : []
        this.transactionsCount = object.transactionsCount || 0
        this.transactionsAvailableBalance = object.transactionsAvailableBalance || 0
        this.transactionsFloatingBalance = object.transactionsFloatingBalance || 0
        this.transactionsAllTimeEarned = object.transactionsAllTimeEarned || 0
    }
}
