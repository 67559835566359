import { useEffect, useState } from "react"

import { OMUniverse } from "@apptivity-lab/firmament-node-sdk"
import { useRouter } from "next/router"

/**
 * Hook to enforce user authentication state, and routes to different path otherwise.
 *
 * @param redirectTo Path to redirect to if user is unauthenticated
 */
export const useAuthenticatedUser = (redirectTo: string) => {
    const router = useRouter()
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>()

    useEffect(() => {
        // Under most circumstances, installation and (at least anonymous) user will always be present
        // except while restoringSession, which is async and may take some time.
        if (!OMUniverse.shared.installation || !OMUniverse.shared.currentUser?.user) {
            // This prevents user from being redirected immediately at first visit, during _app's restoreSession
            return
        }

        setIsAuthenticated(OMUniverse.shared.currentUser?.isAuthenticated || false)
        if (OMUniverse.shared.currentUser?.isAuthenticated !== true) {
            router.replace(`${redirectTo}?return=${router.asPath}`)
        }
    }, [router, redirectTo])
}
