//  PmtPayment.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5ce78bbfe2f30c92e6cee11da3705a6df#]

import {
    OMObject,
    OMObjectProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import MerchOrder from "../merchantable/MerchOrder"
import { DomainProfile } from "../kernel"

// Import command types
import { PmtPaymentCancelCommand, PmtPaymentCaptureCommand, PmtPaymentRefundCommand } from "./PmtPaymentCommands"

export interface PmtPaymentProperties extends OMObjectProperties
{
    // PmtPayment Attributes
    amountInCents: number
    currency: string
    processorPaymentId?: string | null
    status: string
    cancellationReason?: string | null
    cancelledAt?: Date | null
    chargedAt?: Date | null
    description?: string | null
    failureCode?: string | null
    failureReason?: string | null
    refundReason?: string | null
    refundedAt?: Date | null
    cardBrand?: string | null
    cardNumber?: string | null
    methodType?: string | null
    receiptEmail?: string | null

    // PmtPayment relationships
    order?: OMReference<MerchOrder> | null
    customer: OMReference<DomainProfile>
}

/**
 * PmtPayment
 */
export default class PmtPayment extends OMObject
    implements PmtPaymentProperties
{
    public static Type: string = "pmt_payment"
    public static Path: string = "pmt_payments"

    // Creates a placeholder PmtPayment instance
    public static createPlaceholder() {
        return new PmtPayment({
            amountInCents: 0,
            currency: "",
            status: "PENDING",
            customer: new OMReference(DomainProfile, DomainProfile.createPlaceholder())
        })
    }

    // PmtPayment Attributes
    public amountInCents: number
    public currency: string
    public processorPaymentId?: string | null
    public status: string
    public cancellationReason?: string | null
    public cancelledAt?: Date | null
    public chargedAt?: Date | null
    public description?: string | null
    public failureCode?: string | null
    public failureReason?: string | null
    public refundReason?: string | null
    public refundedAt?: Date | null
    public cardBrand?: string | null
    public cardNumber?: string | null
    public methodType?: string | null
    public receiptEmail?: string | null

    // PmtPayment Relationships
    public order?: OMReference<MerchOrder> | null
    public customer: OMReference<DomainProfile>

    constructor(object: PmtPaymentProperties) {
        super(object)

        // PmtPayment Attributes
        this.amountInCents = object.amountInCents
        this.currency = object.currency
        this.processorPaymentId = object.processorPaymentId
        this.status = object.status
        this.cancellationReason = object.cancellationReason
        this.cancelledAt = object.cancelledAt ? new Date(object.cancelledAt) : null
        this.chargedAt = object.chargedAt ? new Date(object.chargedAt) : null
        this.description = object.description
        this.failureCode = object.failureCode
        this.failureReason = object.failureReason
        this.refundReason = object.refundReason
        this.refundedAt = object.refundedAt ? new Date(object.refundedAt) : null
        this.cardBrand = object.cardBrand
        this.cardNumber = object.cardNumber
        this.methodType = object.methodType
        this.receiptEmail = object.receiptEmail

        // PmtPayment Relationships
        this.order = object.order ? new OMReference(MerchOrder, object.order) : null
        this.customer = new OMReference(DomainProfile, object.customer)
    }

    // PmtPayment.cancel()
    // @param resultStatus: string,
    // @param reason: string
    public cancel(resultStatus: string, reason?: string)
    {
        try {
            const command = new PmtPaymentCancelCommand(this, resultStatus, reason)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // PmtPayment.capture()
    // @param resultStatus: string,
    // @param failureCode: string,
    // @param failureReason: string
    public capture(resultStatus: string, failureCode?: string, failureReason?: string)
    {
        try {
            const command = new PmtPaymentCaptureCommand(this, resultStatus, failureCode, failureReason)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // PmtPayment.refund()
    // @param resultStatus: string,
    // @param reason: string
    public refund(resultStatus: string, reason?: string)
    {
        try {
            const command = new PmtPaymentRefundCommand(this, resultStatus, reason)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
