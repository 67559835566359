//  SrrRating.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD53529e11444eedf3f985c90795c3600a6#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Protocols adopted
import { Flaggable, ConcreteFlaggable } from "../flaggable"

// Import related object types
import SrrRatingAttachment from "./SrrRatingAttachment"
import { DomainProfile } from "../kernel"
import ConcreteRateable from "./Rateable"

export interface SrrRatingProperties extends DomainObjectProperties,
    Flaggable
{
    // SrrRating Attributes
    overall?: number | null
    response?: string | null
    report?: string | null
    review?: string | null
    subratingTypes?: string[] | null
    subrating1?: number | null
    subrating2?: number | null
    subrating3?: number | null
    subrating4?: number | null
    subrating5?: number | null

    // SrrRating relationships
    attachments?: OMReference<SrrRatingAttachment>[] | null
    attachmentsCount?: number
    submitter?: OMReference<DomainProfile> | null
    subject: OMReference<ConcreteRateable>
}

/**
 * SrrRating
 */
export default class SrrRating extends DomainObject
    implements SrrRatingProperties
{
    public static Type: string = "srr_rating"
    public static Path: string = "srr_ratings"

    // Creates a placeholder SrrRating instance
    public static createPlaceholder() {
        return new SrrRating({
            subject: new OMReference(ConcreteRateable, ConcreteRateable.createPlaceholder())
        })
    }

    // SrrRating Attributes
    public overall?: number | null
    public response?: string | null
    public report?: string | null
    public review?: string | null
    public subratingTypes?: string[] | null
    public subrating1?: number | null
    public subrating2?: number | null
    public subrating3?: number | null
    public subrating4?: number | null
    public subrating5?: number | null

    // SrrRating Relationships
    public attachments?: Array<OMReference<SrrRatingAttachment>> | null
    public attachmentsCount: number
    public submitter?: OMReference<DomainProfile> | null
    public subject: OMReference<ConcreteRateable>

    // Flaggable Protocol
    public flaggers?: Array<OMReference<DomainProfile>>| null
    public flaggerCount: number

    constructor(object: SrrRatingProperties) {
        super(object)

        // SrrRating Attributes
        this.overall = object.overall
        this.response = object.response
        this.report = object.report
        this.review = object.review
        this.subratingTypes = object.subratingTypes
        this.subrating1 = object.subrating1
        this.subrating2 = object.subrating2
        this.subrating3 = object.subrating3
        this.subrating4 = object.subrating4
        this.subrating5 = object.subrating5

        // SrrRating Relationships
        this.attachments = object.attachments ? object.attachments.map((item) => new OMReference(SrrRatingAttachment, item)) : []
        this.attachmentsCount = object.attachmentsCount || 0
        this.submitter = object.submitter ? new OMReference(DomainProfile, object.submitter) : null
        this.subject = new OMReference(ConcreteRateable, object.subject)

        // Flaggable Protocol
        this.flaggers = object.flaggers ? object.flaggers.map((item) => new OMReference(DomainProfile, item)) : []
        this.flaggerCount = object.flaggerCount || 0
    }

    // Flaggable.flag()
    // @param profile?: string,
    // @param reason?: string
    public flag(profile?: string, reason?: string)
    {
        return (new ConcreteFlaggable(this)).flag(profile,reason)
    }

    // Flaggable.unflag()
    // @param profile?: string
    public unflag(profile?: string)
    {
        return (new ConcreteFlaggable(this)).unflag(profile)
    }
}
