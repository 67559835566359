import {
    FSMNamedState,
    Identity,
    ObservableFiniteStateMachine,
    OMGroup as Group,
    OMProfile as Profile,
    OMReference,
    OMUniverse
} from "@apptivity-lab/firmament-node-sdk"

import { AffAttendeeProfile } from "@firmament-packages/aff"
import { CnxConsumerProfile, CnxSessionWallet } from "@firmament-packages/connectx"
import Setting from "@firmament-packages/settings/Setting"

import "../firmament-packages/aff/extensions/OMUserExtensions"
import "../firmament-packages/aff/extensions/AffAttendeeProfileExtensions"

declare namespace AppStateMachine {
    export interface Start extends FSMNamedState {
        name: "start"
    }

    export interface RestoringSession extends FSMNamedState {
        name: "restoringSession"
    }

    export interface LoadingAuthentication extends FSMNamedState {
        name: "loadingAuthentication"
    }

    export interface LoadingProfile extends FSMNamedState {
        name: "loadingProfile"
        profile: Profile
    }

    export interface LoadingConsumerGroup extends FSMNamedState {
        name: "loadingConsumerGroup"
        profile: CnxConsumerProfile
    }

    export interface LoadingSessionWallet extends FSMNamedState {
        name: "loadingSessionWallet"
        profile: CnxConsumerProfile
    }

    export interface LoadingProfile extends FSMNamedState {
        name: "loadingProfile"
        profile: Profile
    }

    export interface ShowingError extends FSMNamedState {
        name: "showingError"
        error: Error
    }

    export interface ShowingAuthMenu extends FSMNamedState {
        name: "showingAuthMenu"
    }

    export interface ShowingProfileSetup extends FSMNamedState {
        name: "showingProfileSetup"
    }

    export interface ShowingMain extends FSMNamedState {
        name: "showingMain"
    }

    export interface LoggingOut extends FSMNamedState {
        name: "loggingOut"
    }

    export type State =
        Start |
        RestoringSession |
        ShowingError |
        LoadingAuthentication |
        LoadingProfile | LoadingConsumerGroup | LoadingSessionWallet |
        ShowingAuthMenu | ShowingProfileSetup | ShowingMain |
        LoggingOut
}

export class AppStateMachine extends ObservableFiniteStateMachine<AppStateMachine.State> {
    private isUserAuthenticationRequired: boolean = false

    constructor() {
        super({ name: "start" })
    }

    async restoreSession() {
        switch (this.state.name) {
            case "start":
                break
            default:
                return
        }

        const environment = process.env.UNIVERSE_ENVIRONMENT || "production"
        if (environment === "production") {
            OMUniverse.setApplicationKey("CM62DCA9xDHx3JYzgjXhJgDA")
            OMUniverse.setBaseURL("https://harbour.alivefitandfree.com/aff-connectx-production")
        } else {
            OMUniverse.setApplicationKey("1a64b006f85ddb77357541fd6826716f")
            OMUniverse.setBaseURL("https://harbour-cloud.apptivitylab.com/aff-connectx-dev")
        }
        OMUniverse.shared.changeEnvironment(environment as any)

        this.state = { name: "restoringSession" }

        try {
            await OMUniverse.shared.restoreUserSession()
        } catch (error: unknown) {
            if (error instanceof Error) {
                this.fail(error)
            } else {
                this.fail(new Error("An unknown error has occurred while restoring session. Try refreshing this page."))
            }
        }

        // Load settings at app launch and cache in local storage as key-value
        // pairs
        const settingsQuery = Setting.query()
            .filterGroup([
                ["platform", "isNull", undefined],
                ["platform", "equals", "NextJS"]
            ], "OR")
            .filterGroup(
                ["cnx_onboarding"].map((module) => ["module", "equals", module]),
                "OR"
            )
            .limit(500)

        settingsQuery
            .execute()
            .catch(() => settingsQuery)
            .then((query) => {
                const settings = query.resultObjects
                    .map((setting) => ({ key: setting.key, value: setting.value || null }))
                if (localStorage) {
                    localStorage.setItem("settings", JSON.stringify(settings))
                }
            })

        this.loadAuthentication()
    }

    loadAuthentication() {
        switch (this.state.name) {
            case "restoringSession":
            case "showingMain":
                break
            default:
                return
        }

        this.state = { name: "loadingAuthentication" }

        const user = OMUniverse.shared.user
        if (!this.isUserAuthenticationRequired || (user && user.identityCount > 0)) {
            this.loadProfile()
        } else {
            this.state = { name: "showingAuthMenu" }
        }
    }

    async loadProfile() {
        switch (this.state.name) {
            case "loadingAuthentication":
                break
            default:
                return
        }

        try {
            const attendeeProfile = OMUniverse.shared.currentUser?.user.attendeeProfile()

            // If user has identities (but not profile), they may have successfully
            // went past registration, but did not complete profile setup.
            // This app should not be used in a "profile limbo" state,
            // to always prompt users falling under this category to complete
            // their profile by forcing them there.
            if (OMUniverse.shared.currentUser?.user &&
                OMUniverse.shared.currentUser?.user.identityCount > 0 &&
                !attendeeProfile) {
                this.state = { name: "showingProfileSetup" }
                return
            }

            if (attendeeProfile) {
                this.state = { name: "loadingProfile", profile: attendeeProfile }

                const refreshedProfile = await new OMReference(AffAttendeeProfile, attendeeProfile)
                    .load(
                        AffAttendeeProfile.query()
                            .include("sessionWallets")
                            .include("photo")
                    )

                // Registered consumers should have an associated Stripe
                // Customer account
                if (!refreshedProfile.stripeCustomerId) {
                    const emailIdentity = OMUniverse.shared.currentUser?.user
                        ?.identities?.find((id) => id.actualObject?.identityType === "email")
                        ?.actualObject
                    await refreshedProfile.registerStripeCustomer(
                        refreshedProfile.fullName, // name
                        undefined, // phone
                        emailIdentity?.identifier // email
                    )
                }

                await refreshedProfile.loadActiveSubscriptions()

                // Registered consumers should be part of the default consumer group
                const defaultConsumerGroupId = JSON.parse(localStorage?.getItem("settings") || "[]")
                    .filter((each: { key: string, value?: any  }) => each.key === "cnx_onboarding_consumer_default_group")
                    ?.[0]
                    ?.value

                const currentUserGroups = (refreshedProfile.user.actualObject?.groups || []).map((group) => group.id)
                if (defaultConsumerGroupId && !currentUserGroups.includes(defaultConsumerGroupId)) {
                    this.loadConsumerGroup(refreshedProfile)
                    return
                }

                if (refreshedProfile.sessionWalletsCount === 0) {
                    this.loadSessionWallet(refreshedProfile)
                    return
                }
            }

            this.complete()
        } catch (error: unknown) {
            if (error instanceof Error) {
                this.fail(error)
            }
        }
    }

    private async loadConsumerGroup(profile: CnxConsumerProfile) {
        switch (this.state.name) {
            case "loadingProfile":
                break
            default:
                return
        }

        this.state = { name: "loadingConsumerGroup", profile }

        try {
            const defaultConsumerGroupId = JSON.parse(localStorage?.getItem("settings") || "[]")
                .filter((each: { key: string, value?: any  }) => each.key === "cnx_onboarding_consumer_default_group")
                ?.[0]
                ?.value

            const currentUserGroups = (profile.user.actualObject?.groups || []).map((group) => group.id)

            // Registered consumers should be part of the default consumer group
            if (defaultConsumerGroupId && !currentUserGroups.includes(defaultConsumerGroupId)) {
                const consumerGroupId = new OMReference(Group, defaultConsumerGroupId)
                const consumerGroup = consumerGroupId.actualObject || await consumerGroupId.load()

                await consumerGroup?.addMember(profile.user.id)
            }

            if (profile.sessionWalletsCount === 0) {
                this.loadSessionWallet(profile)
            } else {
                this.complete()
            }
        } catch (error: unknown) {
            if (error instanceof Error) {
                this.fail(error)
            } else {
                this.fail(new Error("An unknown error has occurred while adding into default consumer group."))
            }
        }
    }

    private async loadSessionWallet(profile: CnxConsumerProfile) {
        switch (this.state.name) {
            case "loadingProfile":
            case "loadingConsumerGroup":
                break
            default:
                return
        }

        this.state = { name: "loadingSessionWallet", profile }

        try {
            // Registered attendee should have a session wallet
            if (profile.sessionWalletsCount === 0) {
                const sessionWallet = new CnxSessionWallet({
                    currency: "SSN",
                    consumer: new OMReference(CnxConsumerProfile, profile)
                })
                OMUniverse.shared.touch(sessionWallet)
                await sessionWallet.save()

                OMUniverse.shared.touch(sessionWallet, true)
                profile.sessionWallets = [new OMReference(CnxSessionWallet, sessionWallet.id)]
                OMUniverse.shared.touch(profile, true)
            }

            this.complete()
        } catch (error: unknown) {
            if (error instanceof Error) {
                this.fail(error)
            } else {
                this.fail(new Error("An unknown error has occurred while setting up session wallet."))
            }
        }
    }

    async logout() {
        try {
            this.state = { name: "loggingOut" }
            await OMUniverse.shared.logout()
        } finally {
            this.complete()
        }
    }

    back() {
        switch (this.state.name) {
            case "showingAuthMenu":
                if (OMUniverse.shared.currentUser?.isAuthenticated === true) {
                    this.state = { name: "showingMain" }
                }
                break
            case "showingProfileSetup":
                if (OMUniverse.shared.currentUser?.user.attendeeProfile()) {
                    this.state = { name: "showingMain" }
                }
                break
            default:
                return
        }
    }

    fail(error: Error) {
        this.state = { name: "showingError", error }
    }

    complete() {
        switch (this.state.name) {
            case "loadingAuthentication":
            case "loadingProfile":
            case "loadingConsumerGroup":
            case "loadingSessionWallet":
                this.state = { name: "showingMain" }
                break
            case "loggingOut":
                this.state = { name: "start" }
                this.restoreSession()
                break
            default:
                return
        }
    }
}
