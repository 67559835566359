//  CnxBooking.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5aa396ea93f586bb9ea507afc11463e97#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import CnxConsumerProfile from "./CnxConsumerProfile"
import CnxConsumerPurchase from "./CnxConsumerPurchase"
import CnxMeeting from "./CnxMeeting"

// Import command types
import { CnxBookingCancelCommand, CnxBookingConfirmCommand } from "./CnxBookingCommands"

export interface CnxBookingProperties extends DomainObjectProperties
{
    // CnxBooking Attributes
    baseCurrency?: string | null
    paymentCurrency?: string | null
    paymentGrossRateCents?: number | null
    paymentPlatformFeeCents?: number | null
    paymentProcessingFeeCents?: number | null
    currencyExchangeConstant?: number | null
    currencyExchangeRate?: number | null
    bookingStatus?: string | null

    // CnxBooking relationships
    consumer: OMReference<CnxConsumerProfile>
    purchase?: OMReference<CnxConsumerPurchase> | null
    meeting: OMReference<CnxMeeting>
}

/**
 * CnxBooking
 */
export default class CnxBooking extends DomainObject
    implements CnxBookingProperties
{
    public static Type: string = "cnx_booking"
    public static Path: string = "cnx_bookings"

    // Creates a placeholder CnxBooking instance
    public static createPlaceholder() {
        return new CnxBooking({
            consumer: new OMReference(CnxConsumerProfile, CnxConsumerProfile.createPlaceholder()),
            meeting: new OMReference(CnxMeeting, CnxMeeting.createPlaceholder())
        })
    }

    // CnxBooking Attributes
    public baseCurrency?: string | null
    public paymentCurrency?: string | null
    public paymentGrossRateCents?: number | null
    public paymentPlatformFeeCents?: number | null
    public paymentProcessingFeeCents?: number | null
    public currencyExchangeConstant?: number | null
    public currencyExchangeRate?: number | null
    public bookingStatus?: string | null

    // CnxBooking Relationships
    public consumer: OMReference<CnxConsumerProfile>
    public purchase?: OMReference<CnxConsumerPurchase> | null
    public meeting: OMReference<CnxMeeting>

    constructor(object: CnxBookingProperties) {
        super(object)

        // CnxBooking Attributes
        this.baseCurrency = object.baseCurrency
        this.paymentCurrency = object.paymentCurrency
        this.paymentGrossRateCents = object.paymentGrossRateCents
        this.paymentPlatformFeeCents = object.paymentPlatformFeeCents
        this.paymentProcessingFeeCents = object.paymentProcessingFeeCents
        this.currencyExchangeConstant = object.currencyExchangeConstant
        this.currencyExchangeRate = object.currencyExchangeRate
        this.bookingStatus = object.bookingStatus

        // CnxBooking Relationships
        this.consumer = new OMReference(CnxConsumerProfile, object.consumer)
        this.purchase = object.purchase ? new OMReference(CnxConsumerPurchase, object.purchase) : null
        this.meeting = new OMReference(CnxMeeting, object.meeting)

    }

    // CnxBooking.cancel()
    public cancel()
    {
        try {
            const command = new CnxBookingCancelCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // CnxBooking.confirm()
    public confirm()
    {
        try {
            const command = new CnxBookingConfirmCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
