//  AffAttendeeProfile.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5b41d1392b4694615a6f16b02ca5bb84c#]

import {
    OMReference,
    User,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"
import { capitalize } from "@lib"

import { CnxConsumerProfile, CnxConsumerProfileProperties } from "../connectx"

// Import command types
import { AffAttendeeProfileSendWelcomeEmailCommand } from "./AffAttendeeProfileCommands"

// AffAttendeeProfile Custom Datatypes
export type ParticipationType = "INDIVIDUAL" | "COMMUNITY"
export const ParticipationTypeOptions = [
    { title: "INDIVIDUAL", value: "INDIVIDUAL" },
    { title: "COMMUNITY", value: "COMMUNITY" },
]

export interface AffAttendeeProfileProperties extends CnxConsumerProfileProperties
{
    // AffAttendeeProfile Attributes
    lastName?: string | null
    phoneNumber?: string | null
    timezone?: string | null
    participationType: ParticipationType
    dateOfBirth?: Date | null

}

/**
 * AffAttendeeProfile
 */
export default class AffAttendeeProfile extends CnxConsumerProfile
    implements AffAttendeeProfileProperties
{
    public static Type: string = "aff_attendee_profile"
    public static Path: string = "aff_attendee_profiles"

    // Creates a placeholder AffAttendeeProfile instance
    public static createPlaceholder() {
        return new AffAttendeeProfile({
            participationType: "INDIVIDUAL",
            user: new OMReference(User, User.createPlaceholder())
        })
    }

    // AffAttendeeProfile Attributes
    public lastName?: string | null
    public phoneNumber?: string | null
    public timezone?: string | null
    public participationType: ParticipationType
    public dateOfBirth?: Date | null

    public get fullName(): string {
        if (this.participationType === "COMMUNITY" && this.title) {
            return this.title
        }

        return [
            capitalize(this.name || ""),
            capitalize(this.lastName || "")
        ]
            .filter((each) => each && each.length > 0)
            .join(" ")
    }

    constructor(object: AffAttendeeProfileProperties) {
        super(object)

        // AffAttendeeProfile Attributes
        this.lastName = object.lastName
        this.phoneNumber = object.phoneNumber
        this.timezone = object.timezone
        this.participationType = object.participationType
        this.dateOfBirth = object.dateOfBirth ? new Date(object.dateOfBirth) : null
    }

    // AffAttendeeProfile.sendWelcomeEmail()
    public sendWelcomeEmail()
    {
        try {
            const command = new AffAttendeeProfileSendWelcomeEmailCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
