//  RenewalOrder.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5168638f8c4a3916e2961bed97b2231f3#]

import {
    OMReference,
    CommandManager,
    DomainObject
} from "@apptivity-lab/firmament-node-sdk"

import { MerchOrder, MerchOrderProperties } from "../merchantable"

// Import related object types
import Subscription from "./Subscription"

// Import command types
import { RenewalOrderFulfillCommand } from "./RenewalOrderCommands"

// RenewalOrder Custom Datatypes
type OrderStatus = "CART" | "CHECKED_OUT" | "PAID" | "COMPLETED" | "CANCELLED" | "FAILED"
const OrderStatusOptions = [
    { title: "Cart", value: "CART" },
    { title: "Checked_Out", value: "CHECKED_OUT" },
    { title: "Paid", value: "PAID" },
    { title: "Completed", value: "COMPLETED" },
    { title: "Cancelled", value: "CANCELLED" },
    { title: "Failed", value: "FAILED" },
]

export interface RenewalOrderProperties extends MerchOrderProperties
{
    // RenewalOrder Attributes
    externalReferenceId?: string | null

    // RenewalOrder relationships
    subscription: OMReference<Subscription>
}

/**
 * RenewalOrder
 */
export default class RenewalOrder extends MerchOrder
    implements RenewalOrderProperties
{
    public static Type: string = "renewal_order"
    public static Path: string = "renewal_orders"

    // RenewalOrder Attributes
    public externalReferenceId?: string | null

    // Creates a placeholder RenewalOrder instance
    public static createPlaceholder() {
        return new RenewalOrder({
            currency: "MYR",
            status: "CART",
            subscription: new OMReference(Subscription, Subscription.createPlaceholder())
        })
    }

    // RenewalOrder Relationships
    public subscription: OMReference<Subscription>

    constructor(object: RenewalOrderProperties) {
        super(object)

        // RenewalOrder Attributes
        this.externalReferenceId = object.externalReferenceId

        // RenewalOrder Relationships
        this.subscription = new OMReference(Subscription, object.subscription)
    }

    // RenewalOrder.fulfill()
    public fulfill()
    {
        try {
            const command = new RenewalOrderFulfillCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
