//  CnxServiceTier.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5c38ad4489d802ab0f8e2d5d02e739a76#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import CnxMeeting from "./CnxMeeting"
import CnxMeetingTemplate from "./CnxMeetingTemplate"
import CnxConsumerProfile from "./CnxConsumerProfile"
import CnxProviderProfile from "./CnxProviderProfile"

// Import command types
import { CnxServiceTierAccessCommand } from "./CnxServiceTierCommands"

export interface CnxServiceTierProperties extends DomainObjectProperties
{
    // CnxServiceTier Attributes
    preserviceMins: number
    durationMins: number
    gracePeriodMins: number
    earningCurrency: string
    earningGrossCents: number
    earningPlatformFeeCents: number
    earningProcessingFeeCents: number
    serviceCurrency: string
    serviceGrossCents: number
    servicePlatformFeeCents: number
    serviceProcessingFeeCents: number
    maximumBids: number
    maximumBookings: number
    minimumBids: number
    minimumBookings: number
    serviceLeadType: string
    serviceTimingType: string

    // CnxServiceTier relationships
    meetings?: OMReference<CnxMeeting>[] | null
    meetingsCount?: number
    meetingTemplates?: OMReference<CnxMeetingTemplate>[] | null
    meetingTemplatesCount?: number
    consumers?: OMReference<CnxConsumerProfile>[] | null
    consumerCount?: number
    providers?: OMReference<CnxProviderProfile>[] | null
    providerCount?: number
}

/**
 * CnxServiceTier
 */
export default class CnxServiceTier extends DomainObject
    implements CnxServiceTierProperties
{
    public static Type: string = "cnx_service_tier"
    public static Path: string = "cnx_service_tiers"

    // Creates a placeholder CnxServiceTier instance
    public static createPlaceholder() {
        return new CnxServiceTier({
            preserviceMins: 10,
            durationMins: 15,
            gracePeriodMins: 5,
            earningCurrency: "",
            earningGrossCents: 0,
            earningPlatformFeeCents: 0,
            earningProcessingFeeCents: 0,
            serviceCurrency: "",
            serviceGrossCents: 0,
            servicePlatformFeeCents: 0,
            serviceProcessingFeeCents: 0,
            maximumBids: 1,
            maximumBookings: 1,
            minimumBids: 1,
            minimumBookings: 1,
            serviceLeadType: "PROVIDER",
            serviceTimingType: "PLANNED"
        })
    }

    // CnxServiceTier Attributes
    public preserviceMins: number
    public durationMins: number
    public gracePeriodMins: number
    public earningCurrency: string
    public earningGrossCents: number
    public earningPlatformFeeCents: number
    public earningProcessingFeeCents: number
    public serviceCurrency: string
    public serviceGrossCents: number
    public servicePlatformFeeCents: number
    public serviceProcessingFeeCents: number
    public maximumBids: number
    public maximumBookings: number
    public minimumBids: number
    public minimumBookings: number
    public serviceLeadType: string
    public serviceTimingType: string

    // CnxServiceTier Relationships
    public meetings?: Array<OMReference<CnxMeeting>> | null
    public meetingsCount: number
    public meetingTemplates?: Array<OMReference<CnxMeetingTemplate>> | null
    public meetingTemplatesCount: number
    public consumers?: Array<OMReference<CnxConsumerProfile>>| null
    public consumerCount: number
    public providers?: Array<OMReference<CnxProviderProfile>>| null
    public providerCount: number

    constructor(object: CnxServiceTierProperties) {
        super(object)

        // CnxServiceTier Attributes
        this.preserviceMins = object.preserviceMins
        this.durationMins = object.durationMins
        this.gracePeriodMins = object.gracePeriodMins
        this.earningCurrency = object.earningCurrency
        this.earningGrossCents = object.earningGrossCents
        this.earningPlatformFeeCents = object.earningPlatformFeeCents
        this.earningProcessingFeeCents = object.earningProcessingFeeCents
        this.serviceCurrency = object.serviceCurrency
        this.serviceGrossCents = object.serviceGrossCents
        this.servicePlatformFeeCents = object.servicePlatformFeeCents
        this.serviceProcessingFeeCents = object.serviceProcessingFeeCents
        this.maximumBids = object.maximumBids
        this.maximumBookings = object.maximumBookings
        this.minimumBids = object.minimumBids
        this.minimumBookings = object.minimumBookings
        this.serviceLeadType = object.serviceLeadType
        this.serviceTimingType = object.serviceTimingType

        // CnxServiceTier Relationships
        this.meetings = object.meetings ? object.meetings.map((item) => new OMReference(CnxMeeting, item)) : []
        this.meetingsCount = object.meetingsCount || 0
        this.meetingTemplates = object.meetingTemplates ? object.meetingTemplates.map((item) => new OMReference(CnxMeetingTemplate, item)) : []
        this.meetingTemplatesCount = object.meetingTemplatesCount || 0
        this.consumers = object.consumers ? object.consumers.map((item) => new OMReference(CnxConsumerProfile, item)) : []
        this.consumerCount = object.consumerCount || 0
        this.providers = object.providers ? object.providers.map((item) => new OMReference(CnxProviderProfile, item)) : []
        this.providerCount = object.providerCount || 0

    }

    // CnxServiceTier.access()
    // @param profile: string
    public access(profile: string)
    {
        try {
            const command = new CnxServiceTierAccessCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
