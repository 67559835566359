//  PmtStripePayment.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5ccb3487904fb3e94a81bd5e91ae0865b#]

import {
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

import { PmtPayment, PmtPaymentProperties } from "../payments"

// Import related object types
import { DomainProfile } from "../kernel"

// Import command types
import { PmtStripePaymentFetchStatusCommand } from "./PmtStripePaymentCommands"

export interface PmtStripePaymentProperties extends PmtPaymentProperties
{
    // PmtStripePayment Attributes
    paymentIntentId?: string | null
}

/**
 * PmtStripePayment
 */
export default class PmtStripePayment extends PmtPayment
    implements PmtStripePaymentProperties
{
    public static Type: string = "pmt_stripe_payment"
    public static Path: string = "pmt_stripe_payments"

    // Creates a placeholder PmtStripePayment instance
    public static createPlaceholder() {
        return new PmtStripePayment({
            amountInCents: 0,
            currency: "",
            status: "PENDING",
            customer: new OMReference(DomainProfile, DomainProfile.createPlaceholder())
        })
    }

    // PmtStripePayment Attributes
    public paymentIntentId?: string | null

    constructor(object: PmtStripePaymentProperties) {
        super(object)

        // PmtStripePayment Attributes
        this.paymentIntentId = object.paymentIntentId
    }

    // PmtStripePayment.fetchStatus()
    public fetchStatus()
    {
        try {
            const command = new PmtStripePaymentFetchStatusCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
