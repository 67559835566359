//  CnxConsumerPurchase.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5c20c7d9f8bb4c83ac060af68e2f04c73#]

import {
    OMReference,
    DomainObject
} from "@apptivity-lab/firmament-node-sdk"

import { WltTransaction, WltTransactionProperties } from "../wallet"

// Import related object types
import CnxBooking from "./CnxBooking"
import CnxMeeting from "./CnxMeeting"
import { WltWallet } from "../wallet"

// CnxConsumerPurchase Custom Datatypes
type TransactionStatus = "PENDING" | "APPROVED" | "REJECTED"
const TransactionStatusOptions = [
    { title: "Pending", value: "PENDING" },
    { title: "Approved", value: "APPROVED" },
    { title: "Rejected", value: "REJECTED" },
]
type TransactionType = "DEBIT" | "CREDIT"
const TransactionTypeOptions = [
    { title: "Debit", value: "DEBIT" },
    { title: "Credit", value: "CREDIT" },
]

export interface CnxConsumerPurchaseProperties extends WltTransactionProperties
{
    // CnxConsumerPurchase relationships
    bookings?: OMReference<CnxBooking>[] | null
    bookingsCount?: number
    meeting?: OMReference<CnxMeeting> | null
}

/**
 * CnxConsumerPurchase
 */
export default class CnxConsumerPurchase extends WltTransaction
    implements CnxConsumerPurchaseProperties
{
    public static Type: string = "cnx_consumer_purchase"
    public static Path: string = "cnx_consumer_purchases"

    // Creates a placeholder CnxConsumerPurchase instance
    public static createPlaceholder() {
        return new CnxConsumerPurchase({
            amountInCents: 0,
            currency: "",
            transactionStatus: "PENDING",
            transactionType: "DEBIT",
            wallet: new OMReference(WltWallet, WltWallet.createPlaceholder())
        })
    }

    // CnxConsumerPurchase Relationships
    public bookings?: Array<OMReference<CnxBooking>> | null
    public bookingsCount: number
    public meeting?: OMReference<CnxMeeting> | null

    constructor(object: CnxConsumerPurchaseProperties) {
        super(object)

        // CnxConsumerPurchase Relationships
        this.bookings = object.bookings ? object.bookings.map((item) => new OMReference(CnxBooking, item)) : []
        this.bookingsCount = object.bookingsCount || 0
        this.meeting = object.meeting ? new OMReference(CnxMeeting, object.meeting) : null
    }
}
