//  CnxVideoRoom.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD58efea3170f8995c585423d75683580b7#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import CnxSnapshotPhoto from "./CnxSnapshotPhoto"

export interface CnxVideoRoomProperties extends DomainObjectProperties
{
    // CnxVideoRoom Attributes
    consumerRecordingUrls?: string[] | null
    providerRecordingUrl?: string | null
    roomStatus: string
    roomUrl?: string | null
    sid?: string | null
    sizeType?: string | null

    // CnxVideoRoom relationships
    snapshots?: OMReference<CnxSnapshotPhoto>[] | null
    snapshotsCount?: number
}

/**
 * CnxVideoRoom
 */
export default class CnxVideoRoom extends DomainObject
    implements CnxVideoRoomProperties
{
    public static Type: string = "cnx_video_room"
    public static Path: string = "cnx_video_rooms"

    // Creates a placeholder CnxVideoRoom instance
    public static createPlaceholder() {
        return new CnxVideoRoom({
            roomStatus: "unstarted"
        })
    }

    // CnxVideoRoom Attributes
    public consumerRecordingUrls?: string[] | null
    public providerRecordingUrl?: string | null
    public roomStatus: string
    public roomUrl?: string | null
    public sid?: string | null
    public sizeType?: string | null

    // CnxVideoRoom Relationships
    public snapshots?: Array<OMReference<CnxSnapshotPhoto>> | null
    public snapshotsCount: number

    constructor(object: CnxVideoRoomProperties) {
        super(object)

        // CnxVideoRoom Attributes
        this.consumerRecordingUrls = object.consumerRecordingUrls
        this.providerRecordingUrl = object.providerRecordingUrl
        this.roomStatus = object.roomStatus
        this.roomUrl = object.roomUrl
        this.sid = object.sid
        this.sizeType = object.sizeType

        // CnxVideoRoom Relationships
        this.snapshots = object.snapshots ? object.snapshots.map((item) => new OMReference(CnxSnapshotPhoto, item)) : []
        this.snapshotsCount = object.snapshotsCount || 0
    }
}
