//  CatCategory.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Protocols adopted
import { Publishable, ConcretePublishable } from "../publishable"
import { Sequenceable, ConcreteSequenceable } from "../sequenceable"
import { Appearance, ConcreteAppearance } from "../appearance"
import { Discoverable, ConcreteDiscoverable } from "../discovery"

// Import related object types
import { GenImage } from "../files"
import ConcreteCategorizable from "./Categorizable"
import { DcvListSection } from "../discovery"

// Import command types
import { CatCategoryDeleteBranchCommand, CatCategoryRefreshBranchCommand } from "./CatCategoryCommands"

// CatCategory Custom Datatypes
export type CategoryType = "GENERIC" | "MEETING_TYPE" | "ACCESSIBILITY" | "INTENSITY" | "SUBJECT"
export const CategoryTypeOptions = [
    { title: "Generic", value: "GENERIC" },
    { title: "Meeting Type", value: "MEETING_TYPE" },
    { title: "Accessibility", value: "ACCESSIBILITY" },
    { title: "Intensity", value: "INTENSITY" },
    { title: "Subject", value: "SUBJECT" },
]

export interface CatCategoryProperties extends DomainObjectProperties,
    Publishable, Sequenceable, Appearance, Discoverable
{
    // CatCategory Attributes
    categoryParentPath?: string[] | null
    categoryType: CategoryType
    summary?: string | null

    // CatCategory relationships
    subcategories?: OMReference<CatCategory>[] | null
    subcategoriesCount?: number
    parentCategory?: OMReference<CatCategory> | null
    subjects?: OMReference<ConcreteCategorizable>[] | null
    subjectCount?: number
}

/**
 * CatCategory
 */
export default class CatCategory extends DomainObject
    implements CatCategoryProperties
{
    public static Type: string = "cat_category"
    public static Path: string = "cat_categories"

    // Creates a placeholder CatCategory instance
    public static createPlaceholder() {
        return new CatCategory({
            categoryType: "GENERIC"
        })
    }

    // CatCategory Attributes
    public categoryParentPath?: string[] | null
    public categoryType: CategoryType
    public summary?: string | null

    // CatCategory Relationships
    public subcategories?: Array<OMReference<CatCategory>> | null
    public subcategoriesCount: number
    public parentCategory?: OMReference<CatCategory> | null
    public subjects?: Array<OMReference<ConcreteCategorizable>>| null
    public subjectCount: number

    // Publishable Protocol
    public publishAt?: Date | null
    public unpublishAt?: Date | null

    // Sequenceable Protocol
    public sequence?: number | null

    // Appearance Protocol
    public backgroundColor?: string | null
    public foregroundColor?: string | null
    public backgroundPhoto?: OMReference<GenImage> | null
    public icon?: OMReference<GenImage> | null

    // Discoverable Protocol
    public listSections?: Array<OMReference<DcvListSection>>| null
    public listSectionCount: number

    constructor(object: CatCategoryProperties) {
        super(object)

        // CatCategory Attributes
        this.categoryParentPath = object.categoryParentPath
        this.categoryType = object.categoryType
        this.summary = object.summary

        // CatCategory Relationships
        this.subcategories = object.subcategories ? object.subcategories.map((item) => new OMReference(CatCategory, item)) : []
        this.subcategoriesCount = object.subcategoriesCount || 0
        this.parentCategory = object.parentCategory ? new OMReference(CatCategory, object.parentCategory) : null
        this.subjects = object.subjects ? object.subjects.map((item) => new OMReference(ConcreteCategorizable, item)) : []
        this.subjectCount = object.subjectCount || 0

        // Publishable Protocol
        this.publishAt = object.publishAt ? new Date(object.publishAt) : null
        this.unpublishAt = object.unpublishAt ? new Date(object.unpublishAt) : null

        // Sequenceable Protocol
        this.sequence = object.sequence

        // Appearance Protocol
        this.backgroundColor = object.backgroundColor
        this.foregroundColor = object.foregroundColor
        this.backgroundPhoto = object.backgroundPhoto ? new OMReference(GenImage, object.backgroundPhoto) : null
        this.icon = object.icon ? new OMReference(GenImage, object.icon) : null

        // Discoverable Protocol
        this.listSections = object.listSections ? object.listSections.map((item) => new OMReference(DcvListSection, item)) : []
        this.listSectionCount = object.listSectionCount || 0
    }

    // CatCategory.deleteBranch()
    public deleteBranch()
    {
        try {
            const command = new CatCategoryDeleteBranchCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // CatCategory.refreshBranch()
    public refreshBranch()
    {
        try {
            const command = new CatCategoryRefreshBranchCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
