//  MerchBusiness.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5c0d49468ca0cd0fe47cdc4162c48a230#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import ConcreteMerchantable from "./Merchantable"
import MerchOrder from "./MerchOrder"

export interface MerchBusiness extends DomainObjectProperties
{
    // MerchBusiness relationships
    merchantables?: OMReference<ConcreteMerchantable>[] | null
    merchantablesCount?: number
    orders?: OMReference<MerchOrder>[] | null
    ordersCount?: number
}

/**
 * ConcreteMerchBusiness
 */
export default class ConcreteMerchBusiness extends DomainObject
    implements MerchBusiness
{
    public static Type: string = "merch_business"
    public static Path: string = "merch_businesses"

    // Creates a placeholder ConcreteMerchBusiness instance
    public static createPlaceholder() {
        return new ConcreteMerchBusiness({ })
    }

    // MerchBusiness Relationships
    public merchantables?: Array<OMReference<ConcreteMerchantable>> | null
    public merchantablesCount: number
    public orders?: Array<OMReference<MerchOrder>> | null
    public ordersCount: number

    constructor(object: MerchBusiness) {
        super(object)

        // MerchBusiness Relationships
        this.merchantables = object.merchantables ? object.merchantables.map((item) => new OMReference(ConcreteMerchantable, item)) : []
        this.merchantablesCount = object.merchantablesCount || 0
        this.orders = object.orders ? object.orders.map((item) => new OMReference(MerchOrder, item)) : []
        this.ordersCount = object.ordersCount || 0
    }
}
