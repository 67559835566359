//  Setting.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import {
    OMObject,
    OMObjectProperties
} from "@apptivity-lab/firmament-node-sdk"

export interface SettingProperties extends OMObjectProperties
{
    // Setting Attributes
    key: string
    value?: string | null
    bundleIdentifier?: string | null
    environment?: string | null
    maxBuildNumber?: number | null
    minBuildNumber?: number | null
    module?: string | null
    platform?: string | null
}

/**
 * Setting
 */
export default class Setting extends OMObject
    implements SettingProperties
{
    public static Type: string = "setting"
    public static Path: string = "settings"

    // Creates a placeholder Setting instance
    public static createPlaceholder() {
        return new Setting({
            key: ""
        })
    }

    // Setting Attributes
    public key: string
    public value?: string | null
    public bundleIdentifier?: string | null
    public environment?: string | null
    public maxBuildNumber?: number | null
    public minBuildNumber?: number | null
    public module?: string | null
    public platform?: string | null

    constructor(object: SettingProperties) {
        super(object)

        // Setting Attributes
        this.key = object.key
        this.value = object.value
        this.bundleIdentifier = object.bundleIdentifier
        this.environment = object.environment
        this.maxBuildNumber = object.maxBuildNumber
        this.minBuildNumber = object.minBuildNumber
        this.module = object.module
        this.platform = object.platform
    }
}
