export const capitalize = (input: string) => {
    const words = input.split(" ")

    return words.map((word) => {
        const letters = word.toLowerCase().split("")
        if (letters.length > 0) {
            return letters[0].toLocaleUpperCase() + letters.slice(1).join("")
        } else {
            return word
        }
    }).join(" ")
}
