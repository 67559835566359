//  PmtCurrencyPair.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5d6503342f3dfb947162f0608782ae311#]

import {
    OMObject,
    OMObjectProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import PmtCurrencyRate from "./PmtCurrencyRate"

// Import command types
import { PmtCurrencyPairAwakeCommand } from "./PmtCurrencyPairCommands"

export interface PmtCurrencyPairProperties extends OMObjectProperties
{
    // PmtCurrencyPair Attributes
    baseCurrency: string
    currency: string
    currencySymbol: string
    exchangeSpread: number
    maximumRate?: number | null
    minimumRate?: number | null

    // PmtCurrencyPair relationships
    exchangeRates?: OMReference<PmtCurrencyRate>[] | null
    exchangeRatesCount?: number
    exchangeRatesLatestRate?: number | null
}

/**
 * PmtCurrencyPair
 */
export default class PmtCurrencyPair extends OMObject
    implements PmtCurrencyPairProperties
{
    public static Type: string = "pmt_currency_pair"
    public static Path: string = "pmt_currency_pairs"

    // Creates a placeholder PmtCurrencyPair instance
    public static createPlaceholder() {
        return new PmtCurrencyPair({
            baseCurrency: "",
            currency: "",
            currencySymbol: "",
            exchangeSpread: 1.0
        })
    }

    // PmtCurrencyPair Attributes
    public baseCurrency: string
    public currency: string
    public currencySymbol: string
    public exchangeSpread: number
    public maximumRate?: number | null
    public minimumRate?: number | null

    // PmtCurrencyPair Relationships
    public exchangeRates?: Array<OMReference<PmtCurrencyRate>> | null
    public exchangeRatesCount: number
    public exchangeRatesLatestRate: number

    constructor(object: PmtCurrencyPairProperties) {
        super(object)

        // PmtCurrencyPair Attributes
        this.baseCurrency = object.baseCurrency
        this.currency = object.currency
        this.currencySymbol = object.currencySymbol
        this.exchangeSpread = object.exchangeSpread
        this.maximumRate = object.maximumRate
        this.minimumRate = object.minimumRate

        // PmtCurrencyPair Relationships
        this.exchangeRates = object.exchangeRates ? object.exchangeRates.map((item) => new OMReference(PmtCurrencyRate, item)) : []
        this.exchangeRatesCount = object.exchangeRatesCount || 0
        this.exchangeRatesLatestRate = object.exchangeRatesLatestRate || 0

    }

    // PmtCurrencyPair.awake()
    public awake()
    {
        try {
            const command = new PmtCurrencyPairAwakeCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
