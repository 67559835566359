//  MsgEmailTemplate.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD50b3a75b541c97c116cceee3c4a610338#]

import {
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

import { default as MsgTemplate, MsgTemplateProperties } from "./MsgTemplate"

// Import related object types
import MsgSentMessage from "./MsgSentMessage"

export interface MsgEmailTemplateProperties extends MsgTemplateProperties
{
    // MsgEmailTemplate Attributes
    bodyHtml?: string | null
    senderEmail?: string | null
    senderName?: string | null
    subject?: string | null
    bcc?: string[] | null
    cc?: string[] | null
}

/**
 * MsgEmailTemplate
 */
export default class MsgEmailTemplate extends MsgTemplate
    implements MsgEmailTemplateProperties
{
    public static Type: string = "msg_email_template"
    public static Path: string = "msg_email_templates"

    // Creates a placeholder MsgEmailTemplate instance
    public static createPlaceholder() {
        return new MsgEmailTemplate({
            key: ""
        })
    }

    // MsgEmailTemplate Attributes
    public bodyHtml?: string | null
    public senderEmail?: string | null
    public senderName?: string | null
    public subject?: string | null
    public bcc?: string[] | null
    public cc?: string[] | null

    constructor(object: MsgEmailTemplateProperties) {
        super(object)

        // MsgEmailTemplate Attributes
        this.bodyHtml = object.bodyHtml
        this.senderEmail = object.senderEmail
        this.senderName = object.senderName
        this.subject = object.subject
        this.bcc = object.bcc
        this.cc = object.cc
    }
}
