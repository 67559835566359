//  FlaggableCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { ConcreteFlaggable } from "."

/* FlagCommand */
export class FlagCommand extends Command<ConcreteFlaggable>
{
    public static readonly Path = "flag"
    public static readonly Type = "flag_command"

    public profile?: string | null
		public reason?: string | null

    public constructor(
        receiver: ConcreteFlaggable,
        profile?: string | null,
		reason?: string | null
    ) {
        super(receiver)

        this.profile = profile
		this.reason = reason
    }
}



/* UnflagCommand */
export class UnflagCommand extends Command<ConcreteFlaggable>
{
    public static readonly Path = "unflag"
    public static readonly Type = "unflag_command"

    public profile?: string | null

    public constructor(
        receiver: ConcreteFlaggable,
        profile?: string | null
    ) {
        super(receiver)

        this.profile = profile
    }
}


