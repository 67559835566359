//  PmtCurrencyPairCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD542bcfbaf972f0d90ebf10f0d531bbab0#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { PmtCurrencyPair } from "."

/* PmtCurrencyPairAwakeCommand */
export class PmtCurrencyPairAwakeCommand extends Command<PmtCurrencyPair>
{
    public static readonly Path = "awake"
    public static readonly Type = "pmt_currency_pair_awake_command"
}
