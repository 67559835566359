const fbTrackEvent = (event, options) => {
    if (process.env.UNIVERSE_ENVIRONMENT !== "production") {
        return
    }

    window.fbq("track", event, options)
}

export {
    fbTrackEvent
}
