//  NotifBroadcast.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD55078fb097505efb346819eed78da82e9#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import { Group } from "../kernel"
import NotifMessage from "./NotifMessage"
import { DomainProfile } from "../kernel"

// Import command types
import { NotifBroadcastCreateGroupMessagesCommand, NotifBroadcastCreateMessageCommand } from "./NotifBroadcastCommands"

export interface NotifBroadcastProperties extends DomainObjectProperties
{
    // NotifBroadcast Attributes
    email?: string[] | null
    smsPhoneNumber?: string[] | null
    pushEndpointArn?: string[] | null
    pushTopicArn?: string | null
    deliverEmail: boolean
    deliverSms: boolean
    deliverPush: boolean
    actionUrl?: string | null
    bodyHtml?: string | null
    body: string
    targetType?: string | null
    sound?: string | null

    // NotifBroadcast relationships
    targetGroup?: OMReference<Group> | null
    messages?: OMReference<NotifMessage>[] | null
    messagesCount?: number
    targetProfile?: OMReference<DomainProfile> | null
}

/**
 * NotifBroadcast
 */
export default class NotifBroadcast extends DomainObject
    implements NotifBroadcastProperties
{
    public static Type: string = "notif_broadcast"
    public static Path: string = "notif_broadcasts"

    // Creates a placeholder NotifBroadcast instance
    public static createPlaceholder() {
        return new NotifBroadcast({
            deliverEmail: false,
            deliverSms: false,
            deliverPush: true,
            body: ""
        })
    }

    // NotifBroadcast Attributes
    public email?: string[] | null
    public smsPhoneNumber?: string[] | null
    public pushEndpointArn?: string[] | null
    public pushTopicArn?: string | null
    public deliverEmail: boolean
    public deliverSms: boolean
    public deliverPush: boolean
    public actionUrl?: string | null
    public bodyHtml?: string | null
    public body: string
    public targetType?: string | null
    public sound?: string | null

    // NotifBroadcast Relationships
    public targetGroup?: OMReference<Group> | null
    public messages?: Array<OMReference<NotifMessage>> | null
    public messagesCount: number
    public targetProfile?: OMReference<DomainProfile> | null

    constructor(object: NotifBroadcastProperties) {
        super(object)

        // NotifBroadcast Attributes
        this.email = object.email
        this.smsPhoneNumber = object.smsPhoneNumber
        this.pushEndpointArn = object.pushEndpointArn
        this.pushTopicArn = object.pushTopicArn
        this.deliverEmail = object.deliverEmail
        this.deliverSms = object.deliverSms
        this.deliverPush = object.deliverPush
        this.actionUrl = object.actionUrl
        this.bodyHtml = object.bodyHtml
        this.body = object.body
        this.targetType = object.targetType
        this.sound = object.sound

        // NotifBroadcast Relationships
        this.targetGroup = object.targetGroup ? new OMReference(Group, object.targetGroup) : null
        this.messages = object.messages ? object.messages.map((item) => new OMReference(NotifMessage, item)) : []
        this.messagesCount = object.messagesCount || 0
        this.targetProfile = object.targetProfile ? new OMReference(DomainProfile, object.targetProfile) : null
    }

    // NotifBroadcast.createGroupMessages()
    public createGroupMessages()
    {
        try {
            const command = new NotifBroadcastCreateGroupMessagesCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // NotifBroadcast.createMessage()
    public createMessage()
    {
        try {
            const command = new NotifBroadcastCreateMessageCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
