//  DcvDiscoveryLayout.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import {
    DomainObject,
    DomainObjectProperties
} from "@apptivity-lab/firmament-node-sdk"

// DcvDiscoveryLayout Custom Datatypes
type DiscoveryType = "LINK" | "CATEGORY"
const DiscoveryTypeOptions = [
    { title: "Link", value: "LINK" },
    { title: "Category", value: "CATEGORY" },
]
type DiscoveryLayout = "SMALL_GRID" | "MEDIUM_GRID" | "LARGE_GRID" | "SMALL_LIST" | "MEDIUM_LIST" | "LARGE_LIST"
const DiscoveryLayoutOptions = [
    { title: "SMALL_GRID", value: "SMALL_GRID" },
    { title: "MEDIUM_GRID", value: "MEDIUM_GRID" },
    { title: "LARGE_GRID", value: "LARGE_GRID" },
    { title: "SMALL_LIST", value: "SMALL_LIST" },
    { title: "MEDIUM_LIST", value: "MEDIUM_LIST" },
    { title: "LARGE_LIST", value: "LARGE_LIST" },
]

export interface DcvDiscoveryLayoutProperties extends DomainObjectProperties
{
    // DcvDiscoveryLayout Attributes
    layoutTypes: DiscoveryLayout[]
    itemType: DiscoveryType
}

/**
 * DcvDiscoveryLayout
 */
export default class DcvDiscoveryLayout extends DomainObject
    implements DcvDiscoveryLayoutProperties
{
    public static Type: string = "dcv_discovery_layout"
    public static Path: string = "dcv_discovery_layouts"

    // Creates a placeholder DcvDiscoveryLayout instance
    public static createPlaceholder() {
        return new DcvDiscoveryLayout({
            layoutTypes: ["SMALL_GRID"],
            itemType: "LINK"
        })
    }

    // DcvDiscoveryLayout Attributes
    public layoutTypes: DiscoveryLayout[]
    public itemType: DiscoveryType



    constructor(object: DcvDiscoveryLayoutProperties) {
        super(object)

        // DcvDiscoveryLayout Attributes
        this.layoutTypes = object.layoutTypes
        this.itemType = object.itemType


    }

}
