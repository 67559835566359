//  CnxProviderProfile.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD535b92163cd59a23ec2198373e26a7709#]

import {
    OMReference,
    User,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

import { default as CnxMemberProfile, CnxMemberProfileProperties } from "./CnxMemberProfile"
// Protocols adopted
import { Approveable, ConcreteApproveable } from "../approveable"

// Import related object types
import CnxBid from "./CnxBid"
import CnxServiceTier from "./CnxServiceTier"
import CnxArticle from "./CnxArticle"
import CnxConsumerProfile from "./CnxConsumerProfile"
import CnxMeeting from "./CnxMeeting"
import CnxMeetingTemplate from "./CnxMeetingTemplate"

// Import command types
import { CnxProviderProfileFollowCommand, CnxProviderProfileSetTiersCommand, CnxProviderProfileSummarizeMeetingsCommand, CnxProviderProfileUnfollowCommand } from "./CnxProviderProfileCommands"

// CnxProviderProfile Custom Datatypes
type ApprovalStatus = "PENDING" | "APPROVED" | "REJECTED"
const ApprovalStatusOptions = [
    { title: "Pending", value: "PENDING" },
    { title: "Approved", value: "APPROVED" },
    { title: "Rejected", value: "REJECTED" },
]

export interface CnxProviderProfileProperties extends CnxMemberProfileProperties,
    Approveable
{
    // CnxProviderProfile Attributes
    bio?: string | null
    isOnline: boolean
    shortIntro?: string | null

    // CnxProviderProfile relationships
    bids?: OMReference<CnxBid>[] | null
    bidsCount?: number
    serviceTiers?: OMReference<CnxServiceTier>[] | null
    serviceTierCount?: number
    providerArticles?: OMReference<CnxArticle>[] | null
    providerArticleCount?: number
    favoritedByConsumers?: OMReference<CnxConsumerProfile>[] | null
    favoritedByConsumerCount?: number
    meetings?: OMReference<CnxMeeting>[] | null
    meetingsCount?: number
    requestedMeetings?: OMReference<CnxMeeting>[] | null
    requestedMeetingCount?: number
    meetingTemplates?: OMReference<CnxMeetingTemplate>[] | null
    meetingTemplatesCount?: number
}

/**
 * CnxProviderProfile
 */
export default class CnxProviderProfile extends CnxMemberProfile
    implements CnxProviderProfileProperties
{
    public static Type: string = "cnx_provider_profile"
    public static Path: string = "cnx_provider_profiles"

    // Creates a placeholder CnxProviderProfile instance
    public static createPlaceholder() {
        return new CnxProviderProfile({
            isOnline: false,
            approvalState: "PENDING",
            user: new OMReference(User, User.createPlaceholder())
        })
    }

    // CnxProviderProfile Attributes
    public bio?: string | null
    public isOnline: boolean
    public shortIntro?: string | null

    // CnxProviderProfile Relationships
    public bids?: Array<OMReference<CnxBid>> | null
    public bidsCount: number
    public serviceTiers?: Array<OMReference<CnxServiceTier>>| null
    public serviceTierCount: number
    public providerArticles?: Array<OMReference<CnxArticle>>| null
    public providerArticleCount: number
    public favoritedByConsumers?: Array<OMReference<CnxConsumerProfile>>| null
    public favoritedByConsumerCount: number
    public meetings?: Array<OMReference<CnxMeeting>> | null
    public meetingsCount: number
    public requestedMeetings?: Array<OMReference<CnxMeeting>>| null
    public requestedMeetingCount: number
    public meetingTemplates?: Array<OMReference<CnxMeetingTemplate>> | null
    public meetingTemplatesCount: number

    // Approveable Protocol
    public approvalState: ApprovalStatus
    public approvedAt?: Date | null
    public rejectedAt?: Date | null
    public rejectionReason?: string | null

    constructor(object: CnxProviderProfileProperties) {
        super(object)

        // CnxProviderProfile Attributes
        this.bio = object.bio
        this.isOnline = object.isOnline
        this.shortIntro = object.shortIntro

        // CnxProviderProfile Relationships
        this.bids = object.bids ? object.bids.map((item) => new OMReference(CnxBid, item)) : []
        this.bidsCount = object.bidsCount || 0
        this.serviceTiers = object.serviceTiers ? object.serviceTiers.map((item) => new OMReference(CnxServiceTier, item)) : []
        this.serviceTierCount = object.serviceTierCount || 0
        this.providerArticles = object.providerArticles ? object.providerArticles.map((item) => new OMReference(CnxArticle, item)) : []
        this.providerArticleCount = object.providerArticleCount || 0
        this.favoritedByConsumers = object.favoritedByConsumers ? object.favoritedByConsumers.map((item) => new OMReference(CnxConsumerProfile, item)) : []
        this.favoritedByConsumerCount = object.favoritedByConsumerCount || 0
        this.meetings = object.meetings ? object.meetings.map((item) => new OMReference(CnxMeeting, item)) : []
        this.meetingsCount = object.meetingsCount || 0
        this.requestedMeetings = object.requestedMeetings ? object.requestedMeetings.map((item) => new OMReference(CnxMeeting, item)) : []
        this.requestedMeetingCount = object.requestedMeetingCount || 0
        this.meetingTemplates = object.meetingTemplates ? object.meetingTemplates.map((item) => new OMReference(CnxMeetingTemplate, item)) : []
        this.meetingTemplatesCount = object.meetingTemplatesCount || 0

        // Approveable Protocol
        this.approvalState = object.approvalState
        this.approvedAt = object.approvedAt ? new Date(object.approvedAt) : null
        this.rejectedAt = object.rejectedAt ? new Date(object.rejectedAt) : null
        this.rejectionReason = object.rejectionReason
    }

    // Approveable.approve()
    public approve()
    {
        return (new ConcreteApproveable(this)).approve()
    }


    // Approveable.reapply()
    public reapply()
    {
        return (new ConcreteApproveable(this)).reapply()
    }


    // Approveable.reject()
    // @param reason?: string
    public reject(reason?: string)
    {
        return (new ConcreteApproveable(this)).reject(reason)
    }

    // CnxProviderProfile.follow()
    // @param profile: string
    public follow(profile: string)
    {
        try {
            const command = new CnxProviderProfileFollowCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // CnxProviderProfile.setServiceTiers()
    // @param serviceTiers: string[]
    public setServiceTiers(serviceTiers?: string[])
    {
        try {
            const command = new CnxProviderProfileSetTiersCommand(this, serviceTiers)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // CnxProviderProfile.summarizeMeetings()
    public summarizeMeetings()
    {
        try {
            const command = new CnxProviderProfileSummarizeMeetingsCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // CnxProviderProfile.unfollow()
    // @param profile: string
    public unfollow(profile: string)
    {
        try {
            const command = new CnxProviderProfileUnfollowCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
