//  CnxMeeting.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD57fe56673fd8032bae5d73fa8f0eabf8e#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Protocols adopted
import { Categorizable, ConcreteCategorizable } from "../categories"
import { Rateable, ConcreteRateable } from "../ratings"

// Import related object types
import { CatCategory } from "../categories"
import CnxBid from "./CnxBid"
import CnxBooking from "./CnxBooking"
import CnxConsumerProfile from "./CnxConsumerProfile"
import { GenImage } from "../files"
import CnxMemberProfile from "./CnxMemberProfile"
import CnxMessage from "./CnxMessage"
import CnxProviderProfile from "./CnxProviderProfile"
import CnxVideoRoom from "./CnxVideoRoom"
import { SrrRating } from "../ratings"
import CnxConsumerPurchase from "./CnxConsumerPurchase"
import CnxMeetingTemplate from "./CnxMeetingTemplate"
import CnxServiceTier from "./CnxServiceTier"

// Import command types
import { CnxMeetingAddConsumerCommand, CnxMeetingAwakeCommand, CnxMeetingExpireCommand, CnxMeetingGetRoomTokenCommand, CnxMeetingJoinCommand, CnxMeetingLeaveCommand, CnxMeetingNotifyMatchProvidersCommand, CnxMeetingOfferCommand, CnxMeetingRemoveConsumerCommand, CnxMeetingSetMatchProvidersCommand, CnxMeetingSetServiceCategoriesCommand } from "./CnxMeetingCommands"

// CnxMeeting Custom Datatypes
export type ServiceTimingType = "ON_DEMAND" | "PLANNED"
export const ServiceTimingTypeOptions = [
    { title: "On Demand", value: "ON_DEMAND" },
    { title: "Planned", value: "PLANNED" },
]
export type ServiceLeadType = "CONSUMER" | "PROVIDER"
export const ServiceLeadTypeOptions = [
    { title: "Consumer", value: "CONSUMER" },
    { title: "Provider", value: "PROVIDER" },
]
export type MeetingStatus = "SCHEDULED" | "PENDING" | "CONFIRMED" | "STARTED" | "COMPLETED" | "FAILED"
export const MeetingStatusOptions = [
    { title: "Scheduled", value: "SCHEDULED" },
    { title: "Pending", value: "PENDING" },
    { title: "Confirmed", value: "CONFIRMED" },
    { title: "Started", value: "STARTED" },
    { title: "Completed", value: "COMPLETED" },
    { title: "Failed", value: "FAILED" },
]

export interface CnxMeetingProperties extends DomainObjectProperties,
    Categorizable, Rateable
{
    // CnxMeeting Attributes
    summary?: string | null
    meetingStatus: MeetingStatus
    serviceLeadType: ServiceLeadType
    serviceTimingType: ServiceTimingType
    scheduledStartAt?: Date | null
    scheduledEndAt?: Date | null
    actualStartAt?: Date | null
    actualEndAt?: Date | null
    expireAt?: Date | null
    minBookings: number
    maxBookings: number
    minBids: number
    maxBids: number

    // CnxMeeting relationships
    attendees?: OMReference<CnxConsumerProfile>[] | null
    attendeeCount?: number
    acceptedBid?: OMReference<CnxBid> | null
    bids?: OMReference<CnxBid>[] | null
    bidsCount?: number
    bookings?: OMReference<CnxBooking>[] | null
    bookingsCount?: number
    consumers?: OMReference<CnxConsumerProfile>[] | null
    consumerCount?: number
    coverPhoto?: OMReference<GenImage> | null
    currentParticipants?: OMReference<CnxMemberProfile>[] | null
    currentParticipantsCount?: number
    transcript?: OMReference<CnxMessage>[] | null
    transcriptCount?: number
    provider?: OMReference<CnxProviderProfile> | null
    matchProviders?: OMReference<CnxProviderProfile>[] | null
    matchProviderCount?: number
    matchProviderSet?: string[] | null
    videoRoom?: OMReference<CnxVideoRoom> | null
    purchases?: OMReference<CnxConsumerPurchase>[] | null
    purchasesCount?: number
    meetingTemplate?: OMReference<CnxMeetingTemplate> | null
    serviceTier: OMReference<CnxServiceTier>
}

/**
 * CnxMeeting
 */
export default class CnxMeeting extends DomainObject
    implements CnxMeetingProperties
{
    public static Type: string = "cnx_meeting"
    public static Path: string = "cnx_meetings"

    // Creates a placeholder CnxMeeting instance
    public static createPlaceholder() {
        return new CnxMeeting({
            meetingStatus: "SCHEDULED",
            serviceLeadType: "PROVIDER",
            serviceTimingType: "PLANNED",
            minBookings: 1,
            maxBookings: 1,
            minBids: 1,
            maxBids: 1,
            serviceTier: new OMReference(CnxServiceTier, CnxServiceTier.createPlaceholder())
        })
    }

    // CnxMeeting Attributes
    public summary?: string | null
    public meetingStatus: MeetingStatus
    public serviceLeadType: ServiceLeadType
    public serviceTimingType: ServiceTimingType
    public scheduledStartAt?: Date | null
    public scheduledEndAt?: Date | null
    public actualStartAt?: Date | null
    public actualEndAt?: Date | null
    public expireAt?: Date | null
    public minBookings: number
    public maxBookings: number
    public minBids: number
    public maxBids: number

    // CnxMeeting Relationships
    public attendees?: Array<OMReference<CnxConsumerProfile>>| null
    public attendeeCount: number
    public acceptedBid?: OMReference<CnxBid> | null
    public bids?: Array<OMReference<CnxBid>> | null
    public bidsCount: number
    public bookings?: Array<OMReference<CnxBooking>> | null
    public bookingsCount: number
    public consumers?: Array<OMReference<CnxConsumerProfile>>| null
    public consumerCount: number
    public coverPhoto?: OMReference<GenImage> | null
    public currentParticipants?: Array<OMReference<CnxMemberProfile>> | null
    public currentParticipantsCount: number
    public transcript?: Array<OMReference<CnxMessage>> | null
    public transcriptCount: number
    public provider?: OMReference<CnxProviderProfile> | null
    public matchProviders?: Array<OMReference<CnxProviderProfile>>| null
    public matchProviderCount: number
    public matchProviderSet: string[]
    public videoRoom?: OMReference<CnxVideoRoom> | null
    public purchases?: Array<OMReference<CnxConsumerPurchase>> | null
    public purchasesCount: number
    public meetingTemplate?: OMReference<CnxMeetingTemplate> | null
    public serviceTier: OMReference<CnxServiceTier>

    // Categorizable Protocol
    public categories?: Array<OMReference<CatCategory>>| null
    public categoryCount: number
    public categorySet: string[]
    public categorySetByType: Map<string, string>

    // Rateable Protocol
    public ratings?: Array<OMReference<SrrRating>> | null
    public ratingsCount: number
    public ratingsAverage: number
    public ratingsCountByStar: Map<string, string>

    constructor(object: CnxMeetingProperties) {
        super(object)

        // CnxMeeting Attributes
        this.summary = object.summary
        this.meetingStatus = object.meetingStatus
        this.serviceLeadType = object.serviceLeadType
        this.serviceTimingType = object.serviceTimingType
        this.scheduledStartAt = object.scheduledStartAt ? new Date(object.scheduledStartAt) : null
        this.scheduledEndAt = object.scheduledEndAt ? new Date(object.scheduledEndAt) : null
        this.actualStartAt = object.actualStartAt ? new Date(object.actualStartAt) : null
        this.actualEndAt = object.actualEndAt ? new Date(object.actualEndAt) : null
        this.expireAt = object.expireAt ? new Date(object.expireAt) : null
        this.minBookings = object.minBookings
        this.maxBookings = object.maxBookings
        this.minBids = object.minBids
        this.maxBids = object.maxBids

        // CnxMeeting Relationships
        this.attendees = object.attendees ? object.attendees.map((item) => new OMReference(CnxConsumerProfile, item)) : []
        this.attendeeCount = object.attendeeCount || 0
        this.acceptedBid = object.acceptedBid ? new OMReference(CnxBid, object.acceptedBid) : null
        this.bids = object.bids ? object.bids.map((item) => new OMReference(CnxBid, item)) : []
        this.bidsCount = object.bidsCount || 0
        this.bookings = object.bookings ? object.bookings.map((item) => new OMReference(CnxBooking, item)) : []
        this.bookingsCount = object.bookingsCount || 0
        this.consumers = object.consumers ? object.consumers.map((item) => new OMReference(CnxConsumerProfile, item)) : []
        this.consumerCount = object.consumerCount || 0
        this.coverPhoto = object.coverPhoto ? new OMReference(GenImage, object.coverPhoto) : null
        this.currentParticipants = object.currentParticipants ? object.currentParticipants.map((item) => new OMReference(CnxMemberProfile, item)) : []
        this.currentParticipantsCount = object.currentParticipantsCount || 0
        this.transcript = object.transcript ? object.transcript.map((item) => new OMReference(CnxMessage, item)) : []
        this.transcriptCount = object.transcriptCount || 0
        this.provider = object.provider ? new OMReference(CnxProviderProfile, object.provider) : null
        this.matchProviders = object.matchProviders ? object.matchProviders.map((item) => new OMReference(CnxProviderProfile, item)) : []
        this.matchProviderCount = object.matchProviderCount || 0
        this.matchProviderSet = object.matchProviderSet || []
        this.videoRoom = object.videoRoom ? new OMReference(CnxVideoRoom, object.videoRoom) : null
        this.purchases = object.purchases ? object.purchases.map((item) => new OMReference(CnxConsumerPurchase, item)) : []
        this.purchasesCount = object.purchasesCount || 0
        this.meetingTemplate = object.meetingTemplate ? new OMReference(CnxMeetingTemplate, object.meetingTemplate) : null
        this.serviceTier = new OMReference(CnxServiceTier, object.serviceTier)

        // Categorizable Protocol
        this.categories = object.categories ? object.categories.map((item) => new OMReference(CatCategory, item)) : []
        this.categoryCount = object.categoryCount || 0
        this.categorySet = object.categorySet || []
        this.categorySetByType = object.categorySetByType || new Map<string, string>()

        // Rateable Protocol
        this.ratings = object.ratings ? object.ratings.map((item) => new OMReference(SrrRating, item)) : []
        this.ratingsCount = object.ratingsCount || 0
        this.ratingsAverage = object.ratingsAverage || 0
        this.ratingsCountByStar = object.ratingsCountByStar || new Map<string, string>()
    }

    // Categorizable.setCategories()
    // @param categories?: string[]
    public setCategories(categories?: string[])
    {
        return (new ConcreteCategorizable(this)).setCategories(categories)
    }


    // CnxMeeting.addConsumer()
    // @param profile: string
    public addConsumer(profile: string)
    {
        try {
            const command = new CnxMeetingAddConsumerCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }


    // CnxMeeting.awake()
    public awake()
    {
        try {
            const command = new CnxMeetingAwakeCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }


    // CnxMeeting.expire()
    public expire()
    {
        try {
            const command = new CnxMeetingExpireCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }


    // CnxMeeting.getRoomToken()
    public getRoomToken()
    {
        try {
            const command = new CnxMeetingGetRoomTokenCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }


    // CnxMeeting.join()
    // @param profile: string
    public join(profile: string)
    {
        try {
            const command = new CnxMeetingJoinCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }


    // CnxMeeting.leave()
    // @param profile: string
    public leave(profile: string)
    {
        try {
            const command = new CnxMeetingLeaveCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }


    // CnxMeeting.notifyMatchProviders()
    // @param providers: string[]
    public notifyMatchProviders(providers?: string[])
    {
        try {
            const command = new CnxMeetingNotifyMatchProvidersCommand(this, providers)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }


    // CnxMeeting.offer()
    // @param bid: string
    public offer(bid: string)
    {
        try {
            const command = new CnxMeetingOfferCommand(this, bid)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }


    // CnxMeeting.removeConsumer()
    // @param profile: string
    public removeConsumer(profile: string)
    {
        try {
            const command = new CnxMeetingRemoveConsumerCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }


    // CnxMeeting.setMatchProviders()
    // @param providers: string[]
    public setMatchProviders(providers?: string[])
    {
        try {
            const command = new CnxMeetingSetMatchProvidersCommand(this, providers)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }


    // CnxMeeting.setServiceCategories()
    // @param categories: string[]
    public setServiceCategories(categories?: string[])
    {
        try {
            const command = new CnxMeetingSetServiceCategoriesCommand(this, categories)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

}
