//  CnxSessionWalletCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD500a374d3da7767445a9b2b88e0e5b828#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { CnxSessionWallet } from "."

/* CnxSessionWalletTopupCommand */
export class CnxSessionWalletTopupCommand extends Command<CnxSessionWallet>
{
    public static readonly Path = "topup"
    public static readonly Type = "cnx_session_wallet_topup_command"

    public payment: string
    public topupPackage?: string | null

    public constructor(
        receiver: CnxSessionWallet,
        payment: string,
        topupPackage?: string | null
    ) {
        super(receiver)

        this.payment = payment
        this.topupPackage = topupPackage
    }
}
