//  CnxTopupPayment.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD54551628b69656193c47ee47a2d8263b8#]

import {
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

import { PmtStripePayment, PmtStripePaymentProperties } from "../payments_stripe"

// Import related object types
import CnxConsumerTopup from "./CnxConsumerTopup"
import CnxTopupPackage from "./CnxTopupPackage"
import { MerchOrder } from "../merchantable"
import { DomainProfile } from "../kernel"


export interface CnxTopupPaymentProperties extends PmtStripePaymentProperties
{

    // CnxTopupPayment relationships
    transactions?: OMReference<CnxConsumerTopup>[] | null
    transactionCount?: number
    topupPackage?: OMReference<CnxTopupPackage> | null
}

/**
 * CnxTopupPayment
 */
export default class CnxTopupPayment extends PmtStripePayment
    implements CnxTopupPaymentProperties
{
    public static Type: string = "cnx_topup_payment"
    public static Path: string = "cnx_topup_payments"

    // Creates a placeholder CnxTopupPayment instance
    public static createPlaceholder() {
        return new CnxTopupPayment({
            amountInCents: 0,
            currency: "",
            status: "PENDING",
            customer: new OMReference(DomainProfile, DomainProfile.createPlaceholder())
        })
    }


    // CnxTopupPayment Relationships
    public transactions?: Array<OMReference<CnxConsumerTopup>>| null
    public transactionCount: number
    public topupPackage?: OMReference<CnxTopupPackage> | null

    constructor(object: CnxTopupPaymentProperties) {
        super(object)

        // CnxTopupPayment Relationships
        this.transactions = object.transactions ? object.transactions.map((item) => new OMReference(CnxConsumerTopup, item)) : []
        this.transactionCount = object.transactionCount || 0
        this.topupPackage = object.topupPackage ? new OMReference(CnxTopupPackage, object.topupPackage) : null
    }
}
