//  CnxConsumerTopup.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD50afa0f00c9ba79ab02f077c5b09f8713#]

import {
    OMReference,
    DomainObject
} from "@apptivity-lab/firmament-node-sdk"

import { WltTransaction, WltTransactionProperties } from "../wallet"

// Import related object types
import CnxTopupPayment from "./CnxTopupPayment"
import { WltWallet } from "../wallet"

// CnxConsumerTopup Custom Datatypes
type TransactionStatus = "PENDING" | "APPROVED" | "REJECTED"
const TransactionStatusOptions = [
    { title: "Pending", value: "PENDING" },
    { title: "Approved", value: "APPROVED" },
    { title: "Rejected", value: "REJECTED" },
]
type TransactionType = "DEBIT" | "CREDIT"
const TransactionTypeOptions = [
    { title: "Debit", value: "DEBIT" },
    { title: "Credit", value: "CREDIT" },
]

export interface CnxConsumerTopupProperties extends WltTransactionProperties
{
    // CnxConsumerTopup relationships
    payments?: OMReference<CnxTopupPayment>[] | null
    paymentCount?: number
}

/**
 * CnxConsumerTopup
 */
export default class CnxConsumerTopup extends WltTransaction
    implements CnxConsumerTopupProperties
{
    public static Type: string = "cnx_consumer_topup"
    public static Path: string = "cnx_consumer_topups"

    // Creates a placeholder CnxConsumerTopup instance
    public static createPlaceholder() {
        return new CnxConsumerTopup({
            amountInCents: 0,
            currency: "",
            transactionStatus: "PENDING",
            transactionType: "DEBIT",
            wallet: new OMReference(WltWallet, WltWallet.createPlaceholder())
        })
    }

    // CnxConsumerTopup Relationships
    public payments?: Array<OMReference<CnxTopupPayment>>| null
    public paymentCount: number

    constructor(object: CnxConsumerTopupProperties) {
        super(object)

        // CnxConsumerTopup Relationships
        this.payments = object.payments ? object.payments.map((item) => new OMReference(CnxTopupPayment, item)) : []
        this.paymentCount = object.paymentCount || 0
    }
}
