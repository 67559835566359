//  PushChannel.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD50c88307cc67b39197320853fbe1bd3dd#]

import {
    OMInstallation as Installation,
    OMObject,
    OMObjectProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import PushMessage from "./PushMessage"

// Import command types
import { PushChannelPublishMessageCommand } from "./PushChannelCommands"

export interface PushChannelProperties extends OMObjectProperties
{
    // PushChannel Attributes
    topicArn: string

    // PushChannel relationships
    messages?: OMReference<PushMessage>[] | null
    messagesCount?: number
    installations?: OMReference<Installation>[] | null
    installationsCount?: number
}

/**
 * PushChannel
 */
export default class PushChannel extends OMObject
    implements PushChannelProperties
{
    public static Type: string = "push_channel"
    public static Path: string = "push_channels"

    // Creates a placeholder PushChannel instance
    public static createPlaceholder() {
        return new PushChannel({
            topicArn: ""
        })
    }

    // PushChannel Attributes
    public topicArn: string

    // PushChannel Relationships
    public messages?: Array<OMReference<PushMessage>> | null
    public messagesCount: number
    public installations?: Array<OMReference<Installation>>| null
    public installationsCount: number

    constructor(object: PushChannelProperties) {
        super(object)

        // PushChannel Attributes
        this.topicArn = object.topicArn

        // PushChannel Relationships
        this.messages = object.messages ? object.messages.map((item) => new OMReference(PushMessage, item)) : []
        this.messagesCount = object.messagesCount || 0
        this.installations = object.installations ? object.installations.map((item) => new OMReference(Installation, item)) : []
        this.installationsCount = object.installationsCount || 0

    }

    // PushChannel.publishMessage()
    // @param message: string,
    // @param messageId: string
    public publishMessage(message: string, messageId?: string)
    {
        try {
            const command = new PushChannelPublishMessageCommand(this, message, messageId)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
