//  AffStripePayment.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5838c25fe82825247d1d8c34fc7778239#]

import {
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

import { PmtStripePayment, PmtStripePaymentProperties } from "../payments_stripe"

// Import related object types
import { DomainProfile } from "../kernel"

export interface AffStripePaymentProperties extends PmtStripePaymentProperties
{

}

/**
 * AffStripePayment
 */
export default class AffStripePayment extends PmtStripePayment
    implements AffStripePaymentProperties
{
    public static Type: string = "aff_stripe_payment"
    public static Path: string = "aff_stripe_payments"

    // Creates a placeholder AffStripePayment instance
    public static createPlaceholder() {
        return new AffStripePayment({
            amountInCents: 0,
            currency: "",
            status: "PENDING",
            customer: new OMReference(DomainProfile, DomainProfile.createPlaceholder())
        })
    }

    constructor(object: AffStripePaymentProperties) {
        super(object)
    }
}
