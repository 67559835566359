//  CnxProviderEarning.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD563521bcdc1de77e72549317945c0dfc1#]

import {
    OMReference,
    DomainObject
} from "@apptivity-lab/firmament-node-sdk"

import { WltTransaction, WltTransactionProperties } from "../wallet"

// Import related object types
import { WltWallet } from "../wallet"

// CnxProviderEarning Custom Datatypes
type TransactionStatus = "PENDING" | "APPROVED" | "REJECTED"
const TransactionStatusOptions = [
    { title: "Pending", value: "PENDING" },
    { title: "Approved", value: "APPROVED" },
    { title: "Rejected", value: "REJECTED" },
]
type TransactionType = "DEBIT" | "CREDIT"
const TransactionTypeOptions = [
    { title: "Debit", value: "DEBIT" },
    { title: "Credit", value: "CREDIT" },
]

export interface CnxProviderEarningProperties extends WltTransactionProperties
{

}

/**
 * CnxProviderEarning
 */
export default class CnxProviderEarning extends WltTransaction
    implements CnxProviderEarningProperties
{
    public static Type: string = "cnx_provider_earning"
    public static Path: string = "cnx_provider_earnings"

    // Creates a placeholder CnxProviderEarning instance
    public static createPlaceholder() {
        return new CnxProviderEarning({
            amountInCents: 0,
            currency: "",
            transactionStatus: "PENDING",
            transactionType: "DEBIT",
            wallet: new OMReference(WltWallet, WltWallet.createPlaceholder())
        })
    }

    constructor(object: CnxProviderEarningProperties) {
        super(object)
    }
}
