//  MerchOrder.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5783c26a68f6c8ded502f3fc98a597b7c#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import { MerchLineItem, ConcreteMerchBusiness } from "."
import { PmtPayment } from "../payments"
import { DomainProfile } from "../kernel"

// MerchOrder Custom Datatypes
export type OrderStatus = "CART" | "CHECKED_OUT" | "PAID" | "COMPLETED" | "CANCELLED" | "FAILED"
export const OrderStatusOptions = [
    { title: "Cart", value: "CART" },
    { title: "Checked_Out", value: "CHECKED_OUT" },
    { title: "Paid", value: "PAID" },
    { title: "Completed", value: "COMPLETED" },
    { title: "Cancelled", value: "CANCELLED" },
    { title: "Failed", value: "FAILED" },
]

export interface MerchOrderProperties extends DomainObjectProperties
{
    // MerchOrder Attributes
    checkoutAt?: Date | null
    currency: string
    paymentDueAt?: Date | null
    remarks?: string | null
    status: OrderStatus
    totalPriceCents?: number | null

    // MerchOrder relationships
    lineItems?: OMReference<MerchLineItem>[] | null
    lineItemsCount?: number
    lineItemsTotalActualPriceCents?: number | null
    lineItemsTotalItemCount?: number | null
    payments?: OMReference<PmtPayment>[] | null
    paymentsCount?: number
    business?: OMReference<ConcreteMerchBusiness> | null
    customer?: OMReference<DomainProfile> | null
}

/**
 * MerchOrder
 */
export default class MerchOrder extends DomainObject
    implements MerchOrderProperties
{
    public static Type: string = "merch_order"
    public static Path: string = "merch_orders"

    // Creates a placeholder MerchOrder instance
    public static createPlaceholder() {
        return new MerchOrder({
            currency: "MYR",
            status: "CART"
        })
    }

    // MerchOrder Attributes
    public checkoutAt?: Date | null
    public currency: string
    public paymentDueAt?: Date | null
    public remarks?: string | null
    public status: OrderStatus
    public totalPriceCents?: number | null

    // MerchOrder Relationships
    public lineItems?: Array<OMReference<MerchLineItem>> | null
    public lineItemsCount: number
    public lineItemsTotalActualPriceCents: number
    public lineItemsTotalItemCount: number
    public payments?: Array<OMReference<PmtPayment>> | null
    public paymentsCount: number
    public business?: OMReference<ConcreteMerchBusiness> | null
    public customer?: OMReference<DomainProfile> | null

    constructor(object: MerchOrderProperties) {
        super(object)

        // MerchOrder Attributes
        this.checkoutAt = object.checkoutAt ? new Date(object.checkoutAt) : null
        this.currency = object.currency
        this.paymentDueAt = object.paymentDueAt ? new Date(object.paymentDueAt) : null
        this.remarks = object.remarks
        this.status = object.status
        this.totalPriceCents = object.totalPriceCents

        // MerchOrder Relationships
        this.lineItems = object.lineItems ? object.lineItems.map((item) => new OMReference(MerchLineItem, item)) : []
        this.lineItemsCount = object.lineItemsCount || 0
        this.lineItemsTotalActualPriceCents = object.lineItemsTotalActualPriceCents || 0
        this.lineItemsTotalItemCount = object.lineItemsTotalItemCount || 0
        this.payments = object.payments ? object.payments.map((item) => new OMReference(PmtPayment, item)) : []
        this.paymentsCount = object.paymentsCount || 0
        this.business = object.business ? new OMReference(ConcreteMerchBusiness, object.business) : null
        this.customer = object.customer ? new OMReference(DomainProfile, object.customer) : null
    }
}
