
//  AffAttendeeProfileCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD52f2af2707d37cda936cc897f5007df24#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { AffAttendeeProfile } from "."

/* AffAttendeeProfileGetUniqueTimezonesCommand */
export class AffAttendeeProfileGetUniqueTimezonesCommand extends Command<AffAttendeeProfile>
{
    public static readonly Path = "get_unique_timezones"
    public static readonly Type = "aff_attendee_profile_get_unique_timezones_command"
}

/* AffAttendeeProfileSendWelcomeEmailCommand */
export class AffAttendeeProfileSendWelcomeEmailCommand extends Command<AffAttendeeProfile>
{
    public static readonly Path = "send_welcome_email"
    public static readonly Type = "aff_attendee_profile_send_welcome_email_command"
}
