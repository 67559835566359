 import { getTimeZoneNamed } from "./timezones"

 export const startOfDay = (referenceDate: Date = new Date(), timezone?: string) => {
    let tzOffsetMillis = 0
    const tz = getTimeZoneNamed(timezone || new Intl.DateTimeFormat("en").resolvedOptions().timeZone)
    if (tz) {
        tzOffsetMillis = (tz.offsetMinutes) * 60 * 1000
    }

    const date = new Date(referenceDate.getTime() + tzOffsetMillis)

    const diffSecondsToStartOfDay = (date.getUTCHours() * 3600) + (date.getUTCMinutes() * 60) + (date.getUTCSeconds())
    date.setTime(date.getTime() - (diffSecondsToStartOfDay * 1000) - tzOffsetMillis)

    return date
}

export const firstMondayOfWeek = (referenceDate: Date = new Date()): Date => {
    const date = new Date(referenceDate.getFullYear(), referenceDate.getMonth(), referenceDate.getDate())
    if (referenceDate.getDay() === 0) {
        date.setHours(-24)
        return firstMondayOfWeek(date)
    } else {
        date.setHours(((referenceDate.getDay() - 1) % 7) * -24)
    }
    return date
}

export const startOfWeek = (referenceDate: Date = new Date(), timezone?: string) => {
    const utcMonthString = `${referenceDate.getUTCMonth() + 1}`.padStart(2, "0")
    const utcDateString = `${referenceDate.getUTCDate()}`.padStart(2, "0")

    const date = new Date(`${referenceDate.getUTCFullYear()}-${utcMonthString}-${utcDateString}T00:00:00.000Z`)

    if (referenceDate.getDay() !== 0) {
        date.setUTCHours(((referenceDate.getUTCDay() - 1) % 7) * -24)
    }

    const tz = getTimeZoneNamed(timezone || new Intl.DateTimeFormat("en").resolvedOptions().timeZone)
    if (tz) {
        date.setUTCMinutes(date.getUTCMinutes() - tz.offsetMinutes)
    }

    return date
}

export const endOfWeek = (referenceDate: Date = new Date(), timezone?: string) => {
    const date = startOfWeek(referenceDate, timezone)
    date.setTime(date.getTime() + (7 * 24 * 3600 * 1000) - 1)

    return date
}

export const startOfMonth = (referenceDate: Date = new Date(), monthOffset: number = 0, timezone?: string) => {
    const utcMonthString = `${((referenceDate.getUTCMonth() + monthOffset) % 12) + 1}`.padStart(2, "0")
    const date = new Date(`${referenceDate.getUTCFullYear()}-${utcMonthString}-01T00:00:00.000Z`)

    const tz = getTimeZoneNamed(timezone || new Intl.DateTimeFormat("en").resolvedOptions().timeZone)
    if (tz) {
        date.setUTCMinutes(date.getUTCMinutes() - tz.offsetMinutes)
    }

    return date
}

/**
 * Pass in a non-zero monthOffset to get the end of the month based on
 * reference date, offset with number of months.
 *
 * @param referenceDate
 * @param monthOffset Number of months to offset
 * @returns Last day of the month based on referenceDate, offset with monthOffset value
 */
export const endOfMonth = (referenceDate: Date = new Date(), monthOffset: number = 0, timezone?: string) => {
    const date = new Date(referenceDate)

    // First offset the month with monthOffset + 1  so that
    // we get date offset by an additional month in advance
    date.setUTCMonth((date.getUTCMonth() + (monthOffset + 1) % 12))
    // Then, set date to 0, which gives us last date of date.getMonth() - 1
    date.setUTCDate(0)

    // Set time components to end of day
    date.setUTCHours(23, 59, 59, 999)

    const tz = getTimeZoneNamed(timezone || new Intl.DateTimeFormat("en").resolvedOptions().timeZone)
    if (tz) {
        date.setUTCMinutes(date.getUTCMinutes() - tz.offsetMinutes)
    }

    return date
}

/**
 * Returns number of days in a month given the year and a month. Please note
 * that the month parameter is zero-based, i.e. pass in 0 for January, not 1).
 *
 * @param year
 * @param month Zero-based value for month (0 = January, 1 = February, etc.)
 * @returns Number of days in a month
 */
export const daysInMonth = (year: number, month: number) => {
    // Passing 0 for date parameter returns the last date of the
    // previous month (month - 1). In our case, if we want the number days in
    // month, then we have to add 1 to month parameter.
    return new Date(year, month + 1, 0).getDate()
}
