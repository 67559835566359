//  RenewalOrderCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5ec63c8cfab4f26ab56a9594aa37ff000#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { RenewalOrder } from "."

/* RenewalOrderFulfillCommand */
export class RenewalOrderFulfillCommand extends Command<RenewalOrder>
{
    public static readonly Path = "fulfill"
    public static readonly Type = "renewal_order_fulfill_command"
}
