//  CnxMoneyWallet.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD527d18b0b27bff7d7321e9b84cb1ebb0b#]

import {
    OMReference,
    DomainObject
} from "@apptivity-lab/firmament-node-sdk"

import { WltWallet, WltWalletProperties } from "../wallet"

// Import related object types
import CnxMemberProfile from "./CnxMemberProfile"

export interface CnxMoneyWalletProperties extends WltWalletProperties
{
    // CnxMoneyWallet relationships
    moneyOwner: OMReference<CnxMemberProfile>
}

/**
 * CnxMoneyWallet
 */
export default class CnxMoneyWallet extends WltWallet
    implements CnxMoneyWalletProperties
{
    public static Type: string = "cnx_money_wallet"
    public static Path: string = "cnx_money_wallets"

    // Creates a placeholder CnxMoneyWallet instance
    public static createPlaceholder() {
        return new CnxMoneyWallet({
            currency: "",
            moneyOwner: new OMReference(CnxMemberProfile, CnxMemberProfile.createPlaceholder())
        })
    }

    // CnxMoneyWallet Relationships
    public moneyOwner: OMReference<CnxMemberProfile>

    constructor(object: CnxMoneyWalletProperties) {
        super(object)

        // CnxMoneyWallet Relationships
        this.moneyOwner = new OMReference(CnxMemberProfile, object.moneyOwner)
    }
}
