import React, { useMemo } from "react"
import { CatCategory, Categorizable } from ".."

const CategorizableCategoryTagsView: React.FunctionComponent<{
    className?: string
    subject: Categorizable,
    restrictToCategoryTypes?: string[]
}> = ({
    className,
    subject,
    restrictToCategoryTypes
}) => {
    const categories = useMemo(() => {
        return (subject.categories || []).reduce<CatCategory[]>((result, category) => {
            const actual = category.actualObject
            if (actual &&
                (!restrictToCategoryTypes || restrictToCategoryTypes.length === 0 || restrictToCategoryTypes.includes(actual.categoryType!))) {
                result.push(actual)
            }
            return result
        }, [])
    }, [subject.categories, restrictToCategoryTypes])

    return (
        <div className={["flex flex-wrap gap-[12px]"].concat(className ? [className] : []).join(" ")}>
            {categories.map((category) => {
                const categoryTitle = category.title
                if (!categoryTitle) {
                    return null
                } else {
                    return (
                        <div key={category.id} className="px-[12px] py-[6px] bg-black-100 rounded-full text-footnote text-black-700 truncate">{categoryTitle}</div>
                    )
                }
            })}
        </div>
    )
}

export default CategorizableCategoryTagsView
