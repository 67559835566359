//  NotifMessage.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5650b87a2ba436b726a374bb7bec028c8#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import NotifBroadcast from "./NotifBroadcast"
import { DomainProfile } from "../kernel"

// Import command types
import { NotifMessageMarkAllReadCommand } from "./NotifMessageCommands"

export interface NotifMessageProperties extends DomainObjectProperties
{
    // NotifMessage Attributes
    archivedAt?: Date | null
    body?: string | null
    bodyHtml?: string | null
    lastReadAt?: Date | null

    // NotifMessage relationships
    broadcast: OMReference<NotifBroadcast>
    profile?: OMReference<DomainProfile> | null
}

/**
 * NotifMessage
 */
export default class NotifMessage extends DomainObject
    implements NotifMessageProperties
{
    public static Type: string = "notif_message"
    public static Path: string = "notif_messages"

    // Creates a placeholder NotifMessage instance
    public static createPlaceholder() {
        return new NotifMessage({
            broadcast: new OMReference(NotifBroadcast, NotifBroadcast.createPlaceholder())
        })
    }

    // NotifMessage Attributes
    public archivedAt?: Date | null
    public body?: string | null
    public bodyHtml?: string | null
    public lastReadAt?: Date | null

    // NotifMessage Relationships
    public broadcast: OMReference<NotifBroadcast>
    public profile?: OMReference<DomainProfile> | null

    constructor(object: NotifMessageProperties) {
        super(object)

        // NotifMessage Attributes
        this.archivedAt = object.archivedAt ? new Date(object.archivedAt) : null
        this.body = object.body
        this.bodyHtml = object.bodyHtml
        this.lastReadAt = object.lastReadAt ? new Date(object.lastReadAt) : null

        // NotifMessage Relationships
        this.broadcast = new OMReference(NotifBroadcast, object.broadcast)
        this.profile = object.profile ? new OMReference(DomainProfile, object.profile) : null
    }

    // NotifMessage.markAllRead()
    // @param profile: string
    public markAllRead(profile: string)
    {
        try {
            const command = new NotifMessageMarkAllReadCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
