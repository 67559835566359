export const GA_MEASUREMENT_ID = "UA-166902643-1"

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = (url) => {
    if (process.env.UNIVERSE_ENVIRONMENT !== "production") {
        return
    }

    window.gtag("config", GA_MEASUREMENT_ID, {
        page_path: url
    })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
    if (process.env.UNIVERSE_ENVIRONMENT !== "production") {
        return
    }

    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    })
}
