
//  AffVideo.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD59560cce0b12e7c3cef3fea9d8ff11bd3#]

import {
    OMFile,
    OMFileProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import AffVideoViewership from "./AffVideoViewership"

// Import command types
import { AffVideoRecordViewershipCommand } from "./AffVideoCommands"


export interface AffVideoProperties extends OMFileProperties
{
    // AffVideo Attributes
    hlsUrl?: string | null

    // AffVideo relationships
    viewerships?: OMReference<AffVideoViewership>[] | null
    viewershipsCount?: number
}

/**
 * AffVideo
 */
export default class AffVideo extends OMFile
    implements AffVideoProperties
{
    public static Type: string = "aff_video"
    public static Path: string = "aff_videos"

    // Creates a placeholder AffVideo instance
    public static createPlaceholder() {
        return new AffVideo({ })
    }

    // AffVideo Attributes
    public hlsUrl?: string | null

    // AffVideo Relationships
    public viewerships?: Array<OMReference<AffVideoViewership>> | null
    public viewershipsCount: number

    constructor(object: AffVideoProperties) {
        super(object)

        // AffVideo Attributes
        this.hlsUrl = object.hlsUrl ? object.hlsUrl : null

        // AffVideo Relationships
        this.viewerships = object.viewerships ? object.viewerships.map((item) => new OMReference(AffVideoViewership, item)) : []
        this.viewershipsCount = object.viewershipsCount || 0

    }

    // AffVideo.recordViewership()
    // @param consumer: string,
    // @param progress: number
    public recordViewership(consumer: string, progress: number)
    {
        try {
            const command = new AffVideoRecordViewershipCommand(this, consumer, progress)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

}
