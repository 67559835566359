import { Dispatch, SetStateAction, useState } from "react"
import { usePreviousValue } from "./usePreviousValue"

/**
 * This hook enqueues update of the returned stateful variable if predicate
 * evaluates to true or if predicate is not supplied, only when the value
 * has changed.
 *
 * @param value
 * @param predicate Optional additional predicate to determine if state needs to be updated
 * @returns stateful variable and a function to update it (from useState() hook)
 */
export const useUpdateableState = <T>(
    value?: T,
    predicate?: (current?: T, next?: T) => boolean
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] => {
    const [state, setState] = useState(value)
    const previous = usePreviousValue(value)

    if ((value !== previous && value !== state) &&
        (predicate === undefined || (predicate && predicate(state, value)))) {
        // If state needs to be updated, enqueue a state update, which will
        // cause a component re-render.
        setState(value)
    }

    return [state, setState]
}
