import React, { useRef, useMemo, ChangeEvent } from "react"
import Image from "next/image"

import { OMReference } from "@apptivity-lab/firmament-node-sdk"

import { useFileChange, useUpdateableState } from "@lib"
import { GenImage } from "@firmament-packages/files"

type Props = {
    value?: GenImage,
    onChange?: (file?: GenImage) => void,
    className?: string
}

const ProfilePhotoPicker: React.FunctionComponent<Props> = ({ value, onChange, className }) => {
    const [file, setFile] = useFileChange(value, onChange)

    const fileUrl = useMemo(() => {
        if (file?.url) {
            return file.url
        } else if (file?.localFile) {
            return URL.createObjectURL(file.localFile)
        }
        return undefined
    }, [file])

    const fileInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFile: File = event.target.files[0]
            setFile(
                new GenImage({
                    localFile: selectedFile,
                    mimetype: selectedFile.type,
                    title: selectedFile.name.replaceAll(" ", "-")
                })
            )
        }
    }

    // Ref to reference the actual file input element which is hidden so that
    // when users click on the container div, we can forward the click to the
    // hidden input to trigger browser's file selector.
    const fileInputRef = useRef<HTMLInputElement>(null)

    return (
        <div id="profilePhotoPicker" className={["inline-block cursor-pointer", className || ""].join(" ")}>
            <div className="flex flex-col" onClick={() => fileInputRef.current?.click()}>
                <div className="relative w-[80px] h-[80px] mx-auto">
                    <div>
                        <Image className="w-full h-full bg-black-200 rounded-full"
                            width="80" height="80" alt="Profile Photo"
                            objectFit="cover"
                            src={fileUrl || "/images/ic_avatar.svg"}
                            />
                    </div>
                    <div className="absolute right-0 bottom-0 w-[32px] h-[32px] bg-accent-900 rounded-full">
                        <Image width="32" height="32" src="/images/ic_change_photo.svg" alt="Change Photo Icon" />
                    </div>
                </div>
                <input ref={fileInputRef} className="hidden" accept="image/*" type="file" onChange={fileInputChangeHandler} />
                <span className="mt-[16px] mx-auto text-callout text-accent-900 underline">Change Photo</span>
            </div>
        </div>
    )
}

export default ProfilePhotoPicker
