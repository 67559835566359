//  DomainProfile.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5b662f8269285feaa2e4b9be6a72a3530#]

import {
    OMProfile,
    OMProfileProperties,
    OMReference,
    User,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import { ConcreteFlaggable } from "../flaggable"
import { MerchOrder } from "../merchantable"
import { NotifMessage } from "../broadcast"
import { PmtPayment } from "../payments"
import { Article } from "../articles"
import { SrrRating } from "../ratings"
import { Subscription } from "../paid_memberships"

// Import command types
import { PmtProfileCreatePaymentIntentCommand, PmtProfileGenerateEphemeralKeyCommand, PmtProfileRegisterStripeCustomerCommand, PmtProfileUpdateStripeCustomerCommand } from "./DomainProfileCommands"

export interface DomainProfileProperties extends OMProfileProperties
{
    // DomainProfile Attributes
    stripeConnectAccount?: string | null
    stripeConnectDetails?: string | null
    stripeCustomerId?: string | null

    // DomainProfile relationships
    flagTargets?: OMReference<ConcreteFlaggable>[] | null
    flagTargetCount?: number
    orders?: OMReference<MerchOrder>[] | null
    ordersCount?: number
    notifications?: OMReference<NotifMessage>[] | null
    notificationsCount?: number
    payments?: OMReference<PmtPayment>[] | null
    paymentsCount?: number
    article?: OMReference<Article>[] | null
    articleCount?: number
    submittedRatings?: OMReference<SrrRating>[] | null
    submittedRatingsCount?: number
    subscriptions?: OMReference<Subscription>[] | null
    subscriptionsCount?: number
}

/**
 * DomainProfile
 */
export default class DomainProfile extends OMProfile
    implements DomainProfileProperties
{
    public static Type: string = "domain_profile"
    public static Path: string = "domain_profiles"

    // Creates a placeholder DomainProfile instance
    public static createPlaceholder() {
        return new DomainProfile({
            user: new OMReference(User, User.createPlaceholder())
        })
    }

    // DomainProfile Attributes
    public stripeConnectAccount?: string | null
    public stripeConnectDetails?: string | null
    public stripeCustomerId?: string | null

    // DomainProfile Relationships
    public flagTargets?: Array<OMReference<ConcreteFlaggable>>| null
    public flagTargetCount: number
    public orders?: Array<OMReference<MerchOrder>> | null
    public ordersCount: number
    public notifications?: Array<OMReference<NotifMessage>> | null
    public notificationsCount: number
    public payments?: Array<OMReference<PmtPayment>> | null
    public paymentsCount: number
    public article?: Array<OMReference<Article>> | null
    public articleCount: number
    public submittedRatings?: Array<OMReference<SrrRating>> | null
    public submittedRatingsCount: number
    public subscriptions?: Array<OMReference<Subscription>> | null
    public subscriptionsCount: number

    constructor(object: DomainProfileProperties) {
        super(object)

        // DomainProfile Attributes
        this.stripeConnectAccount = object.stripeConnectAccount
        this.stripeConnectDetails = object.stripeConnectDetails
        this.stripeCustomerId = object.stripeCustomerId

        // DomainProfile Relationships
        this.flagTargets = object.flagTargets ? object.flagTargets.map((item) => new OMReference(ConcreteFlaggable, item)) : []
        this.flagTargetCount = object.flagTargetCount || 0
        this.orders = object.orders ? object.orders.map((item) => new OMReference(MerchOrder, item)) : []
        this.ordersCount = object.ordersCount || 0
        this.notifications = object.notifications ? object.notifications.map((item) => new OMReference(NotifMessage, item)) : []
        this.notificationsCount = object.notificationsCount || 0
        this.payments = object.payments ? object.payments.map((item) => new OMReference(PmtPayment, item)) : []
        this.paymentsCount = object.paymentsCount || 0
        this.article = object.article ? object.article.map((item) => new OMReference(Article, item)) : []
        this.articleCount = object.articleCount || 0
        this.submittedRatings = object.submittedRatings ? object.submittedRatings.map((item) => new OMReference(SrrRating, item)) : []
        this.submittedRatingsCount = object.submittedRatingsCount || 0
        this.subscriptions = object.subscriptions ? object.subscriptions.map((item) => new OMReference(Subscription, item)) : []
        this.subscriptionsCount = object.subscriptionsCount || 0
    }

    // DomainProfile.createPaymentIntent()
    // @param amountInCents: number,
    // @param currency: string,
    // @param captureAutomatically: boolean,
    // @param paymentIntentId: string,
    // @param receiptEmail: string,
    // @param description: string
    public createPaymentIntent(amountInCents: number, currency: string, captureAutomatically: boolean, paymentIntentId: string, receiptEmail?: string, description?: string)
    {
        try {
            const command = new PmtProfileCreatePaymentIntentCommand(this, amountInCents, currency, captureAutomatically, paymentIntentId, receiptEmail, description)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // DomainProfile.generateEphemeralKey()
    // @param apiVersion: string,
    // @param ephemeralKey: any
    public generateEphemeralKey(apiVersion: string, ephemeralKey?: any)
    {
        try {
            const command = new PmtProfileGenerateEphemeralKeyCommand(this, apiVersion, ephemeralKey)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // DomainProfile.registerStripeCustomer()
    // @param name: string,
    // @param phone: string,
    // @param email: string,
    // @param stripeCustomerId: string
    public registerStripeCustomer(name?: string, phone?: string, email?: string, stripeCustomerId?: string)
    {
        try {
            const command = new PmtProfileRegisterStripeCustomerCommand(this, name, phone, email, stripeCustomerId)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // DomainProfile.updateStripeCustomer()
    // @param name: string,
    // @param phone: string,
    // @param email: string
    public updateStripeCustomer(name?: string, phone?: string, email?: string)
    {
        try {
            const command = new PmtProfileUpdateStripeCustomerCommand(this, name, phone, email)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
