//  ArticleParagraph.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5f1e214ee5ca8616d3511ca06da8b9bb2#]

import {
    OMReference,
    DomainObject
} from "@apptivity-lab/firmament-node-sdk"

import { default as ArticleContent, ArticleContentProperties } from "./ArticleContent"

// Import related object types
import Article from "./Article"

export interface ArticleParagraphProperties extends ArticleContentProperties
{

}

/**
 * ArticleParagraph
 */
export default class ArticleParagraph extends ArticleContent
    implements ArticleParagraphProperties
{
    public static Type: string = "article_paragraph"
    public static Path: string = "article_paragraphs"

    // Creates a placeholder ArticleParagraph instance
    public static createPlaceholder() {
        return new ArticleParagraph({
            article: new OMReference(Article, Article.createPlaceholder())
        })
    }

    constructor(object: ArticleParagraphProperties) {
        super(object)
    }
}
