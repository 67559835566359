//  Rateable.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5a7610bb1ce77c6f73853c0c2a12335f2#]

import {
    OMObject,
    OMObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import SrrRating from "./SrrRating"

export interface Rateable extends OMObjectProperties
{
    // Rateable relationships
    ratings?: OMReference<SrrRating>[] | null
    ratingsCount?: number
    ratingsAverage?: number | null
    ratingsCountByStar?: Map<string, string> | null
}

/**
 * ConcreteRateable
 */
export default class ConcreteRateable extends OMObject
    implements Rateable
{
    public static Type: string = "rateable"
    public static Path: string = "rateables"

    // Creates a placeholder ConcreteRateable instance
    public static createPlaceholder() {
        return new ConcreteRateable({ })
    }

    // Rateable Relationships
    public ratings?: Array<OMReference<SrrRating>> | null
    public ratingsCount: number
    public ratingsAverage: number
    public ratingsCountByStar: Map<string, string>

    constructor(object: Rateable) {
        super(object)

        // Rateable Relationships
        this.ratings = object.ratings ? object.ratings.map((item) => new OMReference(SrrRating, item)) : []
        this.ratingsCount = object.ratingsCount || 0
        this.ratingsAverage = object.ratingsAverage || 0
        this.ratingsCountByStar = object.ratingsCountByStar || new Map<string, string>()
    }
}
