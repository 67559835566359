import { Dispatch, SetStateAction, useEffect } from "react"

import { OMFile } from "@apptivity-lab/firmament-node-sdk"

import { useUpdateableState } from ".."

/**
 * This hook keeps component file state, and triggers onChange
 * callback only if the OMFile instance or its file content has really changed.
 *
 * @param value Current file value from component props
 * @param onChange Change handler that is typically pass in the component as props
 */
export const useFileChange = <T extends OMFile>(
    value?: T,
    onChange?: (file?: T) => void
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] => {
    const [state, setState] = useUpdateableState<T>(
        value,
        (current, next) => {
            return current?.id !== next?.id
        }
    )

    useEffect(() => {
        if (value && state) {
            if (!value.localFile && !state.localFile && value.id === state.id) {
                return
            } else if (value.localFile === state.localFile) {
                return
            }
        }

        // Only trigger onChange if the ID is different between input value
        // via props and state. OMFile instances are always re-created (i.e. it
        // is never mutated on the same object).
        if (state?.id !== value?.id) {
            onChange && onChange(state || undefined)
        }
    }, [value, state, onChange])

    return [state, setState]
}
