//  DcvDiscoveryLink.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Protocols adopted
import { Appearance, ConcreteAppearance } from "../appearance"
import { Discoverable, ConcreteDiscoverable } from "../discovery"

// Import related object types
import { GenImage } from "../files"
import DcvListSection from "./DcvListSection"


export interface DcvDiscoveryLinkProperties extends DomainObjectProperties,
    Appearance, Discoverable
{
    // DcvDiscoveryLink Attributes
    linkType: string
    linkUrl?: string | null

}

/**
 * DcvDiscoveryLink
 */
export default class DcvDiscoveryLink extends DomainObject
    implements DcvDiscoveryLinkProperties
{
    public static Type: string = "dcv_discovery_link"
    public static Path: string = "dcv_discovery_links"

    // Creates a placeholder DcvDiscoveryLink instance
    public static createPlaceholder() {
        return new DcvDiscoveryLink({
            linkType: ""
        })
    }

    // DcvDiscoveryLink Attributes
    public linkType: string
    public linkUrl?: string | null


    // Appearance Protocol
    public backgroundColor?: string | null
    public foregroundColor?: string | null
    public backgroundPhoto?: OMReference<GenImage> | null
    public icon?: OMReference<GenImage> | null

    // Discoverable Protocol
    public listSections?: Array<OMReference<DcvListSection>>| null
    public listSectionCount: number

    constructor(object: DcvDiscoveryLinkProperties) {
        super(object)

        // DcvDiscoveryLink Attributes
        this.linkType = object.linkType
        this.linkUrl = object.linkUrl

        // Appearance Protocol
        this.backgroundColor = object.backgroundColor
        this.foregroundColor = object.foregroundColor
        this.backgroundPhoto = object.backgroundPhoto ? new OMReference(GenImage, object.backgroundPhoto) : null
        this.icon = object.icon ? new OMReference(GenImage, object.icon) : null

        // Discoverable Protocol
        this.listSections = object.listSections ? object.listSections.map((item) => new OMReference(DcvListSection, item)) : []
        this.listSectionCount = object.listSectionCount || 0
    }

}
