import React, { ReactNode } from "react"

const LoadingIndicator: React.FunctionComponent<{
    message?: ReactNode
}> = ({
    message = "Loading"
}) => {
    return (
        <div id="loadingIndicator" className="fixed top-0 left-0 z-50">
            <div className="relative w-screen h-screen">
                <div id="loadingIndicator.backdrop" className="fixed top-0 left-0 w-screen h-screen z-40" style={{ background: "#ffffffa0" }} />
                <div className="absolute z-50 left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]">
                    <div className="flex p-[20px] bg-white-900 rounded shadow-xl">
                        <div className="mx-auto my-auto text-center">
                            <i className="text-gray-400 fas fa-spinner animate-spin" /><br />
                            <div className="mt-[8px] text-caption1 text-gray-800">
                                {message}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoadingIndicator
