//  AffMembershipPackage.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD53d0c665e0f1e277b470a7f00db17eec2#]

import {
    OMReference,
    CommandManager,
    DomainObject
} from "@apptivity-lab/firmament-node-sdk"

import { SubscriptionPackage, SubscriptionPackageProperties, SubscriptionProcessor } from "../paid_memberships"
// Protocols adopted
import { Publishable, ConcretePublishable } from "../publishable"
import { Appearance, ConcreteAppearance } from "../appearance"

// Import related object types
import { GenImage } from "../files"

// Import command types
import { AffMembershipPackageStartTrialCommand, AffMembershipPackageSubscribeCommand } from "./AffMembershipPackageCommands"

// AffMembershipPackage Custom Datatypes
type BillingCycle = "MONTHLY" | "YEARLY"
const BillingCycleOptions = [
    { title: "Monthly", value: "MONTHLY" },
    { title: "Yearly", value: "YEARLY" },
]

export interface AffMembershipPackageProperties extends SubscriptionPackageProperties,
    Publishable, Appearance
{
    // AffMembershipPackage Attributes
    sessionsTopupCredits: number
    promoBadgeText?: string | null
    stripePriceId: string
    stripeProductId: string

}

/**
 * AffMembershipPackage
 */
export default class AffMembershipPackage extends SubscriptionPackage
    implements AffMembershipPackageProperties
{
    public static Type: string = "aff_membership_package"
    public static Path: string = "aff_membership_packages"

    // Creates a placeholder AffMembershipPackage instance
    public static createPlaceholder() {
        return new AffMembershipPackage({
            sessionsTopupCredits: 0,
            currency: "MYR",
            actualPriceCents: 0,
            listPriceCents: 0,
            isAvailable: true,
            stripePriceId: "",
            stripeProductId: ""
        })
    }

    // AffMembershipPackage Attributes
    public sessionsTopupCredits: number
    public promoBadgeText?: string | null
    public stripePriceId: string
    public stripeProductId: string

    // Publishable Protocol
    public publishAt?: Date | null
    public unpublishAt?: Date | null

    // Appearance Protocol
    public foregroundColor?: string | null
    public backgroundColor?: string | null
    public backgroundPhoto?: OMReference<GenImage> | null
    public icon?: OMReference<GenImage> | null

    constructor(object: AffMembershipPackageProperties) {
        super(object)

        // AffMembershipPackage Attributes
        this.sessionsTopupCredits = object.sessionsTopupCredits
        this.promoBadgeText = object.promoBadgeText
        this.stripePriceId = object.stripePriceId
        this.stripeProductId = object.stripeProductId

        // Publishable Protocol
        this.publishAt = object.publishAt ? new Date(object.publishAt) : null
        this.unpublishAt = object.unpublishAt ? new Date(object.unpublishAt) : null

        // Appearance Protocol
        this.foregroundColor = object.foregroundColor
        this.backgroundColor = object.backgroundColor
        this.backgroundPhoto = object.backgroundPhoto ? new OMReference(GenImage, object.backgroundPhoto) : null
        this.icon = object.icon ? new OMReference(GenImage, object.icon) : null
    }

    // AffMembershipPackage.startTrial()
    // @param ownerProfile: string,
    // @param startAt: Date
    public startTrial(ownerProfile: string, startAt: Date)
    {
        try {
            const command = new AffMembershipPackageStartTrialCommand(this, ownerProfile, startAt)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // AffMembershipPackage.subscribe()
    // @param ownerProfile: string,
    // @param startAt: Date,
    // @param subscriptionProcessor: SubscriptionProcessor
    public subscribe(ownerProfile: string, startAt: Date, subscriptionProcessor: SubscriptionProcessor)
    {
        try {
            const command = new AffMembershipPackageSubscribeCommand(this, ownerProfile, startAt, subscriptionProcessor)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
