//  MsgTemplate.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD55f82a55bad6f0245542618b417fa67d2#]

import {
    OMObject,
    OMObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import MsgSentMessage from "./MsgSentMessage"

export interface MsgTemplateProperties extends OMObjectProperties
{
    // MsgTemplate Attributes
    key: string
    body?: string | null
    lang?: string | null

    // MsgTemplate relationships
    messages?: OMReference<MsgSentMessage>[] | null
    messagesCount?: number
}

/**
 * MsgTemplate
 */
export default class MsgTemplate extends OMObject
    implements MsgTemplateProperties
{
    public static Type: string = "msg_template"
    public static Path: string = "msg_templates"

    // Creates a placeholder MsgTemplate instance
    public static createPlaceholder() {
        return new MsgTemplate({
            key: ""
        })
    }

    // MsgTemplate Attributes
    public key: string
    public body?: string | null
    public lang?: string | null

    // MsgTemplate Relationships
    public messages?: Array<OMReference<MsgSentMessage>> | null
    public messagesCount: number

    constructor(object: MsgTemplateProperties) {
        super(object)

        // MsgTemplate Attributes
        this.key = object.key
        this.body = object.body
        this.lang = object.lang

        // MsgTemplate Relationships
        this.messages = object.messages ? object.messages.map((item) => new OMReference(MsgSentMessage, item)) : []
        this.messagesCount = object.messagesCount || 0
    }
}
