//  MsgSmsTemplate.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD53121888082311234bc134d1f10dd3e6c#]

import {
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

import { default as MsgTemplate, MsgTemplateProperties } from "./MsgTemplate"

// Import related object types
import MsgSentMessage from "./MsgSentMessage"

export interface MsgSmsTemplateProperties extends MsgTemplateProperties
{

}

/**
 * MsgSmsTemplate
 */
export default class MsgSmsTemplate extends MsgTemplate
    implements MsgSmsTemplateProperties
{
    public static Type: string = "msg_sms_template"
    public static Path: string = "msg_sms_templates"

    // Creates a placeholder MsgSmsTemplate instance
    public static createPlaceholder() {
        return new MsgSmsTemplate({
            key: ""
        })
    }

    constructor(object: MsgSmsTemplateProperties) {
        super(object)
    }
}
