//  CnxSessionWallet.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5ae5a0ffa36cdd045c2e6ba6628b5c9f7#]

import {
    OMReference,
    CommandManager,
    DomainObject
} from "@apptivity-lab/firmament-node-sdk"

import { WltWallet, WltWalletProperties } from "../wallet"

// Import related object types
import CnxConsumerProfile from "./CnxConsumerProfile"

// Import command types
import { CnxSessionWalletTopupCommand } from "./CnxSessionWalletCommands"

export interface CnxSessionWalletProperties extends WltWalletProperties
{

    // CnxSessionWallet relationships
    consumer: OMReference<CnxConsumerProfile>
}

/**
 * CnxSessionWallet
 */
export default class CnxSessionWallet extends WltWallet
    implements CnxSessionWalletProperties
{
    public static Type: string = "cnx_session_wallet"
    public static Path: string = "cnx_session_wallets"

    // Creates a placeholder CnxSessionWallet instance
    public static createPlaceholder() {
        return new CnxSessionWallet({
            currency: "",
            consumer: new OMReference(CnxConsumerProfile, CnxConsumerProfile.createPlaceholder())
        })
    }

    // CnxSessionWallet Relationships
    public consumer: OMReference<CnxConsumerProfile>

    constructor(object: CnxSessionWalletProperties) {
        super(object)


        // CnxSessionWallet Relationships
        this.consumer = new OMReference(CnxConsumerProfile, object.consumer)

    }

    // CnxSessionWallet.topup()
    // @param payment: string,
    // @param topupPackage: string
    public topup(payment: string, topupPackage?: string)
    {
        try {
            const command = new CnxSessionWalletTopupCommand(this, payment, topupPackage)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
