import { FSMNamedState, JSONAPI, ObservableFiniteStateMachine, OMUniverse } from "@apptivity-lab/firmament-node-sdk";
import { useEffect, useMemo, useState } from "react";

export const useMachine = <T extends new (...args: any) => ObservableFiniteStateMachine<FSMNamedState>>(
    Machine: T,
    cache?: { [key: string]: JSONAPI }
): [InstanceType<T>, InstanceType<T>["state"]] => {
    const machine = useMemo(() => new Machine(cache) as InstanceType<T>, [Machine, cache])
    const [machineState, setMachineState] = useState(machine.state)

    useEffect(() => {
        machine.subscribe(setMachineState)
        return () => machine.unsubscribe(setMachineState)
    }, [machine])

    return [machine, machineState]
}

// Hook to attempt to address issue where universe may still be restoring
// in client-side while React Components already want to call machine
// initial transitions. Having to check OMUniverse installation insertion
// state everywhere is not ideal.
export const useRestoredUniverse = (callback: () => void) => {
    const installationIsInserted = OMUniverse.shared.installation && OMUniverse.shared.installation
        ? OMUniverse.shared.installation.isInserted
        : false

    useEffect(() => {
        if (installationIsInserted) {
            callback()
        }
    }, [callback, installationIsInserted])
}
