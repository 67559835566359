//  PmtCurrencyRate.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5f9d37cff9ecd3b43cfc19afbecd4e31f#]

import {
    OMObject,
    OMObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import PmtCurrencyPair from "./PmtCurrencyPair"

export interface PmtCurrencyRateProperties extends OMObjectProperties
{
    // PmtCurrencyRate Attributes
    date: Date
    rate: number
    source: string

    // PmtCurrencyRate relationships
    currencyPair: OMReference<PmtCurrencyPair>
}

/**
 * PmtCurrencyRate
 */
export default class PmtCurrencyRate extends OMObject
    implements PmtCurrencyRateProperties
{
    public static Type: string = "pmt_currency_rate"
    public static Path: string = "pmt_currency_rates"

    // Creates a placeholder PmtCurrencyRate instance
    public static createPlaceholder() {
        return new PmtCurrencyRate({
            date: new Date(),
            rate: 0,
            source: "",
            currencyPair: new OMReference(PmtCurrencyPair, PmtCurrencyPair.createPlaceholder())
        })
    }

    // PmtCurrencyRate Attributes
    public date: Date
    public rate: number
    public source: string

    // PmtCurrencyRate Relationships
    public currencyPair: OMReference<PmtCurrencyPair>

    constructor(object: PmtCurrencyRateProperties) {
        super(object)

        // PmtCurrencyRate Attributes
        this.date = new Date(object.date)
        this.rate = object.rate
        this.source = object.source

        // PmtCurrencyRate Relationships
        this.currencyPair = new OMReference(PmtCurrencyPair, object.currencyPair)
    }
}
