//  CnxConsumerRefund.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5bf67a9704a3f58e344a8b6397fe2610c#]

import {
    OMReference,
    DomainObject
} from "@apptivity-lab/firmament-node-sdk"

import { WltTransaction, WltTransactionProperties } from "../wallet"

// Import related object types
import { WltWallet } from "../wallet"

// CnxConsumerRefund Custom Datatypes
type TransactionStatus = "PENDING" | "APPROVED" | "REJECTED"
const TransactionStatusOptions = [
    { title: "Pending", value: "PENDING" },
    { title: "Approved", value: "APPROVED" },
    { title: "Rejected", value: "REJECTED" },
]
type TransactionType = "DEBIT" | "CREDIT"
const TransactionTypeOptions = [
    { title: "Debit", value: "DEBIT" },
    { title: "Credit", value: "CREDIT" },
]

export interface CnxConsumerRefundProperties extends WltTransactionProperties
{
    // CnxConsumerRefund Attributes
    reason?: string | null
}

/**
 * CnxConsumerRefund
 */
export default class CnxConsumerRefund extends WltTransaction
    implements CnxConsumerRefundProperties
{
    public static Type: string = "cnx_consumer_refund"
    public static Path: string = "cnx_consumer_refunds"

    // Creates a placeholder CnxConsumerRefund instance
    public static createPlaceholder() {
        return new CnxConsumerRefund({
            amountInCents: 0,
            currency: "",
            transactionStatus: "PENDING",
            transactionType: "DEBIT",
            wallet: new OMReference(WltWallet, WltWallet.createPlaceholder())
        })
    }

    // CnxConsumerRefund Attributes
    public reason?: string | null

    constructor(object: CnxConsumerRefundProperties) {
        super(object)

        // CnxConsumerRefund Attributes
        this.reason = object.reason
    }
}
