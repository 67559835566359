//  PushMessage.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD58e33618fd6f13307bf5b759d570a8402#]

import {
    OMInstallation as Installation,
    OMObject,
    OMObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import PushChannel from "./PushChannel"

export interface PushMessageProperties extends OMObjectProperties
{
    // PushMessage Attributes
    message: string
    messageId: string

    // PushMessage relationships
    channel?: OMReference<PushChannel> | null
    installation?: OMReference<Installation> | null
}

/**
 * PushMessage
 */
export default class PushMessage extends OMObject
    implements PushMessageProperties
{
    public static Type: string = "push_message"
    public static Path: string = "push_messages"

    // Creates a placeholder PushMessage instance
    public static createPlaceholder() {
        return new PushMessage({
            message: "",
            messageId: ""
        })
    }

    // PushMessage Attributes
    public message: string
    public messageId: string

    // PushMessage Relationships
    public channel?: OMReference<PushChannel> | null
    public installation?: OMReference<Installation> | null

    constructor(object: PushMessageProperties) {
        super(object)

        // PushMessage Attributes
        this.message = object.message
        this.messageId = object.messageId

        // PushMessage Relationships
        this.channel = object.channel ? new OMReference(PushChannel, object.channel) : null
        this.installation = object.installation ? new OMReference(Installation, object.installation) : null
    }
}
