//  SubscriptionCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD53e3741414ae8ac57bf6854dcdef57ff4#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { Subscription } from "."

/* SubscriptionCancelCommand */
export class SubscriptionCancelCommand extends Command<Subscription>
{
    public static readonly Path = "cancel"
    public static readonly Type = "subscription_cancel_command"
}

/* SubscriptionExpireCommand */
export class SubscriptionExpireCommand extends Command<Subscription>
{
    public static readonly Path = "expire"
    public static readonly Type = "subscription_expire_command"
}

/* SubscriptionGetDefaultPaymentMethodCommand */
export class SubscriptionGetDefaultPaymentMethodCommand extends Command<Subscription>
{
    public static readonly Path = "get_default_payment_method"
    public static readonly Type = "subscription_get_default_payment_method_command"
}

/* SubscriptionRenewCommand */
export class SubscriptionRenewCommand extends Command<Subscription>
{
    public static readonly Path = "renew"
    public static readonly Type = "subscription_renew_command"
}
