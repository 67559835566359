//  CnxMeetingTemplate.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD584d185fc5622f9703e721f33160a8937#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Protocols adopted
import { Categorizable, ConcreteCategorizable } from "../categories"

// Import related object types
// import { AffRating } from "../aff/AffRating"
import { CatCategory } from "../categories"
import { GenImage } from "../files"
import CnxMeeting from "./CnxMeeting"
import CnxProviderProfile from "./CnxProviderProfile"
import CnxServiceTier from "./CnxServiceTier"

// Import command types
import { CnxMeetingTemplateAwakeCommand, CnxMeetingTemplateCreateMeetingsCommand } from "./CnxMeetingTemplateCommands"

// CnxMeetingTemplate Custom Datatypes
type CronFormat = string

export interface CnxMeetingTemplateProperties extends DomainObjectProperties,
    Categorizable
{
    // CnxMeetingTemplate Attributes
    summary?: string | null
    cronFormat?: CronFormat | null
    durationMins: number
    startAt: Date
    endRepeatingAt?: Date | null
    videoRoomUrl?: string | null
    maximumBookings: number
    lastScheduleUntil?: Date | null

    // CnxMeetingTemplate relationships
    // overallRatings?: OMReference<AffRating>[] | null
    overallRatingsCount?: number
    overallRatingsAverage?: number | null
    overallRatingsCountByStar?: Map<string, string> | null
    coverPhoto?: OMReference<GenImage> | null
    meetings?: OMReference<CnxMeeting>[] | null
    meetingsCount?: number
    provider?: OMReference<CnxProviderProfile> | null
    serviceTier: OMReference<CnxServiceTier>
}

/**
 * CnxMeetingTemplate
 */
export default class CnxMeetingTemplate extends DomainObject
    implements CnxMeetingTemplateProperties
{
    public static Type: string = "cnx_meeting_template"
    public static Path: string = "cnx_meeting_templates"

    // Creates a placeholder CnxMeetingTemplate instance
    public static createPlaceholder() {
        return new CnxMeetingTemplate({
            durationMins: 0,
            startAt: new Date(),
            maximumBookings: 0,
            serviceTier: new OMReference(CnxServiceTier, CnxServiceTier.createPlaceholder())
        })
    }

    // CnxMeetingTemplate Attributes
    public summary?: string | null
    public cronFormat?: CronFormat | null
    public durationMins: number
    public startAt: Date
    public endRepeatingAt?: Date | null
    public videoRoomUrl?: string | null
    public maximumBookings: number
    public lastScheduleUntil?: Date | null

    // CnxMeetingTemplate Relationships
    // public overallRatings?: Array<OMReference<AffRating>> | null
    public overallRatingsCount: number
    public overallRatingsAverage: number
    public overallRatingsCountByStar: Map<string, string>
    public coverPhoto?: OMReference<GenImage> | null
    public meetings?: Array<OMReference<CnxMeeting>> | null
    public meetingsCount: number
    public provider?: OMReference<CnxProviderProfile> | null
    public serviceTier: OMReference<CnxServiceTier>

    // Categorizable Protocol
    public categories?: Array<OMReference<CatCategory>>| null
    public categoryCount: number
    public categorySet: string[]
    public categorySetByType: Map<string, string>

    constructor(object: CnxMeetingTemplateProperties) {
        super(object)

        // CnxMeetingTemplate Attributes
        this.summary = object.summary
        this.cronFormat = object.cronFormat
        this.durationMins = object.durationMins
        this.startAt = new Date(object.startAt)
        this.endRepeatingAt = object.endRepeatingAt ? new Date(object.endRepeatingAt) : null
        this.videoRoomUrl = object.videoRoomUrl
        this.maximumBookings = object.maximumBookings
        this.lastScheduleUntil = object.lastScheduleUntil ? new Date(object.lastScheduleUntil) : null

        // CnxMeetingTemplate Relationships
        // this.overallRatings = object.overallRatings ? object.overallRatings.map((item) => new OMReference(AffRating, item)) : []
        this.overallRatingsCount = object.overallRatingsCount || 0
        this.overallRatingsAverage = object.overallRatingsAverage || 0
        this.overallRatingsCountByStar = object.overallRatingsCountByStar || new Map<string, string>()
        this.coverPhoto = object.coverPhoto ? new OMReference(GenImage, object.coverPhoto) : null
        this.meetings = object.meetings ? object.meetings.map((item) => new OMReference(CnxMeeting, item)) : []
        this.meetingsCount = object.meetingsCount || 0
        this.provider = object.provider ? new OMReference(CnxProviderProfile, object.provider) : null
        this.serviceTier = new OMReference(CnxServiceTier, object.serviceTier)

        // Categorizable Protocol
        this.categories = object.categories ? object.categories.map((item) => new OMReference(CatCategory, item)) : []
        this.categoryCount = object.categoryCount || 0
        this.categorySet = object.categorySet || []
        this.categorySetByType = object.categorySetByType || new Map<string, string>()
    }

    // Categorizable.setCategories()
    // @param categories?: string[]
    public setCategories(categories?: string[])
    {
        return (new ConcreteCategorizable(this)).setCategories(categories)
    }

    // CnxMeetingTemplate.awake()
    public awake()
    {
        try {
            const command = new CnxMeetingTemplateAwakeCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // CnxMeetingTemplate.createMeetings()
    public createMeetings()
    {
        try {
            const command = new CnxMeetingTemplateCreateMeetingsCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
