//  GenImage.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import {
    OMFile,
    OMFileProperties
} from "@apptivity-lab/firmament-node-sdk"

export interface GenImageProperties extends OMFileProperties
{

}

/**
 * GenImage
 */
export default class GenImage extends OMFile
    implements GenImageProperties
{
    public static Type: string = "gen_image"
    public static Path: string = "gen_images"

    // Creates a placeholder GenImage instance
    public static createPlaceholder() {
        return new GenImage({ })
    }

    constructor(object: GenImageProperties) {
        super(object)
    }
}
