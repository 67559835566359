//  CatCategoryCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { CatCategory } from "."

/* CatCategoryDeleteBranchCommand */
export class CatCategoryDeleteBranchCommand extends Command<CatCategory>
{
    public static readonly Path = "delete_branch"
    public static readonly Type = "cat_category_delete_branch_command"
}

/* CatCategoryRefreshBranchCommand */
export class CatCategoryRefreshBranchCommand extends Command<CatCategory>
{
    public static readonly Path = "refresh_branch"
    public static readonly Type = "cat_category_refresh_branch_command"
}
