//  AffLesson.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD584522e0128f822ce72ca100130bd97f4#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Protocols adopted
import { Categorizable, ConcreteCategorizable } from "../categories"

// Import related object types
import { GenImage } from "../files"
import AffExpertProfile from "./AffExpertProfile"
import AffVideo from "./AffVideo"
import { CatCategory } from "../categories"

export interface AffLessonProperties extends DomainObjectProperties,
    Categorizable
{
    // AffLesson Attributes
    summary?: string | null
    duration: number

    // AffLesson relationships
    coverPhoto?: OMReference<GenImage> | null
    provider?: OMReference<AffExpertProfile> | null
    video?: OMReference<AffVideo> | null
}

/**
 * AffLesson
 */
export default class AffLesson extends DomainObject
    implements AffLessonProperties
{
    public static Type: string = "aff_lesson"
    public static Path: string = "aff_lessons"

    // Creates a placeholder AffLesson instance
    public static createPlaceholder() {
        return new AffLesson({
            duration: 0
        })
    }

    // AffLesson Attributes
    public summary?: string | null
    public duration: number

    // AffLesson Relationships
    public coverPhoto?: OMReference<GenImage> | null
    public provider?: OMReference<AffExpertProfile> | null
    public video?: OMReference<AffVideo> | null

    // Categorizable Protocol
    public categories?: Array<OMReference<CatCategory>>| null
    public categoryCount: number
    public categorySet: string[]
    public categorySetByType: Map<string, string>

    constructor(object: AffLessonProperties) {
        super(object)

        // AffLesson Attributes
        this.summary = object.summary
        this.duration = object.duration

        // AffLesson Relationships
        this.coverPhoto = object.coverPhoto ? new OMReference(GenImage, object.coverPhoto) : null
        this.provider = object.provider ? new OMReference(AffExpertProfile, object.provider) : null
        this.video = object.video ? new OMReference(AffVideo, object.video) : null

        // Categorizable Protocol
        this.categories = object.categories ? object.categories.map((item) => new OMReference(CatCategory, item)) : []
        this.categoryCount = object.categoryCount || 0
        this.categorySet = object.categorySet || []
        this.categorySetByType = object.categorySetByType || new Map<string, string>()
    }

    // Categorizable.setCategories()
    // @param categories?: string[]
    public setCategories(categories?: string[])
    {
        return (new ConcreteCategorizable(this)).setCategories(categories)
    }
}
