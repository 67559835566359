//  NotifBroadcastCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD502c5eaaf762f64f82e06a57c17d3676c#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { NotifBroadcast } from "."

/* NotifBroadcastCreateGroupMessagesCommand */
export class NotifBroadcastCreateGroupMessagesCommand extends Command<NotifBroadcast>
{
    public static readonly Path = "create_group_messages"
    public static readonly Type = "notif_broadcast_create_group_messages_command"
}

/* NotifBroadcastCreateMessageCommand */
export class NotifBroadcastCreateMessageCommand extends Command<NotifBroadcast>
{
    public static readonly Path = "create_message"
    public static readonly Type = "notif_broadcast_create_message_command"
}
