import { useEffect } from "react"
import type { AppProps } from "next/app"
import { useRouter } from "next/router"

import { OMUniverse } from "@apptivity-lab/firmament-node-sdk"

import { AppStateMachine } from "@machines/AppStateMachine"
import { useMachine } from "@lib"

import "../styles/globals.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import LoadingIndicator from "@components/LoadingIndicator"
import ErrorHUD from "@components/ErrorHUD"
import GoogleAnalytics from "@components/GoogleAnalytics"

const Noop: React.FC = ({ children }) => <>{children}</>

function AFFWebApp({ Component, pageProps }: AppProps) {
    // Use the layout defined at the page level, if available
    const PageLayout = (Component as any).Layout || Noop

    const router = useRouter()
    const [machine, machineState] = useMachine(AppStateMachine)

    useEffect(() => {
        // Machine only changes once (during initialization)
        // so this is similar to an app-wide appDidLaunch
        machine.restoreSession()
    }, [machine])

    useEffect(() => {
        // Similar to viewDidDisappear, triggered when route path changes
        machine.back()
    }, [machine, router.pathname])

    switch (machineState.name) {
        case "start":
            return (
                <>
                    <GoogleAnalytics />
                    <LoadingIndicator />
                    <Component {...pageProps} />
                </>
            )
        case "restoringSession":
            return <LoadingIndicator message={"Restoring session..."} />
        case "loadingAuthentication":
            return <LoadingIndicator message={"Loading authentication..."} />
        case "loadingProfile":
            return <LoadingIndicator message={"Loading profile..."} />
        case "loadingConsumerGroup":
        case "loadingSessionWallet":
            return <LoadingIndicator message={"Almost there..."} />
        case "showingAuthMenu":
            if (OMUniverse.shared.currentUser?.isAuthenticated !== true &&
                !router.pathname.startsWith("/auth")) {
                router.push("/auth")
            }
            return <PageLayout onLogout={() => machine.logout()}><Component {...pageProps} /></PageLayout>
        case "showingProfileSetup":
            if (!router.pathname.startsWith("/profile/new")) {
                router.push("/profile/new")
            }
            return <PageLayout onLogout={() => machine.logout()}><Component {...pageProps} /></PageLayout>
        case "showingMain":
            return <PageLayout onLogout={() => machine.logout()}><Component {...pageProps} /></PageLayout>
        case "showingError":
            return (
                <>
                    <ErrorHUD error={machineState.error} sticky={true} />
                </>
            )
        case "loggingOut":
            return <PageLayout onLogout={() => machine.logout()}><Component {...pageProps} /></PageLayout>
    }
}

export default AFFWebApp
