//  CnxArticleCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5f1819b8befb04b6afa403a0d3a835a32#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { CnxArticle } from "."

/* CnxArticleSetConsumersCommand */
export class CnxArticleSetConsumersCommand extends Command<CnxArticle>
{
    public static readonly Path = "set_consumers"
    public static readonly Type = "cnx_article_set_consumers_command"

    public consumers?: string[] | null

    public constructor(
        receiver: CnxArticle,
        consumers?: string[] | null
    ) {
        super(receiver)

        this.consumers = consumers
    }
}

/* CnxArticleSetProvidersCommand */
export class CnxArticleSetProvidersCommand extends Command<CnxArticle>
{
    public static readonly Path = "set_providers"
    public static readonly Type = "cnx_article_set_providers_command"

    public providers?: string[] | null

    public constructor(
        receiver: CnxArticle,
        providers?: string[] | null
    ) {
        super(receiver)

        this.providers = providers
    }
}
