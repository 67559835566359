
//  AffRenewalOrder.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD57f62225e4e44947e10ff29d3b13ce528#]

import {
    OMReference,
    CommandManager,
    DomainObject
} from "@apptivity-lab/firmament-node-sdk"

import { RenewalOrder, RenewalOrderProperties } from "../paid_memberships"

// Import related object types
import { Subscription } from "../paid_memberships"

// Import command types
import { AffRenewalOrderFulfillCommand } from "./AffRenewalOrderCommands"

// AffRenewalOrder Custom Datatypes
type OrderStatus = "CART" | "CHECKED_OUT" | "PAID" | "COMPLETED" | "CANCELLED" | "FAILED"
const OrderStatusOptions = [
    { title: "Cart", value: "CART" },
    { title: "Checked_Out", value: "CHECKED_OUT" },
    { title: "Paid", value: "PAID" },
    { title: "Completed", value: "COMPLETED" },
    { title: "Cancelled", value: "CANCELLED" },
    { title: "Failed", value: "FAILED" },
]

export interface AffRenewalOrderProperties extends RenewalOrderProperties
{

}

/**
 * AffRenewalOrder
 */
export default class AffRenewalOrder extends RenewalOrder
    implements AffRenewalOrderProperties
{
    public static Type: string = "aff_renewal_order"
    public static Path: string = "aff_renewal_orders"

    // Creates a placeholder AffRenewalOrder instance
    public static createPlaceholder() {
        return new AffRenewalOrder({
            currency: "MYR",
            status: "CART",
            subscription: new OMReference(Subscription, Subscription.createPlaceholder())
        })
    }

    constructor(object: AffRenewalOrderProperties) {
        super(object)
    }

    // AffRenewalOrder.fulfill()
    public fulfill()
    {
        try {
            const command = new AffRenewalOrderFulfillCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
