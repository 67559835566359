import { OMReference, OMUniverse } from "@apptivity-lab/firmament-node-sdk"
import { AffAttendeeProfile } from "@firmament-packages/aff"
import { CnxSessionWallet } from "@firmament-packages/connectx"
import { Subscription } from "@firmament-packages/paid_memberships"
import AffMembershipPackage from "../AffMembershipPackage"

declare module "@firmament-packages/aff" {
    interface AffAttendeeProfile {
        loadActiveSubscriptions(): Promise<Subscription | null>
        currentSubscription(): Subscription | null
        nextSubscription(): Subscription | null
        sessionWallet(): CnxSessionWallet | null
        availableSessionsBalanceDisplay(): string
    }
}

/**
 * Load active subscriptions, returning the current one.
 */
AffAttendeeProfile.prototype.loadActiveSubscriptions = async function() {
    const subscriptionQuery = Subscription.query()
        .include("product")
        .include("renewalOrders")
        .include("renewalOrders.payments" as any)
        .filter("ownerProfile", "equals", this.id as any)
        .filter("subscriptionStatus", "notEquals", "ENDED")
        // .filter("startAt", "lessThanEqual", new Date().toISOString() as any)
        .filterGroup([
            ["endAt", "isNull", undefined],
            ["endAt", "moreThanEqual", new Date().toISOString() as any]
        ], "OR")
        .sort("startAt", "ASC")
    await subscriptionQuery.execute()

    subscriptionQuery.resultObjects.forEach((subscription) => {
        OMUniverse.shared.touch(subscription)
    })

    if (subscriptionQuery.resultObjects.length > 0) {
        this.subscriptions = subscriptionQuery.results
    } else {
        this.subscriptions = []
    }
    OMUniverse.shared.touch(this)

    return this.currentSubscription()
}

AffAttendeeProfile.prototype.currentSubscription = function() {
    if (!this.subscriptions || this.subscriptions.length === 0) {
        return null
    }

    return this.subscriptions[0].actualObject || null
}

AffAttendeeProfile.prototype.nextSubscription = function() {
    if (!this.subscriptions || this.subscriptions.length <= 1) {
        return null
    }

    return this.subscriptions[1].actualObject || null
}

AffAttendeeProfile.prototype.sessionWallet = function() {
    if (!this.sessionWallets || this.sessionWallets.length === 0) {
        return null
    }

    return this.sessionWallets[0].actualObject || null
}

AffAttendeeProfile.prototype.availableSessionsBalanceDisplay = function() {
    const currentSubscription = this.currentSubscription()
    const membershipPackage = new OMReference(AffMembershipPackage, currentSubscription?.product?.id || "").actualObject

    if (!this.sessionWallets || this.sessionWallets.length === 0) {
        return !currentSubscription ? "Not Available" : "0 Left"
    }

    if (membershipPackage && membershipPackage.sessionsTopupCredits >= 10000) {
        return "Unlimited"
    }

    const sessionWallet = this.sessionWallets[0].actualObject || null
    if (!sessionWallet) {
        return "0 Left"
    }

    return `${sessionWallet.transactionsAvailableBalance / 100} Left`
}
