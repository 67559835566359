//  CnxConsumerProfile.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5cd31a428332d52a781d7779a5697065b#]

import {
    OMReference,
    User,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

import { default as CnxMemberProfile, CnxMemberProfileProperties } from "./CnxMemberProfile"

// Import related object types
import CnxBooking from "./CnxBooking"
import CnxProviderProfile from "./CnxProviderProfile"
import CnxSessionWallet from "./CnxSessionWallet"
import CnxServiceTier from "./CnxServiceTier"
import CnxArticle from "./CnxArticle"
import CnxMeeting from "./CnxMeeting"

// Import command types
import { CnxConsumerProfileSummarizeMeetingsCommand } from "./CnxConsumerProfileCommands"

export interface CnxConsumerProfileProperties extends CnxMemberProfileProperties
{
    // CnxConsumerProfile relationships
    bookings?: OMReference<CnxBooking>[] | null
    bookingsCount?: number
    favoriteProviders?: OMReference<CnxProviderProfile>[] | null
    favoriteProviderCount?: number
    sessionWallets?: OMReference<CnxSessionWallet>[] | null
    sessionWalletsCount?: number
    tierAccesses?: OMReference<CnxServiceTier>[] | null
    tierAccessCount?: number
    consumerArticles?: OMReference<CnxArticle>[] | null
    consumerArticleCount?: number
    attendedMeetings?: OMReference<CnxMeeting>[] | null
    attendedMeetingCount?: number
    meetings?: OMReference<CnxMeeting>[] | null
    meetingCount?: number
}

/**
 * CnxConsumerProfile
 */
export default class CnxConsumerProfile extends CnxMemberProfile
    implements CnxConsumerProfileProperties
{
    public static Type: string = "cnx_consumer_profile"
    public static Path: string = "cnx_consumer_profiles"

    // Creates a placeholder CnxConsumerProfile instance
    public static createPlaceholder() {
        return new CnxConsumerProfile({
            user: new OMReference(User, User.createPlaceholder())
        })
    }

    // CnxConsumerProfile Relationships
    public bookings?: Array<OMReference<CnxBooking>> | null
    public bookingsCount: number
    public favoriteProviders?: Array<OMReference<CnxProviderProfile>>| null
    public favoriteProviderCount: number
    public sessionWallets?: Array<OMReference<CnxSessionWallet>> | null
    public sessionWalletsCount: number
    public tierAccesses?: Array<OMReference<CnxServiceTier>>| null
    public tierAccessCount: number
    public consumerArticles?: Array<OMReference<CnxArticle>>| null
    public consumerArticleCount: number
    public attendedMeetings?: Array<OMReference<CnxMeeting>>| null
    public attendedMeetingCount: number
    public meetings?: Array<OMReference<CnxMeeting>>| null
    public meetingCount: number

    constructor(object: CnxConsumerProfileProperties) {
        super(object)


        // CnxConsumerProfile Relationships
        this.bookings = object.bookings ? object.bookings.map((item) => new OMReference(CnxBooking, item)) : []
        this.bookingsCount = object.bookingsCount || 0
        this.favoriteProviders = object.favoriteProviders ? object.favoriteProviders.map((item) => new OMReference(CnxProviderProfile, item)) : []
        this.favoriteProviderCount = object.favoriteProviderCount || 0
        this.sessionWallets = object.sessionWallets ? object.sessionWallets.map((item) => new OMReference(CnxSessionWallet, item)) : []
        this.sessionWalletsCount = object.sessionWalletsCount || 0
        this.tierAccesses = object.tierAccesses ? object.tierAccesses.map((item) => new OMReference(CnxServiceTier, item)) : []
        this.tierAccessCount = object.tierAccessCount || 0
        this.consumerArticles = object.consumerArticles ? object.consumerArticles.map((item) => new OMReference(CnxArticle, item)) : []
        this.consumerArticleCount = object.consumerArticleCount || 0
        this.attendedMeetings = object.attendedMeetings ? object.attendedMeetings.map((item) => new OMReference(CnxMeeting, item)) : []
        this.attendedMeetingCount = object.attendedMeetingCount || 0
        this.meetings = object.meetings ? object.meetings.map((item) => new OMReference(CnxMeeting, item)) : []
        this.meetingCount = object.meetingCount || 0

    }

    // CnxConsumerProfile.summarizeMeetings()
    public summarizeMeetings()
    {
        try {
            const command = new CnxConsumerProfileSummarizeMeetingsCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

}
