//  ArticleMedia.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5d34f6c8c462677619da5ee79371632e6#]

import {
    OMReference,
    DomainObject
} from "@apptivity-lab/firmament-node-sdk"

import { default as ArticleContent, ArticleContentProperties } from "./ArticleContent"

// Import related object types
import { GenImage } from "../files"
import Article from "./Article"


export interface ArticleMediaProperties extends ArticleContentProperties
{
    // ArticleMedia relationships
    image?: OMReference<GenImage> | null
}

/**
 * ArticleMedia
 */
export default class ArticleMedia extends ArticleContent
    implements ArticleMediaProperties
{
    public static Type: string = "article_media"
    public static Path: string = "article_media"

    // Creates a placeholder ArticleMedia instance
    public static createPlaceholder() {
        return new ArticleMedia({
            article: new OMReference(Article, Article.createPlaceholder())
        })
    }

    // ArticleMedia Relationships
    public image?: OMReference<GenImage> | null

    constructor(object: ArticleMediaProperties) {
        super(object)

        // ArticleMedia Relationships
        this.image = object.image ? new OMReference(GenImage, object.image) : null
    }
}
