//  Subscription.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5f429a88fa8f51aa3bfc4e10aa74b1566#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import { DomainProfile } from "../kernel"
import SubscriptionPackage from "./SubscriptionPackage"
import RenewalOrder from "./RenewalOrder"

// Import command types
import { SubscriptionCancelCommand, SubscriptionExpireCommand, SubscriptionGetDefaultPaymentMethodCommand, SubscriptionRenewCommand } from "./SubscriptionCommands"

// Subscription Custom Datatypes
export type SubscriptionStatus = "PENDING" | "ACTIVE" | "ENDED"
export const SubscriptionStatusOptions = [
    { title: "Pending", value: "PENDING" },
    { title: "Active", value: "ACTIVE" },
    { title: "Ended", value: "ENDED" },
]

export type SubscriptionProcessor = "STRIPE" | "MANUAL"
export const SubscriptionProcessorOptions = [
    { title: "Stripe", value: "STRIPE" },
    { title: "Manual", value: "MANUAL" },
]

export interface SubscriptionProperties extends DomainObjectProperties
{
    // Subscription Attributes
    endAt?: Date | null
    isRenewing?: boolean | null
    isTrial?: boolean | null
    startAt?: Date | null
    subscriptionStatus: SubscriptionStatus
    subscriptionProcessor?: SubscriptionProcessor | null
    processorSubscriptionId?: string | null

    // Subscription relationships
    ownerProfile: OMReference<DomainProfile>
    product: OMReference<SubscriptionPackage>
    renewalOrders?: OMReference<RenewalOrder>[] | null
    renewalOrdersCount?: number
}

/**
 * Subscription
 */
export default class Subscription extends DomainObject
    implements SubscriptionProperties
{
    public static Type: string = "subscription"
    public static Path: string = "subscriptions"

    // Creates a placeholder Subscription instance
    public static createPlaceholder() {
        return new Subscription({
            subscriptionStatus: "PENDING",
            ownerProfile: new OMReference(DomainProfile, DomainProfile.createPlaceholder()),
            product: new OMReference(SubscriptionPackage, SubscriptionPackage.createPlaceholder())
        })
    }

    // Subscription Attributes
    public endAt?: Date | null
    public isRenewing?: boolean | null
    public isTrial?: boolean | null
    public startAt?: Date | null
    public subscriptionStatus: SubscriptionStatus
    public subscriptionProcessor?: SubscriptionProcessor | null
    public processorSubscriptionId?: string | null

    // Subscription Relationships
    public ownerProfile: OMReference<DomainProfile>
    public product: OMReference<SubscriptionPackage>
    public renewalOrders?: Array<OMReference<RenewalOrder>> | null
    public renewalOrdersCount: number

    constructor(object: SubscriptionProperties) {
        super(object)

        // Subscription Attributes
        this.endAt = object.endAt ? new Date(object.endAt) : null
        this.isRenewing = object.isRenewing
        this.isTrial = object.isTrial
        this.startAt = object.startAt ? new Date(object.startAt) : null
        this.subscriptionStatus = object.subscriptionStatus
        this.subscriptionProcessor = object.subscriptionProcessor
        this.processorSubscriptionId = object.processorSubscriptionId

        // Subscription Relationships
        this.ownerProfile = new OMReference(DomainProfile, object.ownerProfile)
        this.product = new OMReference(SubscriptionPackage, object.product)
        this.renewalOrders = object.renewalOrders ? object.renewalOrders.map((item) => new OMReference(RenewalOrder, item)) : []
        this.renewalOrdersCount = object.renewalOrdersCount || 0
    }

    // Subscription.cancel()
    public cancel()
    {
        try {
            const command = new SubscriptionCancelCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // Subscription.expire()
    public expire()
    {
        try {
            const command = new SubscriptionExpireCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // Subscription.getDefaultPaymentMethod()
    public getDefaultPaymentMethod()
    {
        try {
            const command = new SubscriptionGetDefaultPaymentMethodCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // Subscription.renew()
    public renew()
    {
        try {
            const command = new SubscriptionRenewCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
