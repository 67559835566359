//  DcvDiscoveryFilter.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import {
    DomainObject,
    DomainObjectProperties
} from "@apptivity-lab/firmament-node-sdk"

// DcvDiscoveryFilter Custom Datatypes
type DiscoveryType = "LINK" | "CATEGORY"
const DiscoveryTypeOptions = [
    { title: "Link", value: "LINK" },
    { title: "Category", value: "CATEGORY" },
]

export interface DcvDiscoveryFilterProperties extends DomainObjectProperties
{
    // DcvDiscoveryFilter Attributes
    filter: string
    itemType: DiscoveryType

}

/**
 * DcvDiscoveryFilter
 */
export default class DcvDiscoveryFilter extends DomainObject
    implements DcvDiscoveryFilterProperties
{
    public static Type: string = "dcv_discovery_filter"
    public static Path: string = "dcv_discovery_filters"

    // Creates a placeholder DcvDiscoveryFilter instance
    public static createPlaceholder() {
        return new DcvDiscoveryFilter({
            filter: "",
            itemType: "LINK"
        })
    }

    // DcvDiscoveryFilter Attributes
    public filter: string
    public itemType: DiscoveryType



    constructor(object: DcvDiscoveryFilterProperties) {
        super(object)

        // DcvDiscoveryFilter Attributes
        this.filter = object.filter
        this.itemType = object.itemType


    }

}
