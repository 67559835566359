//  ArticleContent.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD57d4d697101589e9e2431b656a3212b61#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import Article from "./Article"

export interface ArticleContentProperties extends DomainObjectProperties
{
    // ArticleContent Attributes
    sequence?: number | null

    // ArticleContent relationships
    article: OMReference<Article>
}

/**
 * ArticleContent
 */
export default class ArticleContent extends DomainObject
    implements ArticleContentProperties
{
    public static Type: string = "article_content"
    public static Path: string = "article_contents"

    // Creates a placeholder ArticleContent instance
    public static createPlaceholder() {
        return new ArticleContent({
            article: new OMReference(Article, Article.createPlaceholder())
        })
    }

    // ArticleContent Attributes
    public sequence?: number | null

    // ArticleContent Relationships
    public article: OMReference<Article>

    constructor(object: ArticleContentProperties) {
        super(object)

        // ArticleContent Attributes
        this.sequence = object.sequence

        // ArticleContent Relationships
        this.article = new OMReference(Article, object.article)
    }
}
