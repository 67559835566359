//  AffRenewalOrderCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5a21f8dd2c9c15166044e4a33ac295a3e#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { AffRenewalOrder } from "."

/* AffRenewalOrderFulfillCommand */
export class AffRenewalOrderFulfillCommand extends Command<AffRenewalOrder>
{
    public static readonly Path = "fulfill"
    public static readonly Type = "aff_renewal_order_fulfill_command"
}
