//  ArticleHeader.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD50c853ebce5bee81612a8ce38b40c8ef9#]

import {
    OMReference,
    DomainObject
} from "@apptivity-lab/firmament-node-sdk"

import { default as ArticleContent, ArticleContentProperties } from "./ArticleContent"

// Import related object types
import Article from "./Article"

export interface ArticleHeaderProperties extends ArticleContentProperties
{

}

/**
 * ArticleHeader
 */
export default class ArticleHeader extends ArticleContent
    implements ArticleHeaderProperties
{
    public static Type: string = "article_header"
    public static Path: string = "article_headers"

    // Creates a placeholder ArticleHeader instance
    public static createPlaceholder() {
        return new ArticleHeader({
            article: new OMReference(Article, Article.createPlaceholder())
        })
    }

    constructor(object: ArticleHeaderProperties) {
        super(object)

    }

}
