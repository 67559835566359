//  DcvDiscovery.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Protocols adopted
import { Publishable, ConcretePublishable } from "../publishable"

// Import related object types
import DcvListSection from "./DcvListSection"


export interface DcvDiscoveryProperties extends DomainObjectProperties,
    Publishable
{

    // DcvDiscovery relationships
    sections?: OMReference<DcvListSection>[] | null
    sectionsCount?: number
}

/**
 * DcvDiscovery
 */
export default class DcvDiscovery extends DomainObject
    implements DcvDiscoveryProperties
{
    public static Type: string = "dcv_discovery"
    public static Path: string = "dcv_discoveries"

    // Creates a placeholder DcvDiscovery instance
    public static createPlaceholder() {
        return new DcvDiscovery({ })
    }


    // DcvDiscovery Relationships
    public sections?: Array<OMReference<DcvListSection>> | null
    public sectionsCount: number

    // Publishable Protocol
    public publishAt?: Date | null
    public unpublishAt?: Date | null

    constructor(object: DcvDiscoveryProperties) {
        super(object)


        // DcvDiscovery Relationships
        this.sections = object.sections ? object.sections.map((item) => new OMReference(DcvListSection, item)) : []
        this.sectionsCount = object.sectionsCount || 0

        // Publishable Protocol
        this.publishAt = object.publishAt ? new Date(object.publishAt) : null
        this.unpublishAt = object.unpublishAt ? new Date(object.unpublishAt) : null

    }

}
