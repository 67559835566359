//  DcvListSection.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Protocols adopted
import { Sequenceable, ConcreteSequenceable } from "../sequenceable"

// Import related object types
import ConcreteDiscoverable from "./Discoverable"
import DcvDiscovery from "./DcvDiscovery"

// DcvListSection Custom Datatypes
export type DiscoveryType = "LINK" | "CATEGORY"
export const DiscoveryTypeOptions = [
    { title: "Link", value: "LINK" },
    { title: "Category", value: "CATEGORY" },
]
export type DiscoveryLayout = "SMALL_GRID" | "MEDIUM_GRID" | "LARGE_GRID" | "SMALL_LIST" | "MEDIUM_LIST" | "LARGE_LIST"
export const DiscoveryLayoutOptions = [
    { title: "SMALL_GRID", value: "SMALL_GRID" },
    { title: "MEDIUM_GRID", value: "MEDIUM_GRID" },
    { title: "LARGE_GRID", value: "LARGE_GRID" },
    { title: "SMALL_LIST", value: "SMALL_LIST" },
    { title: "MEDIUM_LIST", value: "MEDIUM_LIST" },
    { title: "LARGE_LIST", value: "LARGE_LIST" },
]
export type DiscoveryCacheSource = "SERVER" | "CLIENT"
export const DiscoveryCacheSourceOptions = [
    { title: "Server", value: "SERVER" },
    { title: "Client", value: "CLIENT" },
]

export interface DcvListSectionProperties extends DomainObjectProperties,
    Sequenceable
{
    // DcvListSection Attributes
    manualOrdering?: string[] | null
    itemLimit?: number | null
    filter?: string | null
    includePaths?: string[] | null
    searchFilter?: string | null
    sortCriteria?: string[] | null
    layoutType: DiscoveryLayout
    itemCacheSource: DiscoveryCacheSource
    itemType: DiscoveryType
    moreUrl?: string | null
    subtitle?: string | null

    // DcvListSection relationships
    cachedItems?: OMReference<ConcreteDiscoverable>[] | null
    cachedItemCount?: number
    discovery?: OMReference<DcvDiscovery> | null
}

/**
 * DcvListSection
 */
export default class DcvListSection extends DomainObject
    implements DcvListSectionProperties
{
    public static Type: string = "dcv_list_section"
    public static Path: string = "dcv_list_sections"

    // Creates a placeholder DcvListSection instance
    public static createPlaceholder() {
        return new DcvListSection({
            layoutType: "SMALL_GRID",
            itemCacheSource: "SERVER",
            itemType: "LINK"
        })
    }

    // DcvListSection Attributes
    public manualOrdering?: string[] | null
    public itemLimit?: number | null
    public filter?: string | null
    public includePaths?: string[] | null
    public searchFilter?: string | null
    public sortCriteria?: string[] | null
    public layoutType: DiscoveryLayout
    public itemCacheSource: DiscoveryCacheSource
    public itemType: DiscoveryType
    public moreUrl?: string | null
    public subtitle?: string | null

    // DcvListSection Relationships
    public cachedItems?: Array<OMReference<ConcreteDiscoverable>>| null
    public cachedItemCount: number
    public discovery?: OMReference<DcvDiscovery> | null

    // Sequenceable Protocol
    public sequence?: number | null

    constructor(object: DcvListSectionProperties) {
        super(object)

        // DcvListSection Attributes
        this.manualOrdering = object.manualOrdering
        this.itemLimit = object.itemLimit
        this.filter = object.filter
        this.includePaths = object.includePaths
        this.searchFilter = object.searchFilter
        this.sortCriteria = object.sortCriteria
        this.layoutType = object.layoutType
        this.itemCacheSource = object.itemCacheSource
        this.itemType = object.itemType
        this.moreUrl = object.moreUrl
        this.subtitle = object.subtitle

        // DcvListSection Relationships
        this.cachedItems = object.cachedItems ? object.cachedItems.map((item) => new OMReference(ConcreteDiscoverable, item)) : []
        this.cachedItemCount = object.cachedItemCount || 0
        this.discovery = object.discovery ? new OMReference(DcvDiscovery, object.discovery) : null

        // Sequenceable Protocol
        this.sequence = object.sequence

    }

}
