//  Discoverable.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import {
    OMObject,
    OMObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import DcvListSection from "./DcvListSection"


export interface Discoverable extends OMObjectProperties
{

    // Discoverable relationships
    listSections?: OMReference<DcvListSection>[] | null
    listSectionCount?: number
}

/**
 * ConcreteDiscoverable
 */
export default class ConcreteDiscoverable extends OMObject
    implements Discoverable
{
    public static Type: string = "discoverable"
    public static Path: string = "discoverables"

    // Creates a placeholder ConcreteDiscoverable instance
    public static createPlaceholder() {
        return new ConcreteDiscoverable({ })
    }


    // Discoverable Relationships
    public listSections?: Array<OMReference<DcvListSection>>| null
    public listSectionCount: number

    constructor(object: Discoverable) {
        super(object)


        // Discoverable Relationships
        this.listSections = object.listSections ? object.listSections.map((item) => new OMReference(DcvListSection, item)) : []
        this.listSectionCount = object.listSectionCount || 0

    }

}
