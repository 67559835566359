//  PmtPaymentCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5bf88e774c6049743f13f6d94b528a981#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { PmtPayment } from "."

/* PmtPaymentCancelCommand */
export class PmtPaymentCancelCommand extends Command<PmtPayment>
{
    public static readonly Path = "cancel"
    public static readonly Type = "pmt_payment_cancel_command"

    public resultStatus: string
    public reason?: string | null

    public constructor(
        receiver: PmtPayment,
        resultStatus: string,
        reason?: string | null
    ) {
        super(receiver)

        this.resultStatus = resultStatus
        this.reason = reason
    }
}

/* PmtPaymentCaptureCommand */
export class PmtPaymentCaptureCommand extends Command<PmtPayment>
{
    public static readonly Path = "capture"
    public static readonly Type = "pmt_payment_capture_command"

    public resultStatus: string
    public failureCode?: string | null
    public failureReason?: string | null

    public constructor(
        receiver: PmtPayment,
        resultStatus: string,
        failureCode?: string | null,
        failureReason?: string | null
    ) {
        super(receiver)

        this.resultStatus = resultStatus
        this.failureCode = failureCode
        this.failureReason = failureReason
    }
}

/* PmtPaymentRefundCommand */
export class PmtPaymentRefundCommand extends Command<PmtPayment>
{
    public static readonly Path = "refund"
    public static readonly Type = "pmt_payment_refund_command"

    public resultStatus: string
    public reason?: string | null

    public constructor(
        receiver: PmtPayment,
        resultStatus: string,
        reason?: string | null
    ) {
        super(receiver)

        this.resultStatus = resultStatus
        this.reason = reason
    }
}
