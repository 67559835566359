//  CnxMessage.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD512de5902587244258c6a5ff233fb0654#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import { GenImage } from "../files"
import CnxMemberProfile from "./CnxMemberProfile"
import CnxMeeting from "./CnxMeeting"

export interface CnxMessageProperties extends DomainObjectProperties
{
    // CnxMessage Attributes
    messageType: string
    text?: string | null
    suggestions?: Map<string, string> | null

    // CnxMessage relationships
    attachedImage?: OMReference<GenImage> | null
    recipient?: OMReference<CnxMemberProfile> | null
    replies?: OMReference<CnxMessage>[] | null
    repliesCount?: number
    sender: OMReference<CnxMemberProfile>
    meeting: OMReference<CnxMeeting>
    inReplyTo?: OMReference<CnxMessage> | null
}

/**
 * CnxMessage
 */
export default class CnxMessage extends DomainObject
    implements CnxMessageProperties
{
    public static Type: string = "cnx_message"
    public static Path: string = "cnx_messages"

    // Creates a placeholder CnxMessage instance
    public static createPlaceholder() {
        return new CnxMessage({
            messageType: "TEXT",
            sender: new OMReference(CnxMemberProfile, CnxMemberProfile.createPlaceholder()),
            meeting: new OMReference(CnxMeeting, CnxMeeting.createPlaceholder())
        })
    }

    // CnxMessage Attributes
    public messageType: string
    public text?: string | null
    public suggestions?: Map<string, string> | null

    // CnxMessage Relationships
    public attachedImage?: OMReference<GenImage> | null
    public recipient?: OMReference<CnxMemberProfile> | null
    public replies?: Array<OMReference<CnxMessage>> | null
    public repliesCount: number
    public sender: OMReference<CnxMemberProfile>
    public meeting: OMReference<CnxMeeting>
    public inReplyTo?: OMReference<CnxMessage> | null

    constructor(object: CnxMessageProperties) {
        super(object)

        // CnxMessage Attributes
        this.messageType = object.messageType
        this.text = object.text
        this.suggestions = object.suggestions

        // CnxMessage Relationships
        this.attachedImage = object.attachedImage ? new OMReference(GenImage, object.attachedImage) : null
        this.recipient = object.recipient ? new OMReference(CnxMemberProfile, object.recipient) : null
        this.replies = object.replies ? object.replies.map((item) => new OMReference(CnxMessage, item)) : []
        this.repliesCount = object.repliesCount || 0
        this.sender = new OMReference(CnxMemberProfile, object.sender)
        this.meeting = new OMReference(CnxMeeting, object.meeting)
        this.inReplyTo = object.inReplyTo ? new OMReference(CnxMessage, object.inReplyTo) : null
    }
}
