import { OMUniverse, User } from "@apptivity-lab/firmament-node-sdk"
import { OMProfile } from "@apptivity-lab/firmament-node-sdk"
import { AffAttendeeProfile } from "@firmament-packages/aff"

declare module "@apptivity-lab/firmament-node-sdk/dist/Identity" {
    interface User {
        firstProfileTyped<ProfileType extends OMProfile>(type: new (...args: any) => ProfileType): ProfileType | undefined
        attendeeProfile(): AffAttendeeProfile | undefined
    }
}

User.prototype.firstProfileTyped = function<ProfileType extends OMProfile>(type: new (...args: any) => ProfileType) {
    return (this.profiles ?? []).reduce<ProfileType | undefined>((previous, current) => {
        if (previous) {
            return previous
        }
        return OMUniverse.shared.find(type, current.id)
    }, undefined)
}

User.prototype.attendeeProfile = function() {
    return (this.profiles ?? []).reduce<AffAttendeeProfile | undefined>((previous, current) => {
        if (previous) {
            return previous
        }
        return OMUniverse.shared.find(AffAttendeeProfile, current.id)
    }, undefined)
}
