import { Dispatch, SetStateAction, useEffect } from "react"

import { OMObjectProperties } from "@apptivity-lab/firmament-node-sdk"

import { useUpdateableState } from ".."

/**
 * This hook keeps fieldset model state, and triggers onChange callback only
 * if there are any "content" changes in the object.
 *
 * @param placeholder New instance of model
 * @param value Current value from component props (if any)
 * @param onChange Change handler that is typically pass in the component as props
 */
export const useFieldsetModel = <T extends OMObjectProperties>(
    placeholder: T,
    value?: T,
    onChange?: (model?: T) => void
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] => {
    const [model, setModel] = useUpdateableState(value || placeholder)

    useEffect(() => {
        // Compare JSON encoded version as a way to determine if we need to
        // trigger onChange. We need this extra step because React only
        // performs shallow comparison on a non-primitive object to determine
        // if a dependency has changed.
        if (JSON.stringify(value) !== JSON.stringify(model)) {
            onChange && onChange(Object.assign(placeholder, model))
        }
    }, [placeholder, value, model, onChange])

    return [model, setModel]
}
