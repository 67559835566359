//  CnxProviderProfileCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD50c1595abb70152736f4a00503e742c9c#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { CnxProviderProfile } from "."

/* CnxProviderProfileFollowCommand */
export class CnxProviderProfileFollowCommand extends Command<CnxProviderProfile>
{
    public static readonly Path = "follow"
    public static readonly Type = "cnx_provider_profile_follow_command"

    public profile: string

    public constructor(
        receiver: CnxProviderProfile,
        profile: string
    ) {
        super(receiver)

        this.profile = profile
    }
}

/* CnxProviderProfileSetTiersCommand */
export class CnxProviderProfileSetTiersCommand extends Command<CnxProviderProfile>
{
    public static readonly Path = "set_service_tiers"
    public static readonly Type = "cnx_provider_profile_set_tiers_command"

    public serviceTiers?: string[] | null

    public constructor(
        receiver: CnxProviderProfile,
        serviceTiers?: string[] | null
    ) {
        super(receiver)

        this.serviceTiers = serviceTiers
    }
}

/* CnxProviderProfileSummarizeMeetingsCommand */
export class CnxProviderProfileSummarizeMeetingsCommand extends Command<CnxProviderProfile>
{
    public static readonly Path = "summarize_meetings"
    public static readonly Type = "cnx_provider_profile_summarize_meetings_command"
}

/* CnxProviderProfileUnfollowCommand */
export class CnxProviderProfileUnfollowCommand extends Command<CnxProviderProfile>
{
    public static readonly Path = "unfollow"
    public static readonly Type = "cnx_provider_profile_unfollow_command"

    public profile: string

    public constructor(
        receiver: CnxProviderProfile,
        profile: string
    ) {
        super(receiver)

        this.profile = profile
    }
}
