//  GroupCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5e7c188545e79973cb6fb17dfd78e11c6#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { Group } from "."

/* IdAddMemberCommand */
export class IdAddMemberCommand extends Command<Group>
{
    public static readonly Path = "add_member"
    public static readonly Type = "id_add_member_command"

    public party: string

    public constructor(
        receiver: Group,
        party: string
    ) {
        super(receiver)

        this.party = party
    }
}

/* IdRemoveMemberCommand */
export class IdRemoveMemberCommand extends Command<Group>
{
    public static readonly Path = "remove_member"
    public static readonly Type = "id_remove_member_command"

    public party: string

    public constructor(
        receiver: Group,
        party: string
    ) {
        super(receiver)

        this.party = party
    }
}
