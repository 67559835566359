
//  AffVideoViewership.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD59164e1b519cc50e1e33538e99d548e5d#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import AffVideo from "./AffVideo"
import { CnxConsumerProfile } from "../connectx"


export interface AffVideoViewershipProperties extends DomainObjectProperties
{
    // AffVideoViewership Attributes
    playCount?: number | null
    lastProgress?: number | null

    // AffVideoViewership relationships
    video: OMReference<AffVideo>
    consumer: OMReference<CnxConsumerProfile>
}

/**
 * AffVideoViewership
 */
export default class AffVideoViewership extends DomainObject
    implements AffVideoViewershipProperties
{
    public static Type: string = "aff_video_viewership"
    public static Path: string = "aff_video_viewerships"

    // Creates a placeholder AffVideoViewership instance
    public static createPlaceholder() {
        return new AffVideoViewership({
            video: new OMReference(AffVideo, AffVideo.createPlaceholder()),
            consumer: new OMReference(CnxConsumerProfile, CnxConsumerProfile.createPlaceholder())
        })
    }

    // AffVideoViewership Attributes
    public playCount?: number | null
    public lastProgress?: number | null

    // AffVideoViewership Relationships
    public video: OMReference<AffVideo>
    public consumer: OMReference<CnxConsumerProfile>

    constructor(object: AffVideoViewershipProperties) {
        super(object)

        // AffVideoViewership Attributes
        this.playCount = object.playCount ? object.playCount : null
        this.lastProgress = object.lastProgress ? object.lastProgress : null

        // AffVideoViewership Relationships
        this.video = new OMReference(AffVideo, object.video)
        this.consumer = new OMReference(CnxConsumerProfile, object.consumer)

    }

}
