//  DomainProfileCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD514367bcba6d6584106a77fab277caafe#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { DomainProfile } from "."

/* PmtProfileCreatePaymentIntentCommand */
export class PmtProfileCreatePaymentIntentCommand extends Command<DomainProfile>
{
    public static readonly Path = "create_payment_intent"
    public static readonly Type = "pmt_profile_create_payment_intent_command"

    public amountInCents: number
    public currency: string
    public captureAutomatically: boolean
    public paymentIntentId: string
    public receiptEmail?: string | null
    public description?: string | null

    public constructor(
        receiver: DomainProfile,
        amountInCents: number,
        currency: string,
        captureAutomatically: boolean,
        paymentIntentId: string,
        receiptEmail?: string | null,
        description?: string | null
    ) {
        super(receiver)

        this.amountInCents = amountInCents
        this.currency = currency
        this.captureAutomatically = captureAutomatically
        this.paymentIntentId = paymentIntentId
        this.receiptEmail = receiptEmail
        this.description = description
    }
}



/* PmtProfileGenerateEphemeralKeyCommand */
export class PmtProfileGenerateEphemeralKeyCommand extends Command<DomainProfile>
{
    public static readonly Path = "generate_ephemeral_key"
    public static readonly Type = "pmt_profile_generate_ephemeral_key_command"

    public apiVersion: string
    public ephemeralKey?: any | null

    public constructor(
        receiver: DomainProfile,
        apiVersion: string,
        ephemeralKey?: any | null
    ) {
        super(receiver)

        this.apiVersion = apiVersion
        this.ephemeralKey = ephemeralKey
    }
}



/* PmtProfileRegisterStripeCustomerCommand */
export class PmtProfileRegisterStripeCustomerCommand extends Command<DomainProfile>
{
    public static readonly Path = "register_stripe_customer"
    public static readonly Type = "pmt_profile_register_stripe_customer_command"

    public name?: string | null
    public phone?: string | null
    public email?: string | null
    public stripeCustomerId?: string | null

    public constructor(
        receiver: DomainProfile,
        name?: string | null,
        phone?: string | null,
        email?: string | null,
        stripeCustomerId?: string | null
    ) {
        super(receiver)

        this.name = name
        this.phone = phone
        this.email = email
        this.stripeCustomerId = stripeCustomerId
    }
}



/* PmtProfileUpdateStripeCustomerCommand */
export class PmtProfileUpdateStripeCustomerCommand extends Command<DomainProfile>
{
    public static readonly Path = "update_stripe_customer"
    public static readonly Type = "pmt_profile_update_stripe_customer_command"

    public name?: string | null
    public phone?: string | null
    public email?: string | null

    public constructor(
        receiver: DomainProfile,
        name?: string | null,
        phone?: string | null,
        email?: string | null
    ) {
        super(receiver)

        this.name = name
        this.phone = phone
        this.email = email
    }
}
