//  CnxBid.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5bacf2f1f12740774c76dc95185aa4be0#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import CnxMeeting from "./CnxMeeting"
import CnxProviderProfile from "./CnxProviderProfile"

export interface CnxBidProperties extends DomainObjectProperties
{
    // CnxBid Attributes
    bidStatus: string
    earningProcessingFeeCents?: number | null
    earningPlatformFeeCents?: number | null
    earningBaseRateCents?: number | null
    earningCurrency?: string | null
    baseCurrency?: string | null
    currencyExchangeConstant?: number | null
    currencyExchangeRate?: number | null

    // CnxBid relationships
    meeting: OMReference<CnxMeeting>
    provider: OMReference<CnxProviderProfile>
}

/**
 * CnxBid
 */
export default class CnxBid extends DomainObject
    implements CnxBidProperties
{
    public static Type: string = "cnx_bid"
    public static Path: string = "cnx_bids"

    // Creates a placeholder CnxBid instance
    public static createPlaceholder() {
        return new CnxBid({
            bidStatus: "PENDING",
            meeting: new OMReference(CnxMeeting, CnxMeeting.createPlaceholder()),
            provider: new OMReference(CnxProviderProfile, CnxProviderProfile.createPlaceholder())
        })
    }

    // CnxBid Attributes
    public bidStatus: string
    public earningProcessingFeeCents?: number | null
    public earningPlatformFeeCents?: number | null
    public earningBaseRateCents?: number | null
    public earningCurrency?: string | null
    public baseCurrency?: string | null
    public currencyExchangeConstant?: number | null
    public currencyExchangeRate?: number | null

    // CnxBid Relationships
    public meeting: OMReference<CnxMeeting>
    public provider: OMReference<CnxProviderProfile>

    constructor(object: CnxBidProperties) {
        super(object)

        // CnxBid Attributes
        this.bidStatus = object.bidStatus
        this.earningProcessingFeeCents = object.earningProcessingFeeCents
        this.earningPlatformFeeCents = object.earningPlatformFeeCents
        this.earningBaseRateCents = object.earningBaseRateCents
        this.earningCurrency = object.earningCurrency
        this.baseCurrency = object.baseCurrency
        this.currencyExchangeConstant = object.currencyExchangeConstant
        this.currencyExchangeRate = object.currencyExchangeRate

        // CnxBid Relationships
        this.meeting = new OMReference(CnxMeeting, object.meeting)
        this.provider = new OMReference(CnxProviderProfile, object.provider)
    }
}
