//  CnxTopupPackage.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5a463509e25dae68b051ee072addd85ea#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Protocols adopted
import { Publishable, ConcretePublishable } from "../publishable"

// Import related object types
import CnxTopupPayment from "./CnxTopupPayment"

export interface CnxTopupPackageProperties extends DomainObjectProperties,
    Publishable
{
    // CnxTopupPackage Attributes
    finalPriceCents: number
    originalPriceCents: number
    currency: string

    // CnxTopupPackage relationships
    purchases?: OMReference<CnxTopupPayment>[] | null
    purchasesCount?: number
}

/**
 * CnxTopupPackage
 */
export default class CnxTopupPackage extends DomainObject
    implements CnxTopupPackageProperties
{
    public static Type: string = "cnx_topup_package"
    public static Path: string = "cnx_topup_packages"

    // Creates a placeholder CnxTopupPackage instance
    public static createPlaceholder() {
        return new CnxTopupPackage({
            finalPriceCents: 0,
            originalPriceCents: 0,
            currency: ""
        })
    }

    // CnxTopupPackage Attributes
    public finalPriceCents: number
    public originalPriceCents: number
    public currency: string

    // CnxTopupPackage Relationships
    public purchases?: Array<OMReference<CnxTopupPayment>> | null
    public purchasesCount: number

    // Publishable Protocol
    public publishAt?: Date | null
    public unpublishAt?: Date | null

    constructor(object: CnxTopupPackageProperties) {
        super(object)

        // CnxTopupPackage Attributes
        this.finalPriceCents = object.finalPriceCents
        this.originalPriceCents = object.originalPriceCents
        this.currency = object.currency

        // CnxTopupPackage Relationships
        this.purchases = object.purchases ? object.purchases.map((item) => new OMReference(CnxTopupPayment, item)) : []
        this.purchasesCount = object.purchasesCount || 0

        // Publishable Protocol
        this.publishAt = object.publishAt ? new Date(object.publishAt) : null
        this.unpublishAt = object.unpublishAt ? new Date(object.unpublishAt) : null
    }
}
