//  Group.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD54b47f05bd3d8922cb3f996965285901c#]

import {
    OMGroup,
    OMGroupProperties,
    OMReference,
    CommandManager,
    Party,
    User
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import { PushChannel } from "../broadcast"

// Import command types
import { IdAddMemberCommand, IdRemoveMemberCommand } from "./GroupCommands"

export interface GroupProperties extends OMGroupProperties
{
    // Group relationships
    pushChannel?: OMReference<PushChannel> | null
}

/**
 * Group
 */
export default class Group extends OMGroup
    implements GroupProperties
{
    public static Type: string = "group"
    public static Path: string = "groups"

    // Creates a placeholder Group instance
    public static createPlaceholder() {
        return new Group({ })
    }

    // Group Relationships
    public pushChannel?: OMReference<PushChannel> | null

    constructor(object: GroupProperties) {
        super(object)

        // Group Relationships
        this.pushChannel = object.pushChannel ? new OMReference(PushChannel, object.pushChannel) : null

    }

    // Group.addMember()
    // @param party: string
    public addMember(party: string)
    {
        try {
            const command = new IdAddMemberCommand(this, party)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // Group.removeMember()
    // @param party: string
    public removeMember(party: string)
    {
        try {
            const command = new IdRemoveMemberCommand(this, party)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
