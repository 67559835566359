//  CategorizableCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { ConcreteCategorizable } from "."

/* CategorizableSetCategoriesCommand */
export class CategorizableSetCategoriesCommand extends Command<ConcreteCategorizable>
{
    public static readonly Path = "set_categories"
    public static readonly Type = "categorizable_set_categories_command"

    public categories?: string[] | null

    public constructor(
        receiver: ConcreteCategorizable,
        categories?: string[] | null
    ) {
        super(receiver)

        this.categories = categories
    }
}
