//  Flaggable.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import {
    OMObject,
    OMObjectProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import { DomainProfile } from "../kernel"

// Import command types
import { FlagCommand, UnflagCommand } from "./FlaggableCommands"


export interface Flaggable extends OMObjectProperties
{

    // Flaggable relationships
    flaggers?: OMReference<DomainProfile>[] | null
    flaggerCount?: number
}

/**
 * ConcreteFlaggable
 */
export default class ConcreteFlaggable extends OMObject
    implements Flaggable
{
    public static Type: string = "flaggable"
    public static Path: string = "flaggables"

    // Creates a placeholder ConcreteFlaggable instance
    public static createPlaceholder() {
        return new ConcreteFlaggable({ })
    }


    // Flaggable Relationships
    public flaggers?: Array<OMReference<DomainProfile>>| null
    public flaggerCount: number

    constructor(object: Flaggable) {
        super(object)


        // Flaggable Relationships
        this.flaggers = object.flaggers ? object.flaggers.map((item) => new OMReference(DomainProfile, item)) : []
        this.flaggerCount = object.flaggerCount || 0

    }

    // Flaggable.flag()
    // @param profile: string,
    // @param reason: string
    public flag(profile?: string, reason?: string)
    {
        try {
            const command = new FlagCommand(this, profile, reason)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }


    // Flaggable.unflag()
    // @param profile: string
    public unflag(profile?: string)
    {
        try {
            const command = new UnflagCommand(this, profile)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

}
