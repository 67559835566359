import React, { useEffect, useState } from "react"

export const useElementClientSize = (ref: React.RefObject<HTMLElement>) => {
    const [width, setWidth] = useState<number>(0)
    const [height, setHeight] = useState<number>(0)

    useEffect(() => {
        if (!ref.current) {
            return
        }

        setWidth(ref.current.scrollWidth)
        setHeight(ref.current.scrollHeight)
    }, [ref])

    useEffect(() => {
        function onWindowResize() {
            if (ref.current) {
                setWidth(ref.current.clientWidth)
                setHeight(ref.current.clientHeight)
            }
        }

        if (!window) {
            return
        }

        window.addEventListener("resize", onWindowResize)

        // Call our custom handler once to set initial size
        onWindowResize()

        // Clean-up by removing custom window resize handler
        return () => window.removeEventListener("resize", onWindowResize)
    }, [ref])

    return [width, height]
}
