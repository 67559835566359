import React, { useEffect, useState } from "react"

export const useWindowScrollOffset = () => {
    const [scrollOffset, setScrollOffset] = useState({ x: 0, y: 0 })

    // This effect will be called only once with empty dependencies
    // list, to add a custom scroll event listener to window
    useEffect(() => {
        function onWindowScroll() {
            setScrollOffset({ x: window.scrollX, y: window.scrollY })
        }

        if (!window) {
            return
        }

        window.addEventListener("scroll", onWindowScroll)

        // Clean-up by removing custom window resize handler
        return () => window.removeEventListener("scroll", onWindowScroll)
    }, [])

    return [scrollOffset.x, scrollOffset.y]
}
