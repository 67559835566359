//  SrrRatingAttachment.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5ab70de84723bb328b14973953e8e3fd5#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import { GenImage } from "../files"
import SrrRating from "./SrrRating"

export interface SrrRatingAttachmentProperties extends DomainObjectProperties
{

    // SrrRatingAttachment relationships
    photo?: OMReference<GenImage> | null
    rating?: OMReference<SrrRating> | null
}

/**
 * SrrRatingAttachment
 */
export default class SrrRatingAttachment extends DomainObject
    implements SrrRatingAttachmentProperties
{
    public static Type: string = "srr_rating_attachment"
    public static Path: string = "srr_rating_attachments"

    // Creates a placeholder SrrRatingAttachment instance
    public static createPlaceholder() {
        return new SrrRatingAttachment({ })
    }

    // SrrRatingAttachment Relationships
    public photo?: OMReference<GenImage> | null
    public rating?: OMReference<SrrRating> | null

    constructor(object: SrrRatingAttachmentProperties) {
        super(object)

        // SrrRatingAttachment Relationships
        this.photo = object.photo ? new OMReference(GenImage, object.photo) : null
        this.rating = object.rating ? new OMReference(SrrRating, object.rating) : null
    }
}
