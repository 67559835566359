import { useEffect, useState } from "react"

import { OMUniverse, OMProfile as Profile } from "@apptivity-lab/firmament-node-sdk"

export const useProfile = <T extends Profile>(
    ProfileType: new (...arg: any) => T
): T | undefined => {
    const user = OMUniverse.shared.currentUser && OMUniverse.shared.currentUser?.user
        ? OMUniverse.shared.currentUser?.user
        : undefined

    const [profile, setProfile] = useState(
        (user?.profiles ?? []).reduce<T | undefined>((previous, current) => {
            if (previous) {
                return previous
            }
            return OMUniverse.shared.find(ProfileType, current.id)
        }, undefined)
    )

    useEffect(() => {
        if (!OMUniverse.shared.installation || !OMUniverse.shared.currentUser?.user) {
            return
        }

        setProfile((user?.profiles ?? []).reduce<T | undefined>((previous, current) => {
            if (previous) {
                return previous
            }
            return OMUniverse.shared.find(ProfileType, current.id)
        }, undefined))
    }, [ProfileType, user, user?.profiles])

    return profile
}
