//  CnxMemberProfile.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD513dd22e45ff267e46adece6ba0ff1729#]

import {
    OMReference,
    User
} from "@apptivity-lab/firmament-node-sdk"

import { DomainProfile, DomainProfileProperties } from "../kernel"

// Import related object types
import { GenImage } from "../files"
import CnxMoneyWallet from "./CnxMoneyWallet"
import CnxPointWallet from "./CnxPointWallet"
import { CnxMeeting, CnxMessage } from "."

export interface CnxMemberProfileProperties extends DomainProfileProperties
{
    // CnxMemberProfile Attributes
    name?: string | null

    // CnxMemberProfile relationships
    photo?: OMReference<GenImage> | null
    invitedBy?: OMReference<CnxMemberProfile> | null
    moneyWallet?: OMReference<CnxMoneyWallet> | null
    pointWallet?: OMReference<CnxPointWallet> | null
    currentMeeting?: OMReference<CnxMeeting> | null
    invitees?: OMReference<CnxMemberProfile>[] | null
    inviteesCount?: number
    dmReceived?: OMReference<CnxMessage>[] | null
    dmReceivedCount?: number
    messages?: OMReference<CnxMessage>[] | null
    messagesCount?: number
}

/**
 * CnxMemberProfile
 */
export default class CnxMemberProfile extends DomainProfile
    implements CnxMemberProfileProperties
{
    public static Type: string = "cnx_member_profile"
    public static Path: string = "cnx_member_profiles"

    // Creates a placeholder CnxMemberProfile instance
    public static createPlaceholder() {
        return new CnxMemberProfile({
            user: new OMReference(User, User.createPlaceholder())
        })
    }

    // CnxMemberProfile Attributes
    public name?: string | null

    // CnxMemberProfile Relationships
    public photo?: OMReference<GenImage> | null
    public invitedBy?: OMReference<CnxMemberProfile> | null
    public moneyWallet?: OMReference<CnxMoneyWallet> | null
    public pointWallet?: OMReference<CnxPointWallet> | null
    public currentMeeting?: OMReference<CnxMeeting> | null
    public invitees?: Array<OMReference<CnxMemberProfile>> | null
    public inviteesCount: number
    public dmReceived?: Array<OMReference<CnxMessage>> | null
    public dmReceivedCount: number
    public messages?: Array<OMReference<CnxMessage>> | null
    public messagesCount: number

    constructor(object: CnxMemberProfileProperties) {
        super(object)

        // CnxMemberProfile Attributes
        this.name = object.name

        // CnxMemberProfile Relationships
        this.photo = object.photo ? new OMReference(GenImage, object.photo) : null
        this.invitedBy = object.invitedBy ? new OMReference(CnxMemberProfile, object.invitedBy) : null
        this.moneyWallet = object.moneyWallet ? new OMReference(CnxMoneyWallet, object.moneyWallet) : null
        this.pointWallet = object.pointWallet ? new OMReference(CnxPointWallet, object.pointWallet) : null
        this.currentMeeting = object.currentMeeting ? new OMReference(CnxMeeting, object.currentMeeting) : null
        this.invitees = object.invitees ? object.invitees.map((item) => new OMReference(CnxMemberProfile, item)) : []
        this.inviteesCount = object.inviteesCount || 0
        this.dmReceived = object.dmReceived ? object.dmReceived.map((item) => new OMReference(CnxMessage, item)) : []
        this.dmReceivedCount = object.dmReceivedCount || 0
        this.messages = object.messages ? object.messages.map((item) => new OMReference(CnxMessage, item)) : []
        this.messagesCount = object.messagesCount || 0
    }
}
