//  PmtStripePaymentCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD537c30fefbecb679d1243a27afd324631#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { PmtStripePayment } from "."

/* PmtStripePaymentFetchStatusCommand */
export class PmtStripePaymentFetchStatusCommand extends Command<PmtStripePayment>
{
    public static readonly Path = "fetch_status"
    public static readonly Type = "pmt_stripe_payment_fetch_status_command"
}
