//  AffExpertProfile.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5338f7c7f9cb2a1b82200467ef53310be#]

import {
    OMReference,
    User
} from "@apptivity-lab/firmament-node-sdk"

import { CnxProviderProfile, CnxProviderProfileProperties } from "../connectx"

// Import related object types
import AffRating from "./AffRating"
import AffLesson from "./AffLesson"

// AffExpertProfile Custom Datatypes
type ApprovalStatus = "PENDING" | "APPROVED" | "REJECTED"
const ApprovalStatusOptions = [
    { title: "Pending", value: "PENDING" },
    { title: "Approved", value: "APPROVED" },
    { title: "Rejected", value: "REJECTED" },
]

export interface AffExpertProfileProperties extends CnxProviderProfileProperties
{

    // AffExpertProfile relationships
    overallRatings?: OMReference<AffRating>[] | null
    overallRatingsCount?: number
    overallRatingsAverage?: number | null
    overallRatingsCountByStar?: Map<string, string> | null
    onDemandLessons?: OMReference<AffLesson>[] | null
    onDemandLessonsCount?: number
}

/**
 * AffExpertProfile
 */
export default class AffExpertProfile extends CnxProviderProfile
    implements AffExpertProfileProperties
{
    public static Type: string = "aff_expert_profile"
    public static Path: string = "aff_expert_profiles"

    // Creates a placeholder AffExpertProfile instance
    public static createPlaceholder() {
        return new AffExpertProfile({
            isOnline: false,
            approvalState: "PENDING",
            user: new OMReference(User, User.createPlaceholder())
        })
    }

    // AffExpertProfile Relationships
    public overallRatings?: Array<OMReference<AffRating>> | null
    public overallRatingsCount: number
    public overallRatingsAverage: number
    public overallRatingsCountByStar: Map<string, string>
    public onDemandLessons?: Array<OMReference<AffLesson>> | null
    public onDemandLessonsCount: number

    constructor(object: AffExpertProfileProperties) {
        super(object)

        // AffExpertProfile Relationships
        this.overallRatings = object.overallRatings ? object.overallRatings.map((item) => new OMReference(AffRating, item)) : []
        this.overallRatingsCount = object.overallRatingsCount || 0
        this.overallRatingsAverage = object.overallRatingsAverage || 0
        this.overallRatingsCountByStar = object.overallRatingsCountByStar || new Map<string, string>()
        this.onDemandLessons = object.onDemandLessons ? object.onDemandLessons.map((item) => new OMReference(AffLesson, item)) : []
        this.onDemandLessonsCount = object.onDemandLessonsCount || 0
    }
}
