//  SubscriptionPackage.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD502b1013f7028a7a579efe166899b01e8#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Protocols adopted
import { Merchantable, ConcreteMerchantable } from "../merchantable"
import { Publishable, ConcretePublishable } from "../publishable"
import { Sequenceable, ConcreteSequenceable } from "../sequenceable"

// Import related object types
import { GenImage } from "../files"
import { ConcreteMerchBusiness } from "../merchantable"
import { MerchLineItem } from "../merchantable"
import Subscription from "./Subscription"

// SubscriptionPackage Custom Datatypes
export type BillingCycle = "MONTHLY" | "YEARLY"
export const BillingCycleOptions = [
    { title: "Monthly", value: "MONTHLY" },
    { title: "Yearly", value: "YEARLY" },
]

export interface SubscriptionPackageProperties extends DomainObjectProperties,
    Merchantable, Publishable, Sequenceable
{
    // SubscriptionPackage Attributes
    billingCycle?: BillingCycle | null
    gracePeriodDays?: number | null
    trialDays?: number | null

    // SubscriptionPackage relationships
    subscriptions?: OMReference<Subscription>[] | null
    subscriptionsCount?: number
}

/**
 * SubscriptionPackage
 */
export default class SubscriptionPackage extends DomainObject
    implements SubscriptionPackageProperties
{
    public static Type: string = "subscription_package"
    public static Path: string = "subscription_packages"

    // Creates a placeholder SubscriptionPackage instance
    public static createPlaceholder() {
        return new SubscriptionPackage({
            currency: "MYR",
            actualPriceCents: 0,
            listPriceCents: 0,
            isAvailable: true
        })
    }

    // SubscriptionPackage Attributes
    public billingCycle?: BillingCycle | null
    public gracePeriodDays?: number | null
    public trialDays?: number | null

    // SubscriptionPackage Relationships
    public subscriptions?: Array<OMReference<Subscription>> | null
    public subscriptionsCount: number

    // Merchantable Protocol
    public description?: string | null
    public currency: string
    public actualPriceCents: number
    public listPriceCents: number
    public isAvailable: boolean
    public coverPhoto?: OMReference<GenImage> | null
    public business?: OMReference<ConcreteMerchBusiness> | null
    public lineItems?: Array<OMReference<MerchLineItem>> | null
    public lineItemsCount: number

    // Publishable Protocol
    public publishAt?: Date | null
    public unpublishAt?: Date | null

    // Sequenceable Protocol
    public sequence?: number | null

    constructor(object: SubscriptionPackageProperties) {
        super(object)

        // SubscriptionPackage Attributes
        this.billingCycle = object.billingCycle
        this.gracePeriodDays = object.gracePeriodDays
        this.trialDays = object.trialDays

        // SubscriptionPackage Relationships
        this.subscriptions = object.subscriptions ? object.subscriptions.map((item) => new OMReference(Subscription, item)) : []
        this.subscriptionsCount = object.subscriptionsCount || 0

        // Merchantable Protocol
        this.description = object.description
        this.currency = object.currency
        this.actualPriceCents = object.actualPriceCents
        this.listPriceCents = object.listPriceCents
        this.isAvailable = object.isAvailable
        this.coverPhoto = object.coverPhoto ? new OMReference(GenImage, object.coverPhoto) : null
        this.business = object.business ? new OMReference(ConcreteMerchBusiness, object.business) : null
        this.lineItems = object.lineItems ? object.lineItems.map((item) => new OMReference(MerchLineItem, item)) : []
        this.lineItemsCount = object.lineItemsCount || 0

        // Publishable Protocol
        this.publishAt = object.publishAt ? new Date(object.publishAt) : null
        this.unpublishAt = object.unpublishAt ? new Date(object.unpublishAt) : null

        // Sequenceable Protocol
        this.sequence = object.sequence
    }
}
