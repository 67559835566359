//  CnxMeetingCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD59d0b61bea0dc71695c7767bd87e4f0ca#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { CnxMeeting } from "."

/* CnxMeetingAddConsumerCommand */
export class CnxMeetingAddConsumerCommand extends Command<CnxMeeting>
{
    public static readonly Path = "add_consumer"
    public static readonly Type = "cnx_meeting_add_consumer_command"

    public profile: string

    public constructor(
        receiver: CnxMeeting,
        profile: string
    ) {
        super(receiver)

        this.profile = profile
    }
}

/* CnxMeetingAwakeCommand */
export class CnxMeetingAwakeCommand extends Command<CnxMeeting>
{
    public static readonly Path = "awake"
    public static readonly Type = "cnx_meeting_awake_command"
}

/* CnxMeetingExpireCommand */
export class CnxMeetingExpireCommand extends Command<CnxMeeting>
{
    public static readonly Path = "expire"
    public static readonly Type = "cnx_meeting_expire_command"
}

/* CnxMeetingGetRoomTokenCommand */
export class CnxMeetingGetRoomTokenCommand extends Command<CnxMeeting>
{
    public static readonly Path = "get_room_token"
    public static readonly Type = "cnx_meeting_get_room_token_command"
}

/* CnxMeetingJoinCommand */
export class CnxMeetingJoinCommand extends Command<CnxMeeting>
{
    public static readonly Path = "join"
    public static readonly Type = "cnx_meeting_join_command"

    public profile: string

    public constructor(
        receiver: CnxMeeting,
        profile: string
    ) {
        super(receiver)

        this.profile = profile
    }
}

/* CnxMeetingLeaveCommand */
export class CnxMeetingLeaveCommand extends Command<CnxMeeting>
{
    public static readonly Path = "leave"
    public static readonly Type = "cnx_meeting_leave_command"

    public profile: string

    public constructor(
        receiver: CnxMeeting,
        profile: string
    ) {
        super(receiver)

        this.profile = profile
    }
}

/* CnxMeetingNotifyMatchProvidersCommand */
export class CnxMeetingNotifyMatchProvidersCommand extends Command<CnxMeeting>
{
    public static readonly Path = "notify_match_providers"
    public static readonly Type = "cnx_meeting_notify_match_providers_command"

    public providers?: string[] | null

    public constructor(
        receiver: CnxMeeting,
        providers?: string[] | null
    ) {
        super(receiver)

        this.providers = providers
    }
}

/* CnxMeetingOfferCommand */
export class CnxMeetingOfferCommand extends Command<CnxMeeting>
{
    public static readonly Path = "offer"
    public static readonly Type = "cnx_meeting_offer_command"

    public bid: string

    public constructor(
        receiver: CnxMeeting,
        bid: string
    ) {
        super(receiver)

        this.bid = bid
    }
}

/* CnxMeetingRemoveConsumerCommand */
export class CnxMeetingRemoveConsumerCommand extends Command<CnxMeeting>
{
    public static readonly Path = "remove_consumer"
    public static readonly Type = "cnx_meeting_remove_consumer_command"

    public profile: string

    public constructor(
        receiver: CnxMeeting,
        profile: string
    ) {
        super(receiver)

        this.profile = profile
    }
}

/* CnxMeetingSetMatchProvidersCommand */
export class CnxMeetingSetMatchProvidersCommand extends Command<CnxMeeting>
{
    public static readonly Path = "set_match_providers"
    public static readonly Type = "cnx_meeting_set_match_providers_command"

    public providers?: string[] | null

    public constructor(
        receiver: CnxMeeting,
        providers?: string[] | null
    ) {
        super(receiver)

        this.providers = providers
    }
}

/* CnxMeetingSetServiceCategoriesCommand */
export class CnxMeetingSetServiceCategoriesCommand extends Command<CnxMeeting>
{
    public static readonly Path = "set_service_categories"
    public static readonly Type = "cnx_meeting_set_service_categories_command"

    public categories?: string[] | null

    public constructor(
        receiver: CnxMeeting,
        categories?: string[] | null
    ) {
        super(receiver)

        this.categories = categories
    }
}
