//  Categorizable.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import {
    OMObject,
    OMObjectProperties,
    OMReference,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import CatCategory from "./CatCategory"

// Import command types
import { CategorizableSetCategoriesCommand } from "./CategorizableCommands"

export interface Categorizable extends OMObjectProperties
{

    // Categorizable relationships
    categories?: OMReference<CatCategory>[] | null
    categoryCount?: number
    categorySet?: string[] | null
    categorySetByType?: Map<string, string> | null
}

/**
 * ConcreteCategorizable
 */
export default class ConcreteCategorizable extends OMObject
    implements Categorizable
{
    public static Type: string = "categorizable"
    public static Path: string = "categorizables"

    // Creates a placeholder ConcreteCategorizable instance
    public static createPlaceholder() {
        return new ConcreteCategorizable({ })
    }

    // Categorizable Relationships
    public categories?: Array<OMReference<CatCategory>>| null
    public categoryCount: number
    public categorySet: string[]
    public categorySetByType: Map<string, string>

    constructor(object: Categorizable) {
        super(object)

        // Categorizable Relationships
        this.categories = object.categories ? object.categories.map((item) => new OMReference(CatCategory, item)) : []
        this.categoryCount = object.categoryCount || 0
        this.categorySet = object.categorySet || []
        this.categorySetByType = object.categorySetByType || new Map<string, string>()
    }

    // Categorizable.setCategories()
    // @param categories: string[]
    public setCategories(categories?: string[])
    {
        try {
            const command = new CategorizableSetCategoriesCommand(this, categories)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
