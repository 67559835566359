import { useEffect } from "react"
import { useRouter } from "next/router"
import Script from "next/script"

import { GA_MEASUREMENT_ID, pageView } from "@lib"

const GoogleAnalytics: React.FunctionComponent<{}> = () => {
    const router = useRouter()

    useEffect(() => {
        // Track GA page views on route change
        const handleRouteChange = (url: string) => {
            pageView(url)
        }
        router.events.on("routeChangeComplete", handleRouteChange)

        // Clean-up
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange)
        }
    }, [router.events])

    return (
        <>
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            {process.env.UNIVERSE_ENVIRONMENT === "production" &&
                <>
                    <Script
                        strategy="afterInteractive"
                        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
                    />
                    <Script
                        id="gtag-init"
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                        __html: `
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', '${GA_MEASUREMENT_ID}', {
                            page_path: window.location.pathname,
                            });
                        `,
                        }}
                    />
                </>
            }
        </>
    )
}

export default GoogleAnalytics
