//  CnxSnapshotPhoto.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD52cf94b832cdf3eaaedddf623a3b27bda#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import { GenImage } from "../files"
import CnxVideoRoom from "./CnxVideoRoom"

export interface CnxSnapshotPhotoProperties extends DomainObjectProperties
{
    // CnxSnapshotPhoto relationships
    image?: OMReference<GenImage> | null
    videoRoom?: OMReference<CnxVideoRoom> | null
}

/**
 * CnxSnapshotPhoto
 */
export default class CnxSnapshotPhoto extends DomainObject
    implements CnxSnapshotPhotoProperties
{
    public static Type: string = "cnx_snapshot_photo"
    public static Path: string = "cnx_snapshot_photos"

    // Creates a placeholder CnxSnapshotPhoto instance
    public static createPlaceholder() {
        return new CnxSnapshotPhoto({ })
    }

    // CnxSnapshotPhoto Relationships
    public image?: OMReference<GenImage> | null
    public videoRoom?: OMReference<CnxVideoRoom> | null

    constructor(object: CnxSnapshotPhotoProperties) {
        super(object)

        // CnxSnapshotPhoto Relationships
        this.image = object.image ? new OMReference(GenImage, object.image) : null
        this.videoRoom = object.videoRoom ? new OMReference(CnxVideoRoom, object.videoRoom) : null
    }
}
