//  AffMembershipPackageCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD56567ab03faf64d35f423b0dc628b204d#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { SubscriptionProcessor } from "@firmament-packages/paid_memberships"
import { AffMembershipPackage } from "."

/* AffMembershipPackageStartTrialCommand */
export class AffMembershipPackageStartTrialCommand extends Command<AffMembershipPackage>
{
    public static readonly Path = "start_trial"
    public static readonly Type = "aff_membership_package_start_trial_command"

    public ownerProfile: string
	public startAt: Date

    public constructor(
        receiver: AffMembershipPackage,
        ownerProfile: string,
		startAt: Date
    ) {
        super(receiver)

        this.ownerProfile = ownerProfile
		this.startAt = startAt
    }
}

/* AffMembershipPackageSubscribeCommand */
export class AffMembershipPackageSubscribeCommand extends Command<AffMembershipPackage>
{
    public static readonly Path = "subscribe"
    public static readonly Type = "aff_membership_package_subscribe_command"

    public ownerProfile: string
    public startAt: Date
    public subscriptionProcessor: SubscriptionProcessor

    public constructor(
        receiver: AffMembershipPackage,
        ownerProfile: string,
        startAt: Date,
        subscriptionProcessor: SubscriptionProcessor
    ) {
        super(receiver)

        this.ownerProfile = ownerProfile
        this.startAt = startAt
        this.subscriptionProcessor = subscriptionProcessor
    }
}
