//  MerchLineItem.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD558a56bcd399574721b85e7b66b0c468f#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import ConcreteMerchantable from "./Merchantable"
import MerchOrder from "./MerchOrder"

// MerchLineItem Custom Datatypes
type OrderStatus = "CART" | "CHECKED_OUT" | "PAID" | "COMPLETED" | "CANCELLED" | "FAILED"
const OrderStatusOptions = [
    { title: "Cart", value: "CART" },
    { title: "Checked_Out", value: "CHECKED_OUT" },
    { title: "Paid", value: "PAID" },
    { title: "Completed", value: "COMPLETED" },
    { title: "Cancelled", value: "CANCELLED" },
    { title: "Failed", value: "FAILED" },
]

export interface MerchLineItemProperties extends DomainObjectProperties
{
    // MerchLineItem Attributes
    orderStatus?: OrderStatus | null
    quantity?: number | null
    listPriceCents: number
    actualPriceCents: number
    remarks?: string | null

    // MerchLineItem relationships
    merchantable?: OMReference<ConcreteMerchantable> | null
    order: OMReference<MerchOrder>
}

/**
 * MerchLineItem
 */
export default class MerchLineItem extends DomainObject
    implements MerchLineItemProperties
{
    public static Type: string = "merch_line_item"
    public static Path: string = "merch_line_items"

    // Creates a placeholder MerchLineItem instance
    public static createPlaceholder() {
        return new MerchLineItem({
            listPriceCents: 0,
            actualPriceCents: 0,
            order: new OMReference(MerchOrder, MerchOrder.createPlaceholder())
        })
    }

    // MerchLineItem Attributes
    public orderStatus?: OrderStatus | null
    public quantity?: number | null
    public listPriceCents: number
    public actualPriceCents: number
    public remarks?: string | null

    // MerchLineItem Relationships
    public merchantable?: OMReference<ConcreteMerchantable> | null
    public order: OMReference<MerchOrder>

    constructor(object: MerchLineItemProperties) {
        super(object)

        // MerchLineItem Attributes
        this.orderStatus = object.orderStatus
        this.quantity = object.quantity
        this.listPriceCents = object.listPriceCents
        this.actualPriceCents = object.actualPriceCents
        this.remarks = object.remarks

        // MerchLineItem Relationships
        this.merchantable = object.merchantable ? new OMReference(ConcreteMerchantable, object.merchantable) : null
        this.order = new OMReference(MerchOrder, object.order)
    }
}
