//  WltTransaction.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import WltWallet from "./WltWallet"

// WltTransaction Custom Datatypes
export type TransactionType = "DEBIT" | "CREDIT"
export const TransactionTypeOptions = [
    { title: "Debit", value: "DEBIT" },
    { title: "Credit", value: "CREDIT" },
]
export type TransactionStatus = "PENDING" | "APPROVED" | "REJECTED"
export const TransactionStatusOptions = [
    { title: "Pending", value: "PENDING" },
    { title: "Approved", value: "APPROVED" },
    { title: "Rejected", value: "REJECTED" },
]

export interface WltTransactionProperties extends DomainObjectProperties
{
    // WltTransaction Attributes
    amountInCents: number
    currency: string
    transactionStatus: TransactionStatus
    transactionType: TransactionType

    // WltTransaction relationships
    wallet: OMReference<WltWallet>
}

/**
 * WltTransaction
 */
export default class WltTransaction extends DomainObject
    implements WltTransactionProperties
{
    public static Type: string = "wlt_transaction"
    public static Path: string = "wlt_transactions"

    // Creates a placeholder WltTransaction instance
    public static createPlaceholder() {
        return new WltTransaction({
            amountInCents: 0,
            currency: "",
            transactionStatus: "PENDING",
            transactionType: "DEBIT",
            wallet: new OMReference(WltWallet, WltWallet.createPlaceholder())
        })
    }

    // WltTransaction Attributes
    public amountInCents: number
    public currency: string
    public transactionStatus: TransactionStatus
    public transactionType: TransactionType

    // WltTransaction Relationships
    public wallet: OMReference<WltWallet>

    constructor(object: WltTransactionProperties) {
        super(object)

        // WltTransaction Attributes
        this.amountInCents = object.amountInCents
        this.currency = object.currency
        this.transactionStatus = object.transactionStatus
        this.transactionType = object.transactionType

        // WltTransaction Relationships
        this.wallet = new OMReference(WltWallet, object.wallet)
    }
}
