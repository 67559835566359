//  CnxMeetingTemplateCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD56822b9e7c6ee61ee7552b0b4ebaddc91#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { CnxMeetingTemplate } from "."

/* CnxMeetingTemplateAwakeCommand */
export class CnxMeetingTemplateAwakeCommand extends Command<CnxMeetingTemplate>
{
    public static readonly Path = "awake"
    public static readonly Type = "cnx_meeting_template_awake_command"
}

/* CnxMeetingTemplateCreateMeetingsCommand */
export class CnxMeetingTemplateCreateMeetingsCommand extends Command<CnxMeetingTemplate>
{
    public static readonly Path = "create_meetings"
    public static readonly Type = "cnx_meeting_template_create_meetings_command"
}
