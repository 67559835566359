//  NotifMessageCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5632a0609110e4fbc0d06c195b08d1300#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { NotifMessage } from "."

/* NotifMessageMarkAllReadCommand */
export class NotifMessageMarkAllReadCommand extends Command<NotifMessage>
{
    public static readonly Path = "mark_all_read"
    public static readonly Type = "notif_message_mark_all_read_command"

    public profile: string

    public constructor(
        receiver: NotifMessage,
        profile: string
    ) {
        super(receiver)

        this.profile = profile
    }
}
