//  MsgSentMessage.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD53cd7077754fe19846c192adc2042a12d#]

import {
    OMObject,
    OMObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import MsgTemplate from "./MsgTemplate"

export interface MsgSentMessageProperties extends OMObjectProperties
{
    // MsgSentMessage Attributes
    content: string
    receipient: string
    sentAt?: Date | null

    // MsgSentMessage relationships
    template?: OMReference<MsgTemplate> | null
}

/**
 * MsgSentMessage
 */
export default class MsgSentMessage extends OMObject
    implements MsgSentMessageProperties
{
    public static Type: string = "msg_sent_message"
    public static Path: string = "msg_sent_messages"

    // Creates a placeholder MsgSentMessage instance
    public static createPlaceholder() {
        return new MsgSentMessage({
            content: "",
            receipient: ""
        })
    }

    // MsgSentMessage Attributes
    public content: string
    public receipient: string
    public sentAt?: Date | null

    // MsgSentMessage Relationships
    public template?: OMReference<MsgTemplate> | null

    constructor(object: MsgSentMessageProperties) {
        super(object)

        // MsgSentMessage Attributes
        this.content = object.content
        this.receipient = object.receipient
        this.sentAt = object.sentAt ? new Date(object.sentAt) : null

        // MsgSentMessage Relationships
        this.template = object.template ? new OMReference(MsgTemplate, object.template) : null
    }
}
