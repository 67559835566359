//  CnxBookingCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5dd08194388601270edc5f8aeba59bd15#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { CnxBooking } from "."

/* CnxBookingCancelCommand */
export class CnxBookingCancelCommand extends Command<CnxBooking>
{
    public static readonly Path = "cancel"
    public static readonly Type = "cnx_booking_cancel_command"
}

/* CnxBookingConfirmCommand */
export class CnxBookingConfirmCommand extends Command<CnxBooking>
{
    public static readonly Path = "confirm"
    public static readonly Type = "cnx_booking_confirm_command"
}
