//  Merchantable.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD592bbdd75bc6f1f532d9f40e25daad93a#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Import related object types
import { GenImage } from "../files"
import ConcreteMerchBusiness from "./MerchBusiness"
import MerchLineItem from "./MerchLineItem"

export interface Merchantable extends DomainObjectProperties
{
    // Merchantable Attributes
    description?: string | null
    currency: string
    actualPriceCents: number
    listPriceCents: number
    isAvailable: boolean

    // Merchantable relationships
    coverPhoto?: OMReference<GenImage> | null
    business?: OMReference<ConcreteMerchBusiness> | null
    lineItems?: OMReference<MerchLineItem>[] | null
    lineItemsCount?: number
}

/**
 * ConcreteMerchantable
 */
export default class ConcreteMerchantable extends DomainObject
    implements Merchantable
{
    public static Type: string = "merchantable"
    public static Path: string = "merchantables"

    // Creates a placeholder ConcreteMerchantable instance
    public static createPlaceholder() {
        return new ConcreteMerchantable({
            currency: "MYR",
            actualPriceCents: 0,
            listPriceCents: 0,
            isAvailable: true
        })
    }

    // Merchantable Attributes
    public description?: string | null
    public currency: string
    public actualPriceCents: number
    public listPriceCents: number
    public isAvailable: boolean

    // Merchantable Relationships
    public coverPhoto?: OMReference<GenImage> | null
    public business?: OMReference<ConcreteMerchBusiness> | null
    public lineItems?: Array<OMReference<MerchLineItem>> | null
    public lineItemsCount: number

    constructor(object: Merchantable) {
        super(object)

        // Merchantable Attributes
        this.description = object.description
        this.currency = object.currency
        this.actualPriceCents = object.actualPriceCents
        this.listPriceCents = object.listPriceCents
        this.isAvailable = object.isAvailable

        // Merchantable Relationships
        this.coverPhoto = object.coverPhoto ? new OMReference(GenImage, object.coverPhoto) : null
        this.business = object.business ? new OMReference(ConcreteMerchBusiness, object.business) : null
        this.lineItems = object.lineItems ? object.lineItems.map((item) => new OMReference(MerchLineItem, item)) : []
        this.lineItemsCount = object.lineItemsCount || 0
    }
}
