import { useEffect, useRef } from "react"

/**
 * This hook stores and returns a value at the time of the function
 * component (re)-render and then runs an effect to update internally
 * the value with the input (if it has changed). The value update does
 * not trigger a component re-render.
 *
 * This hook can be useful if you need an "instance variable" like
 * "state" in a function component.
 *
 * @param   value
 * @returns value at the time of the function component render before
 *          it is updated
 */
export const usePreviousValue = <T>(value?: T) => {
    // Refs are mutable containers, which does not trigger a component
    // re-render when the value they hold changes (unlike state from
    // useState() hook).
    const ref = useRef(value)

    // Schedule a side-effect if value has changed after function components
    // render to hold the current value. Because we add value as a dependency
    // of this effect, we do not need to check if value has changed in the
    // implementation body of this effect.
    useEffect(() => {
        ref.current = value
    }, [value])

    // Return "previous" value at the time of the function components
    // render.
    return ref.current
}
