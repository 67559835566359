//  CnxArticle.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD526aced4b242db17cdac85c917451c53b#]

import {
    OMReference,
    CommandManager,
    DomainObject
} from "@apptivity-lab/firmament-node-sdk"

import { Article, ArticleProperties } from "../articles"
// Protocols adopted
import { Categorizable, ConcreteCategorizable } from "../categories"

// Import related object types
import { CatCategory } from "../categories"
import CnxConsumerProfile from "./CnxConsumerProfile"
import CnxProviderProfile from "./CnxProviderProfile"

// Import command types
import { CnxArticleSetConsumersCommand, CnxArticleSetProvidersCommand } from "./CnxArticleCommands"

export interface CnxArticleProperties extends ArticleProperties,
    Categorizable
{
    // CnxArticle relationships
    consumerProfiles?: OMReference<CnxConsumerProfile>[] | null
    consumerProfileCount?: number
    providerProfiles?: OMReference<CnxProviderProfile>[] | null
    providerProfileCount?: number
}

/**
 * CnxArticle
 */
export default class CnxArticle extends Article
    implements CnxArticleProperties
{
    public static Type: string = "cnx_article"
    public static Path: string = "cnx_articles"

    // Creates a placeholder CnxArticle instance
    public static createPlaceholder() {
        return new CnxArticle({ })
    }

    // CnxArticle Relationships
    public consumerProfiles?: Array<OMReference<CnxConsumerProfile>>| null
    public consumerProfileCount: number
    public providerProfiles?: Array<OMReference<CnxProviderProfile>>| null
    public providerProfileCount: number

    // Categorizable Protocol
    public categories?: Array<OMReference<CatCategory>>| null
    public categoryCount: number
    public categorySet: string[]
    public categorySetByType: Map<string, string>

    constructor(object: CnxArticleProperties) {
        super(object)

        // CnxArticle Relationships
        this.consumerProfiles = object.consumerProfiles ? object.consumerProfiles.map((item) => new OMReference(CnxConsumerProfile, item)) : []
        this.consumerProfileCount = object.consumerProfileCount || 0
        this.providerProfiles = object.providerProfiles ? object.providerProfiles.map((item) => new OMReference(CnxProviderProfile, item)) : []
        this.providerProfileCount = object.providerProfileCount || 0

        // Categorizable Protocol
        this.categories = object.categories ? object.categories.map((item) => new OMReference(CatCategory, item)) : []
        this.categoryCount = object.categoryCount || 0
        this.categorySet = object.categorySet || []
        this.categorySetByType = object.categorySetByType || new Map<string, string>()
    }

    // Categorizable.setCategories()
    // @param categories?: string[]
    public setCategories(categories?: string[])
    {
        return (new ConcreteCategorizable(this)).setCategories(categories)
    }

    // CnxArticle.setConsumers()
    // @param consumers: string[]
    public setConsumers(consumers?: string[])
    {
        try {
            const command = new CnxArticleSetConsumersCommand(this, consumers)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // CnxArticle.setProviders()
    // @param providers: string[]
    public setProviders(providers?: string[])
    {
        try {
            const command = new CnxArticleSetProvidersCommand(this, providers)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
