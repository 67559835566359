//  CnxPointWallet.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5caa92788f5214976967ee37ce3625d10#]

import {
    OMReference,
    DomainObject
} from "@apptivity-lab/firmament-node-sdk"

import { WltWallet, WltWalletProperties } from "../wallet"

// Import related object types
import CnxMemberProfile from "./CnxMemberProfile"

export interface CnxPointWalletProperties extends WltWalletProperties
{
    // CnxPointWallet relationships
    pointOwner: OMReference<CnxMemberProfile>
}

/**
 * CnxPointWallet
 */
export default class CnxPointWallet extends WltWallet
    implements CnxPointWalletProperties
{
    public static Type: string = "cnx_point_wallet"
    public static Path: string = "cnx_point_wallets"

    // Creates a placeholder CnxPointWallet instance
    public static createPlaceholder() {
        return new CnxPointWallet({
            currency: "",
            pointOwner: new OMReference(CnxMemberProfile, CnxMemberProfile.createPlaceholder())
        })
    }


    // CnxPointWallet Relationships
    public pointOwner: OMReference<CnxMemberProfile>

    constructor(object: CnxPointWalletProperties) {
        super(object)

        // CnxPointWallet Relationships
        this.pointOwner = new OMReference(CnxMemberProfile, object.pointOwner)
    }
}
