//  PushChannelCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5be5eec37249d809631772c3b02882284#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { PushChannel } from "."

/* PushChannelPublishMessageCommand */
export class PushChannelPublishMessageCommand extends Command<PushChannel>
{
    public static readonly Path = "publish_message"
    public static readonly Type = "push_channel_publish_message_command"

    public message: string
    public messageId?: string | null

    public constructor(
        receiver: PushChannel,
        message: string,
        messageId?: string | null
    ) {
        super(receiver)

        this.message = message
        this.messageId = messageId
    }
}
