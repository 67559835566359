
//  ApproveableCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { ConcreteApproveable } from "."

/* ApproveableApproveCommand */
export class ApproveableApproveCommand extends Command<ConcreteApproveable>
{
    public static readonly Path = "approve"
    public static readonly Type = "approveable_approve_command"
}

/* ApproveableReapplyCommand */
export class ApproveableReapplyCommand extends Command<ConcreteApproveable>
{
    public static readonly Path = "reapply"
    public static readonly Type = "approveable_reapply_command"
}

/* ApproveableRejectCommand */
export class ApproveableRejectCommand extends Command<ConcreteApproveable>
{
    public static readonly Path = "reject"
    public static readonly Type = "approveable_reject_command"

    public reason?: string | null

    public constructor(
        receiver: ConcreteApproveable,
        reason?: string | null
    ) {
        super(receiver)

        this.reason = reason
    }
}
