//  CnxConsumerProfileCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5a17481b928f633206c0f134a07fa20eb#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { CnxConsumerProfile } from "."

/* CnxConsumerProfileSummarizeMeetingsCommand */
export class CnxConsumerProfileSummarizeMeetingsCommand extends Command<CnxConsumerProfile>
{
    public static readonly Path = "summarize_meetings"
    public static readonly Type = "cnx_consumer_profile_summarize_meetings_command"
}
