
//  AffVideoCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD5184403c37b69fc18c398eaaba221b06f#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { AffVideo } from "."



/* AffVideoRecordViewershipCommand */
export class AffVideoRecordViewershipCommand extends Command<AffVideo>
{
    public static readonly Path = "record_viewership"
    public static readonly Type = "aff_video_record_viewership_command"

    public consumer: string
		public progress: number

    public constructor(
        receiver: AffVideo,
        consumer: string,
		progress: number
    ) {
        super(receiver)

        this.consumer = consumer
		this.progress = progress
    }
}


