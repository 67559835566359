//  Article.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD57016da1a416e7a0e607dc02878e5a4cb#]

import {
    DomainObject,
    DomainObjectProperties,
    OMReference
} from "@apptivity-lab/firmament-node-sdk"

// Protocols adopted
import { Publishable, ConcretePublishable } from "../publishable"

// Import related object types
import { DomainProfile } from "../kernel"
import { GenImage } from "../files"
import ArticleContent from "./ArticleContent"

export interface ArticleProperties extends DomainObjectProperties,
    Publishable
{
    // Article Attributes
    articleType?: string | null
    summary?: string | null

    // Article relationships
    author?: OMReference<DomainProfile> | null
    coverImage?: OMReference<GenImage> | null
    segments?: OMReference<ArticleContent>[] | null
    segmentsCount?: number
}

/**
 * Article
 */
export default class Article extends DomainObject
    implements ArticleProperties
{
    public static Type: string = "article"
    public static Path: string = "articles"

    // Creates a placeholder Article instance
    public static createPlaceholder() {
        return new Article({ })
    }

    // Article Attributes
    public articleType?: string | null
    public summary?: string | null

    // Article Relationships
    public author?: OMReference<DomainProfile> | null
    public coverImage?: OMReference<GenImage> | null
    public segments?: Array<OMReference<ArticleContent>> | null
    public segmentsCount: number

    // Publishable Protocol
    public publishAt?: Date | null
    public unpublishAt?: Date | null

    constructor(object: ArticleProperties) {
        super(object)

        // Article Attributes
        this.articleType = object.articleType
        this.summary = object.summary

        // Article Relationships
        this.author = object.author ? new OMReference(DomainProfile, object.author) : null
        this.coverImage = object.coverImage ? new OMReference(GenImage, object.coverImage) : null
        this.segments = object.segments ? object.segments.map((item) => new OMReference(ArticleContent, item)) : []
        this.segmentsCount = object.segmentsCount || 0

        // Publishable Protocol
        this.publishAt = object.publishAt ? new Date(object.publishAt) : null
        this.unpublishAt = object.unpublishAt ? new Date(object.unpublishAt) : null
    }
}
