
//  Approveable.ts
//
//  Opera House | Typescript - Model Class
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.

import {
    OMObject,
    OMObjectProperties,
    CommandManager
} from "@apptivity-lab/firmament-node-sdk"

// Import command types
import { ApproveableApproveCommand, ApproveableReapplyCommand, ApproveableRejectCommand } from "./ApproveableCommands"

// Approveable Custom Datatypes
export type ApprovalStatus = "PENDING" | "APPROVED" | "REJECTED"
export const ApprovalStatusOptions = [
    { title: "Pending", value: "PENDING" },
    { title: "Approved", value: "APPROVED" },
    { title: "Rejected", value: "REJECTED" },
]

export interface Approveable extends OMObjectProperties
{
    // Approveable Attributes
    approvalState: ApprovalStatus
    approvedAt?: Date | null
    rejectedAt?: Date | null
    rejectionReason?: string | null
}

/**
 * ConcreteApproveable
 */
export default class ConcreteApproveable extends OMObject
    implements Approveable
{
    public static Type: string = "approveable"
    public static Path: string = "approveables"

    // Creates a placeholder ConcreteApproveable instance
    public static createPlaceholder() {
        return new ConcreteApproveable({
            approvalState: "PENDING"
        })
    }

    // Approveable Attributes
    public approvalState: ApprovalStatus
    public approvedAt?: Date | null
    public rejectedAt?: Date | null
    public rejectionReason?: string | null

    constructor(object: Approveable) {
        super(object)

        // Approveable Attributes
        this.approvalState = object.approvalState
        this.approvedAt = object.approvedAt ? new Date(object.approvedAt) : null
        this.rejectedAt = object.rejectedAt ? new Date(object.rejectedAt) : null
        this.rejectionReason = object.rejectionReason
    }

    // Approveable.approve()
    public approve()
    {
        try {
            const command = new ApproveableApproveCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // Approveable.reapply()
    public reapply()
    {
        try {
            const command = new ApproveableReapplyCommand(this )
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // Approveable.reject()
    // @param reason: string
    public reject(reason?: string)
    {
        try {
            const command = new ApproveableRejectCommand(this, reason)
            return CommandManager.shared.addToQueue(command)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
