import { Application, OMInstallation, JSONAPI, OMReference, OMUniverse } from "@apptivity-lab/firmament-node-sdk"

type ServerInitResult = {}

const _cache = new Map<string, JSONAPI>()
let _lastFetchedAt: Date | undefined = undefined

export const restoreServerEnvironment = async (revalidate: number = 15 * 60): Promise<ServerInitResult> => {
    try {
        switch (process.env.UNIVERSE_ENVIRONMENT) {
            case "production":
                OMUniverse.shared.changeEnvironment("production")
                OMUniverse.setApplicationKey("CM62DCA9xDHx3JYzgjXhJgDA")
                OMUniverse.setBaseURL("https://harbour.alivefitandfree.com/aff-connectx-production")
                OMUniverse.shared.installation = new OMInstallation({
                    objectUuid: "a4d98fc1-2098-4bcc-82f2-43b9e38f5f3d",
                    device: "NextJS",
                    identifier: "55dbb3be-59f1-4b94-b444-813e85e02556",
                    lastLaunchedAt: new Date(),
                    app: new OMReference(Application, Application.createPlaceholder())
                })
                break
            default:
                OMUniverse.shared.changeEnvironment("staging")
                OMUniverse.setApplicationKey("1a64b006f85ddb77357541fd6826716f")
                OMUniverse.setBaseURL("https://harbour-cloud.apptivitylab.com/aff-connectx-dev")
                OMUniverse.shared.installation = new OMInstallation({
                    objectUuid: "500f3274-9015-4215-b1e7-daa991ba330f",
                    device: "NextJS",
                    identifier: "b5887911-7754-425a-83e8-23cd8072088f",
                    lastLaunchedAt: new Date(),
                    app: new OMReference(Application, Application.createPlaceholder())
                })
        }

        return {}
    } catch (error) {
        throw error
    }
}
