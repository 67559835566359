//  CnxServiceTierCommands.ts
//
//  Opera House | Typescript - Command Model
//  Updated 2022-02-25
//  Copyright © 2022 Apptivity Lab. All rights reserved.
//  [#MD57c843320817da19034651b5f3cd59aa1#]

import { Command } from "@apptivity-lab/firmament-node-sdk"
import { CnxServiceTier } from "."

/* CnxServiceTierAccessCommand */
export class CnxServiceTierAccessCommand extends Command<CnxServiceTier>
{
    public static readonly Path = "access"
    public static readonly Type = "cnx_service_tier_access_command"

    public profile: string

    public constructor(
        receiver: CnxServiceTier,
        profile: string
    ) {
        super(receiver)

        this.profile = profile
    }
}
